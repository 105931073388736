import React, { useState, useEffect } from "react";
import { FaPlus, FaTrashAlt, FaThLarge, FaBars } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; // Import Drag and Drop
import axios from "axios"; // Assuming you use axios for API requests
import "./EditTvpayouts.css"; // Assuming CSS is imported
import { Link } from "react-router-dom";
import backarrow from "../../components/Images/backarrow.svg";
import ReactPlayer from "react-player";
import { Table } from "react-bootstrap";
import { CiSquarePlus } from "react-icons/ci";

function EditTvpayouts() {

    const [liveDestination, setLiveDestination] = useState("0"); // default "Choose an Option"
    const [advanced, setadvanced] = useState(false); // checkbox state

    // Handle select dropdown change
    const handleSelectChangelive_destination = async (e) => {
        const value = e.target.value;
        setLiveDestination(value);

        try {
            await axios.post("/api/update-destination", { live_destination: value });
            console.log(`Live Destination updated to ${value}`);
        } catch (error) {
            console.error("Error updating live destination", error);
        }
    };

    // Handle advanced checkbox toggle
    const handleToggleChangeadvanced = async () => {
        // Check if a valid option is selected
        if (liveDestination !== "0") {
            const newValue = !advanced;
            setadvanced(newValue);

            try {
                await axios.post("/api/update-advanced", { advanced: newValue ? 1 : 0 });
                console.log(`advanced updated to ${newValue ? 1 : 0}`);
            } catch (error) {
                console.error("Error updating advanced", error);
            }
        } else {
            console.log("Please select a valid destination before toggling.");
        }
    };

    // Handle delete button to reset the select dropdown
    const handleDeleteadvanced = async () => {
        setLiveDestination("0");
        setadvanced(false); // Reset the checkbox state when destination is deleted

        try {
            await axios.post("/api/reset-destination");
            console.log("Live Destination reset to 0");
        } catch (error) {
            console.error("Error resetting live destination", error);
        }
    };




    const [filteredVideoData, setFilteredVideoData] = useState([]);
    const [filteredAudioData, setFilteredAudioData] = useState([]);
    const [filteredLiveData, setFilteredLiveData] = useState([]);
    const [filteredEpisodeData, setFilteredEpisodeData] = useState([]);
    console.log(filteredLiveData, "filteredLiveDatafilteredLiveData")
    const [loading, setLoading] = useState(true);

    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const fetchApi = async () => {
        await axios
            .get(`${process.env.REACT_APP_Baseurl}/admin/Master-list`, {
                headers: headers,
            })
            .then((response) => {
                // const masterAllList = response;
                const videoList = response?.data?.Videos;
                const audioList = response?.data?.audios;
                const liveList = response?.data?.LiveStream;
                const episodeList = response?.data?.Episode;
                // setMasterOverAllData(masterAllList);
                const updatedVideoList = videoList?.map((item) => {
                    const UploadStatus =
                        item?.uploaded_by === "1"
                            ? "Admin"
                            : item?.uploaded_by === "4"
                                ? "Channel Partner"
                                : item?.uploaded_by === "5"
                                    ? "Content Partner"
                                    : "";
                    const statusText =
                        item?.active === 1 &&
                            item?.publish_type === "publish_now" &&
                            item?.status === 1 &&
                            item?.draft === 1
                            ? "PUBLISHED"
                            : item?.active == null &&
                                item?.publish_type === "publish_later" &&
                                item?.status == null &&
                                item?.draft == null
                                ? "SCHEDULED"
                                : "DRAFT";

                    return { ...item, uploaded_by: UploadStatus, active: statusText };
                });
                const updatedLiveList = liveList?.map((item) => {
                    const UploadStatus =
                        item?.uploaded_by === "1"
                            ? "Admin"
                            : item?.uploaded_by === "4"
                                ? "Channel Partner"
                                : item?.uploaded_by === "5"
                                    ? "Content Partner"
                                    : "";
                    const statusText =
                        item?.active === 1 &&
                            item?.publish_type === "publish_now" &&
                            item?.status === 1
                            ? // &&
                            // item?.draft === 1
                            "PUBLISHED"
                            : item?.active == null &&
                                item?.publish_type === "publish_later" &&
                                item?.status == null
                                ? //  &&
                                // item?.draft == null
                                "SCHEDULED"
                                : "DRAFT";

                    return { ...item, uploaded_by: UploadStatus, active: statusText };
                });
                const updatedAudioList = audioList?.map((item) => {
                    const uploadBy =
                        item?.uploaded_by === "1"
                            ? "Admin"
                            : item?.uploaded_by === "4"
                                ? "Channel Partner"
                                : item?.uploaded_by === "5"
                                    ? "Content Partner"
                                    : "";
                    const statusText =
                        item?.active === 1
                            ? "PUBLISHED"
                            : item?.active === 0 || item?.active === null
                                ? "UNPUBLISHED"
                                : "DRAFT";

                    return { ...item, uploaded_by: uploadBy, active: statusText };
                });
                const updatedEpisodeList = episodeList?.map((item) => {
                    const uploadBy =
                        item?.uploaded_by === "1"
                            ? "Admin"
                            : item?.uploaded_by === "4"
                                ? "Channel Partner"
                                : item?.uploaded_by === "5"
                                    ? "Content Partner"
                                    : "";
                    const statusText =
                        item?.active === 1
                            ? "PUBLISHED"
                            : item?.active === 0 || item?.active === null
                                ? "UNPUBLISHED"
                                : "DRAFT";

                    return { ...item, uploaded_by: uploadBy, active: statusText };
                });
                setFilteredVideoData(updatedVideoList);
                setFilteredLiveData(updatedLiveList);
                setFilteredAudioData(updatedAudioList);
                setFilteredEpisodeData(updatedEpisodeList);
                setLoading(false);
            })
            .catch((error) => console.log(error));
        setLoading(false);
    };

    useEffect(async () => {
        fetchApi();
    }, []);











    const [scheduledContents, setScheduledContents] = useState([]);
    const [isScheduleActive, setIsScheduleActive] = useState(false);

    // Function to format duration from seconds to HH:mm:ss
    const formatDuration = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // Function to handle adding a video
    const handleAddVideo = (item) => {
        const newVideo = {
            content: item.title,
            startTime: scheduledContents.length > 0 ? new Date(scheduledContents[scheduledContents.length - 1].endTime) : new Date(), // Set to end time of last video
            duration: item?.duration ? parseDuration(item.duration) : 0, // Ensure duration is a number
            action: 'Delete', // For delete action
        };

        setScheduledContents(prev => [...prev, newVideo]);
        setIsScheduleActive(true);
        recalculateTimings([...scheduledContents, newVideo]);
    };

    // Handle drag and drop functionality
    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(scheduledContents);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        recalculateTimings(items);
    };

    // Function to recalculate start and end timings
    const recalculateTimings = (items) => {
        let currentTime = new Date();
        items.forEach((item, index) => {
            if (index > 0) {
                const previousItem = items[index - 1];
                currentTime = new Date(previousItem.endTime); // Set start time after the previous video ends
            }
            item.startTime = new Date(currentTime); // Set start time
            item.endTime = new Date(currentTime.getTime() + item.duration * 1000); // Calculate end time based on duration
        });

        setScheduledContents(items);
    };

    // Function to handle editing duration
    const handleDurationChange = (index, newDuration) => {
        const updatedContents = [...scheduledContents];

        // Allow empty input to clear
        if (newDuration === '') {
            updatedContents[index].duration = 0; // Reset duration if cleared
            recalculateTimings(updatedContents);
            setScheduledContents(updatedContents);
            return;
        }

        // Validate input format (HH:mm:ss)
        const durationRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // Regex for HH:mm:ss
        if (durationRegex.test(newDuration)) {
            const parsedDuration = parseDuration(newDuration);
            updatedContents[index].duration = parsedDuration; // Update duration
            recalculateTimings(updatedContents); // Recalculate timings
        } else {
            alert('Please enter a valid duration in HH:mm:ss format.'); // Alert user for invalid format
        }
    };

    // Function to handle editing start time
    const handleStartTimeChange = (index, newStartTime) => {
        const updatedContents = [...scheduledContents];
        updatedContents[index].startTime = new Date(newStartTime);
        recalculateTimings(updatedContents);
    };

    // Helper function to parse duration from HH:mm:ss format to seconds
    const parseDuration = (duration) => {
        const parts = duration.split(':');
        if (parts.length === 3) {
            const hours = parseInt(parts[0], 10) || 0;
            const minutes = parseInt(parts[1], 10) || 0;
            const seconds = parseInt(parts[2], 10) || 0;
            return hours * 3600 + minutes * 60 + seconds;
        }
        return 0; // Fallback to 0 if not in expected format
    };








    return (
        <div className="m-0 p-0">


            <Link to="/tv-payouts" className="backarrow py-4">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
            </Link>

            <section className="container-fluid iq-card mt-2">
                <div className="row py-3 col-lg-12">
                    <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="shadow-sm p-2 bg-body">
                            <label className="m-0">Duration</label>
                            <div className="row mt-2 text-start">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <select
                                            className="form-select"
                                            id="type"
                                            name="type"
                                            size="lg"
                                        >
                                            <option value="0">Choose an Option</option>
                                            <option value="1">Schedule Payouts</option>
                                            <option value="2">Loop Payouts</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 text-start">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <input
                                            type="datetime-local"
                                            name="date"
                                            id="date"
                                            className="rs-input form-control-lg"
                                            placeholder="Year"
                                            style={{ height: "50px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row mt-2 text-start">
                                <ReactPlayer
                                    url="https:\/\/bitdash-a.akamaihd.net\/content\/sintel\/hls\/playlist.m3u8"
                                    controls={true} // Enable player controls
                                    width="100%"
                                    height="150px"
                                // config={{
                                //     file: {
                                //         attributes: {
                                //             poster: editUser?.Player_thumbnail
                                //         }
                                //     }
                                // }}
                                />
                            </div>
                        </div>
                        <div className="mt-4 shadow-sm p-1 bg-body">
                            <ul className="nav nav-pills mb-3 justify-content-evenly" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-Destination"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                    >
                                        Destination
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-Advanced"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                    >
                                        Advanced Setting
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-Destination" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div>
                                        <div className="d-flex row text-start">
                                            <div className="col-12">
                                                <div>
                                                    <select
                                                        className="form-select"
                                                        id="live_destination"
                                                        name="live_destination"
                                                        value={liveDestination}
                                                        onChange={handleSelectChangelive_destination}
                                                        size="lg"
                                                    >
                                                        <option value="0">Choose an Option</option>
                                                        <option value="1">Live Stream Video 1</option>
                                                        <option value="2">Live Stream Video 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <label className="switch">
                                                        <input
                                                            name="advanced"
                                                            id="advanced"
                                                            type="checkbox"
                                                            checked={advanced}
                                                            onChange={handleToggleChangeadvanced}
                                                            className="rs-input"
                                                            disabled={liveDestination === "0"} // Disable if no valid option is selected
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <button className="btn btn-outline-danger" onClick={handleDeleteadvanced}>
                                                        <FaTrashAlt />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-Advanced" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    Advanced Setting
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-12 col-sm-9 col-md-9 col-lg-9">
                        <div>
                            <h6>Schedule</h6>
                            <hr />

                            <div className="row col-lg-12">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                                    <div className="d-flex justify-content-between flex-nowrap">
                                        <div className="row col-lg-12 align-items-center">
                                            <div className="col-12 col-sm-7 col-md-7 col-lg-7">
                                                <label>Adjust the Start Time for the contents.</label>
                                            </div>
                                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 text-end">
                                                <label className="switch">
                                                    <input
                                                        name="featured"
                                                        id="featured"
                                                        className="rs-input"
                                                        type="checkbox"
                                                    />
                                                    <span
                                                        className="slider round"
                                                        name="featured"
                                                        id="featured"
                                                    ></span>
                                                </label>
                                            </div>
                                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 text-start">
                                                <label>Retain Contents</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <Table {...provided.droppableProps} ref={provided.innerRef}>
                                                        <thead>
                                                            <tr className="title">
                                                                <th>CONTENT</th>
                                                                <th>START DATE/TIME</th>
                                                                <th>DURATION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {scheduledContents.map((video, index) => (
                                                                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                                                                    {(provided) => (
                                                                        <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                            <td>
                                                                                {video?.content && video.content.length > 15 ? `${video.content.substring(0, 15)}...` : video.content}
                                                                            </td>
                                                                            <td>
                                                                                {video.startTime.toISOString().slice(0, 16)}
                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex">

                                                                                    <input
                                                                                        type="text"
                                                                                        value={formatDuration(video.duration)}
                                                                                        onChange={(e) => handleDurationChange(index, e.target.value)}
                                                                                        placeholder="HH:mm:ss" // Placeholder for user guidance
                                                                                    />

                                                                                    <button onClick={() => {
                                                                                        // Handle delete logic
                                                                                        setScheduledContents(prev => prev.filter((_, i) => i !== index));
                                                                                    }}>
                                                                                        Delete
                                                                                    </button>


                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </tbody>
                                                    </Table>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <div className="">
                                        <button className="btn btn-primary" disabled={!isScheduleActive}>Set Schedule</button>
                                    </div>
                                    <ul className="nav nav-pills py-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-livestream" type="button" role="tab" aria-controls="pills-home" aria-selected="true">LiveStream</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-videos" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Videos</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-livestream" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td className=""> test </td>
                                                        <td className="fw-bold"> Break </td>
                                                        <td>
                                                            <CiSquarePlus onClick={() => handleAddVideo({ title: 'Break', duration: '00:02:00' })} /> {/* 2 minutes break */}
                                                        </td>
                                                    </tr>
                                                    {filteredLiveData?.map((item) => (
                                                        <tr key={item.id}>
                                                            <td className="">
                                                                <img src={item?.Thumbnail} className="100" width={20} alt={item.title} />
                                                            </td>
                                                            <td>
                                                                {item?.title && item.title.length > 15 ? `${item.title.substring(0, 15)}...` : item.title}
                                                            </td>
                                                            <td>
                                                                <CiSquarePlus onClick={() => handleAddVideo(item)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="tab-pane fade" id="pills-videos" role="tabpanel" aria-labelledby="pills-profile-tab">
                                            <Table>
                                                <tbody>
                                                    {filteredVideoData?.map((item) => (
                                                        <tr key={item.id}>
                                                            <td className="">
                                                                <img src={item?.Thumbnail} className="100" width={20} alt={item.title} />
                                                            </td>
                                                            <td>
                                                                {item?.title && item.title.length > 15 ? `${item.title.substring(0, 15)}...` : item.title}
                                                            </td>
                                                            <td>
                                                                <CiSquarePlus onClick={() => handleAddVideo(item)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default EditTvpayouts;
