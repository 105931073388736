import React, { useState, useEffect, useRef } from "react";
import "./SubscriberAnalytics.css"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { } from "react-bootstrap-table2-paginator";
import {
    MdKeyboardDoubleArrowLeft,
    MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import './UsersRevenue.css'
import { getItemToken } from "../../../Utils/localStorageUtils";
import AdminHeader from "../../../components/CommonComponents/AdminHeader";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";
import axios from "axios";
import ApexAreaChart from "./Chart/ApexAreaChart";
import { IconFilter } from "../../../Assets/MyIcons";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import { formatDate } from "../../../components/CommonMethod/CommonMethods";

const SubscriberAnalytics = () => {
    const userAccessToken = getItemToken('access_token');

    const [filteredData, setFilteredData] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [withOutRefresh, setWithoutRefresh] = useState(false)
    const [loading, setLoading] = useState(false);
    const [editUser, setEditUser] = useState({
        startDate: "",
        endDate: "",
        filterType: "",
    });
    const [defaultData, setDefaultData] = useState({
        filterTypes: [],
        totalAdmin: [],
        totalRegister: [],
        totalSubscriber: []
    })
    const [chartData, setChartData] = useState({
        categories: [],
        series: [{
            name: "USERS",
            data: []
        }]
    })

    const startDateRef = useRef()
    const endDateRef = useRef()
    const filterTypeRef = useRef()

    const columns = [
        {
            dataField: "subscription_id",
            text: "TRANSCATION ID",
            headerClasses: "userAnalyticsBox"
        },
        {
            dataField: "user_name",
            text: "User name",
            headerClasses: "userAnalyticsBox"
        },

        {
            dataField: "PaymentGateway",
            text: "TYPE",
        },
        {
            dataField: "plans_name",
            text: "PLAN",
            headerClasses: "userAnalyticsBox"
        },
        {
            dataField: "subscription_price",
            text: "PRICE",
        },
        {
            dataField: "countryname",
            text: "COUNTRY",
        },
        // {
        //     dataField: "content",
        //     text: "CONTENT",
        // },
        {
            dataField: "createdAt",
            text: "TIMESTAMP",
            formatter: (cell) => formatDate(cell),
        },
        // {
        //     dataField: "source",
        //     text: "SOURCE",
        // },
    ];

    const options = {
        page: 1,
        sizePerPage: 10,
        nextPageText: "Next",
        prePageText: "Prev",
        firstPageText: <MdKeyboardDoubleArrowLeft />,
        lastPageText: <MdKeyboardDoubleArrowRight />,
        showTotal: true,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "All", value: Number(filteredData?.length) },
        ],
    };

    const handleInput = (e) => {
        const { name, type, value, checked } = e.target;
        if (name === 'filterType') {
            setEditUser((prevEditUser) => ({
                ...prevEditUser,
                startDate: null,
                endDate: null
            }));
        }
        if (name === "startDate" || name == "endDate") {
            setEditUser((prevEditUser) => ({
                ...prevEditUser,
                filterType: null,
            }));
            if (editUser?.startDate && !editUser?.endDate) {
                endDateRef.current.focus();
            } else if (editUser?.endDate && !editUser?.startDate) {
                startDateRef.current.focus();
            }
        }
        setEditUser((prevEditUser) => ({
            ...prevEditUser,
            [name]: value,
        }));
    };

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl_Node_Api}/admin/Subscription-purchased-analytics`, { headers: userAccessToken });
            const userData = response?.data?.Subscription;
            setApiData(userData);
            setFilteredData(userData);
            setChartData((prev) => ({
                ...prev,
                categories: response?.data?.monthly_revenue_months,
                series: [
                    {
                        name: "SUBSCRIBER",
                        data: response?.data?.monthly_revenue_counts,
                    }
                ],
            }))
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const fetchFilterTypeData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl_Node_Api}/admin/filter-types`, { headers: userAccessToken });
            const types = response?.data?.FilterTypes;
            setDefaultData((prev) => ({
                ...prev,
                filterTypes: types
            }))

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleFilterChange = (data) => {
        setFilteredData(data);
    };

    const handleReload = () => {
        setWithoutRefresh(!withOutRefresh);
        setApiData([])
        setFilteredData([])
        setEditUser((prev) => ({
            ...prev,
            startDate: "",
            endDate: "",
            filterType: "",
        }))
    }

    const fetchFilterDurationData = async () => {
        setLoading(true)
        const requiredData = {
            filter_type: editUser?.filterType,
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl_Node_Api}/admin/Subscription-purchased-filter-analytics`, JSON.stringify(requiredData), { headers: userAccessToken });
            const userData = response?.data?.purchases;
            setApiData(userData);
            setFilteredData(userData);
            setChartData((prev) => ({
                ...prev,
                categories: response?.data?.monthly_revenue_months,
                series: [
                    {
                        name: "SUBSCRIBER",
                        data: response?.data?.monthly_revenue_counts,
                    }
                ],
            }))
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const fetchFilterStartEndDateData = async () => {
        setLoading(true)
        const requiredData = {
            start_Date: editUser?.startDate,
            end_Date: editUser?.endDate
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl_Node_Api}/admin/Subscription-purchased-date-filter-analytics`, JSON.stringify(requiredData), { headers: userAccessToken });
            const userData = response?.data?.Subscription;
            setApiData(userData);
            setFilteredData(userData);
            setChartData((prev) => ({
                ...prev,
                categories: response?.data?.monthly_revenue_months,
                series: [
                    {
                        name: "SUBSCRIBER",
                        data: response?.data?.monthly_revenue_counts,
                    }
                ],
            }))
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    useEffect(async () => {
        fetchData();
        fetchFilterTypeData()
    }, [withOutRefresh]);

    useEffect(() => {
        if (editUser?.startDate && editUser?.endDate) {
            fetchFilterStartEndDateData()
        }
        else if (editUser?.filterType) {
            fetchFilterDurationData()
        }
        else {
            fetchData()
        }
    }, [editUser])

    return (
        <div className="">
            <h4 className="admin-title mb-3 theme-text-color text-md-start"> <MessageBox text={'Subscription Analytics'} classname="theme-text-color" /></h4>
            <div className="theme-bg-color p-3 rounded-2 mb-3">
                <div className="d-flex align-items-center p-3">
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <MessageBox text="" />
                            <div className="d-flex align-items-center justify-content-between gap-3">
                                <div className="position-relative">
                                    <input type="date" name="startDate" value={editUser?.startDate || ''} max={editUser?.endDate || ''} onChange={handleInput} ref={startDateRef} className="filterTypeCustom  calender rounded-5 theme-text-color border border-1 theme-border-color " />
                                </div>
                                <div className="position-relative">
                                    <input type="date" name="endDate" value={editUser?.endDate || ''} min={editUser?.startDate || ''} onChange={handleInput} ref={endDateRef} className="filterTypeCustom calender rounded-5  theme-text-color border border-1 theme-border-color " />
                                </div>
                                <div className="position-relative">
                                    <IconFilter styled={{ width: "18px", height: "18px", className: "position-absolute filterIcon" }} />
                                    <select name="filterType" value={editUser?.filterType || ''} onChange={handleInput} className=" rounded-5 filterTypeCustom border border-1 theme-border-color  theme-text-color" ref={filterTypeRef}>
                                        <option value='' className="theme-bg-color theme-text-color" >Filter</option>
                                        {defaultData?.filterTypes?.map((item, index) => {
                                            return <option key={item?.filter_type} value={item?.filter_type} className="theme-bg-color theme-text-color">{item?.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <ApexAreaChart users={apiData} chartData={chartData} />
                    </div>
                </div>
                {/* <div className="d-flex align-items-center pb-3">
                    <div className="col-3 d-flex align-items-center">
                        <select name="filterType" value={editUser?.filterType || ''} onChange={handleInput} className="form-select custom-placeholder   border border-1 theme-border-color  theme-text-color" ref={filterTypeRef}>
                            <option value='' className="theme-bg-color theme-text-color" >Select Filter Type</option>
                            {defaultData?.filterTypes?.map((item, index) => {
                                return <option key={item?.filter_type} value={item?.filter_type} className="theme-bg-color theme-text-color">{item?.name}</option>
                            })}
                        </select>
                    </div>

                    <div className="col-9 px-4 d-flex align-items-center justify-content-end">
                        <div className="col-3 pe-2">
                            <input type="date" name="startDate" value={editUser?.startDate || ''} max={editUser?.endDate || ''} onChange={handleInput} ref={startDateRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color " />
                        </div>
                        <div className="col-3 pe-2">
                            <input type="date" name="endDate" value={editUser?.endDate || ''} min={editUser?.startDate || ''} onChange={handleInput} ref={endDateRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color " />
                        </div>

                    </div>
                </div> */}
            </div>
            <div className="theme-bg-color p-3 rounded-2">
                {/* <div className="d-flex align-items-center pb-3">
                    <div className="col-3 d-flex align-items-center">
                        <select name="filterType" value={editUser?.filterType || ''} onChange={handleInput} className="form-select custom-placeholder   border border-1 theme-border-color  theme-text-color" ref={filterTypeRef}>
                            <option value='' className="theme-bg-color theme-text-color" >Select Filter Type</option>
                            {defaultData?.filterTypes?.map((item, index) => {
                                return <option key={item?.filter_type} value={item?.filter_type} className="theme-bg-color theme-text-color">{item?.name}</option>
                            })}
                        </select>
                    </div>

                    <div className="col-9 px-4 d-flex align-items-center justify-content-end">
                        <div className="col-3 pe-2">
                            <input type="date" name="startDate" value={editUser?.startDate || ''} max={editUser?.endDate || ''} onChange={handleInput} ref={startDateRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color " />
                        </div>
                        <div className="col-3 pe-2">
                            <input type="date" name="endDate" value={editUser?.endDate || ''} min={editUser?.startDate || ''} onChange={handleInput} ref={endDateRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color " />
                        </div>

                    </div>
                </div> */}
                <div className=" bootstrapTable scroll-active multi-delete-width" >
                    <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={'SUBSCRIBER-ANALYTICS-LIST'} reloadAction={handleReload} customSubscriber reload loading={loading} />
                    <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={loading ? [] : filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover />
                </div>
            </div>
        </div>
    );
};

export default SubscriberAnalytics;
