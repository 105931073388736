import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import { adminApiHeader } from "../../../components/CommonApis/CommonApiUrl";
import { albumDefaultURL } from "../../../components/CommonApis/CommonAudioApis";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import { toast } from "react-toastify";
import MessageSubHeading from "../../../components/CommonComponents/MessageSubHeading";
import { IoClose } from "react-icons/io5";
import { FaFileAlt } from "react-icons/fa";
import NavigationData from "../../../layout/Sidebar/NavigationData";

const Addalbum = () => {
  const [formData, setFormData] = useState({ parent_id: 0 })
  const [formDataError, setFormDataError] = useState({})
  const [defaultOption, setDefaultOption] = useState()
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [copyRightFile, setCopyRightFile] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);

  const titleInputRef = useRef(null);
  const slugInputRef = useRef(null);
  const coverImageRef = useRef(null)
  const copyRightRef = useRef(null)
  const navigate = useNavigate();

  const { label_management } = NavigationData();

  // api start
  const handleGetCategory = async () => {
    try {
      const response = await axios.get(`${albumDefaultURL?.createListApi}`, { headers: adminApiHeader })
      const result = response?.data?.audio_albums;
      if (response?.data?.status) {
        setDefaultOption(result)
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  const handleAdd = async () => {
    setShowOverlay(true);
    try {
      const formDataAdd = new FormData();
      for (const key in formData) {
        formDataAdd.append(key, formData[key]);
      }
      const response = await axios.post(`${albumDefaultURL?.storeAlbum}`, formDataAdd, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        if (coverImageFile) {
          await handleImageUpload(result?.AudioAlbum_id)
        }
        if (copyRightFile) {
          await handleCopyRightUpload(result?.AudioAlbum_id)
        }
        setShowOverlay(false);
        toast.success(result?.message)
        navigate("/manage-albums");
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    }
    catch (err) {
      setShowOverlay(false);
      const errorMessage = err?.response?.data?.message
      toast.error(errorMessage)
      console.error("error", err)
    }
  }

  const handleImageUpload = async (id) => {
    try {
      const formData = new FormData();
      formData.append("album_id", id);
      formData.append("album", coverImageFile);
      const response = await axios.post(`${albumDefaultURL?.uploadImageURL}`, formData, { headers: adminApiHeader });
      if (!response?.data?.status) {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleCopyRightUpload = async (id) => {
    try {
      const formData = new FormData();
      formData.append("album_id", id);
      formData.append("copyrights", copyRightFile);
      const response = await axios.post(`${albumDefaultURL?.uploadCopyRight}`, formData, { headers: adminApiHeader });
      if (!response?.data?.status) {
        toast.error(response?.data?.message)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleStore = async () => {
    if (formValidation()) {
      await handleAdd();
    } else {
      console.log("Form validation failed ");
    }
  }

  // api end
  // function start
  const handleBlurtitle = () => {
    generateSlug(formData?.albumname);
  };

  const generateSlug = (albumname) => {
    albumname = albumname?.trim();
    const slug = albumname?.toLowerCase().replace(/\s+/g, "-");
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: slug,
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(formData?.name);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      slug: event.target.value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: "",
    }));
  };

  const handleBlurTitleEdit = () => {
    generateSlug(formData?.slug);
  };

  const handleInput = async (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      albumname: "",
      slug: "",
    }));
  }

  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  const handleCopyRightChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCopyRightFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  const formValidation = () => {
    let formIsValid = true;
    if (!formData?.albumname) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        albumname: "Title cannot be empty.",
      }));
      titleInputRef.current.focus();
      formIsValid = false;
    } else if (!formData?.slug) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        slug: "Slug cannot be empty.",
      }));
      slugInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  // function end
  useEffect(() => {
    handleGetCategory()
  }, []);

  return (
    <>
      {showOverlay && (<Response_Processing />)}
      <AdminSaveHeader heading={label_management?.audios_album[0]?.title} saveText={label_management?.audios_album[8]?.title} saveMethod={handleStore} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text={label_management?.audios_album[1]?.title} />
            <MessageLabel text={label_management?.audios_album[2]?.title} className="mandatorySimple" />
            <input type="text" name="albumname" value={formData?.albumname} onChange={handleInput} placeholder="Title" ref={titleInputRef} onBlur={handleBlurtitle} onKeyDown={handleInputKeyDowntitle} className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mb-3" />
            {formDataError?.albumname && (<MessageBox text={`${formDataError?.albumname}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text={label_management?.audios_album[3]?.title} />
            <input type="text" name="slug" id="slug" value={formData?.slug} onChange={handleAutoFillChangetitle} ref={slugInputRef} onBlur={handleBlurTitleEdit} placeholder="slug" className="mb-3 rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0" />
            {formDataError?.slug && (<MessageBox text={`${formDataError?.slug}`} classname='errorred mb-2 d-block' />)}

            <MessageLabel text={label_management?.audios_album[4]?.title} />
            <select id="parent_id" name="parent_id" onChange={handleInput} className="form-select mb-3 custom-placeholder  theme-bg-color-secondary theme-text-color border-0" >
              <option value="0" className="theme-bg-color theme-text-color"> Choose  category</option>
              {defaultOption?.map((item) => (<option value={item.id} className="theme-bg-color theme-text-color">{item.albumname}</option>))}
            </select>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text={label_management?.audios_album[5]?.title} />
            <MessageLabel text={label_management?.audios_album[6]?.title} />
            <MessageSubHeading text={label_management?.audios_album[6]?.sub_title} />
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" >
                  <input type="file" ref={coverImageRef} onChange={(event) => handleCoverImageChange(event)} accept="image/*" className="opacity-0 w-100 h-100" key={coverImageFile ? coverImageFile : "inputKey"} />
                  <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {coverImageFile && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0 ">
                          <img src={URL?.createObjectURL(coverImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                          <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <MessageLabel text={label_management?.audios_album[7]?.title} />
            <div className="mx-0 my-3 row">
              <div className="col-6 ps-0">
                <div className="imagedrop theme-border-color" >
                  <input type="file" ref={copyRightRef} onChange={(event) => handleCopyRightChange(event)} accept=".pdf, image/*" className="opacity-0 w-100 h-100" key={copyRightFile ? copyRightFile : "inputKey"} />
                  <label className="theme-text-color position-absolute"><FaFileAlt className=" theme-text-color" /> </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {copyRightFile && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0 h-100 align-items-center">
                        <div className="col-10 p-0 text-center ">
                          <MessageBox text="Name:" classname="d-inline-block theme-text-color" /><span className="text-break">{copyRightFile?.name?.slice(0, 5)}</span>
                          <MessageBox text="Type:." classname="d-inline-block theme-text-color" /><span className="text-break">{copyRightFile?.type?.slice(0, 5)}</span>
                        </div>
                        <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                          <button onClick={() => setCopyRightFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </RightWrapper>
      </FormContainer>
    </>
  );
}

export default Addalbum;