import React, { useRef, useState, useEffect } from 'react'
import "./AdsUpload.css"
import AdminSaveHeader from '../../../components/CommonComponents/AdminSaveHeader'
import MessageLabel from '../../../components/CommonComponents/MessageLabel'
import FormContainer from '../../../components/CommonComponents/FormContainer'
import LeftWrapper from '../../../components/CommonComponents/LeftWrapper'
import Wrapper from '../../../components/CommonComponents/Wrapper'
import MessageBox from '../../../components/CommonComponents/MessageBox'
import RightWrapper from '../../../components/CommonComponents/RightWrapper'
import AdminCustomButton from '../../../components/CommonComponents/AdminCustomButton'
import MessageHeading from '../../../components/CommonComponents/MessageHeading'
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom'
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

const AdsUpload = (props) => {

    const { id } = useParams();
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const [editUser, setEditUser] = useState({
        advertiser_id: "",
        status: "",
        ads_name: "",
        ads_slug: "",
        ads_position: '',
        ads_upload_type: "",
        ads_video: "",
        ads_path: "",
        ads_category: "",
        age: "",
        devices: "",
        gender: "",
        ads_redirection_url: "",
        schedule: "",
    })
    const [editname, setEditName] = useState({
        ads_name: "",
    })
    const [formData, setFormData] = useState({
    });


    const [advertisement_videos_upload, setAdvertisement_videos_upload] = useState("");

    const [validationMessagetitle, setValidationMessagetitle] = useState("");
    const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

    const [validationMessageuser_ads_upload_type, setValidationMessageads_upload_type] =
        useState("");
    const [isValidationHiddenads_upload_type, setIsValidationHiddenads_upload_type] =
        useState(true);

    const [processing, setProcessing] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const titleInputRef = useRef(null);
    const ads_upload_typeInputRef = useRef(null);



    const handleInput = (e) => {
        const { name, value } = e.target;
        setEditUser((prevEditUser) => ({
            ...prevEditUser,
            [name]: value,
        }));
    };

    const handleInputenable = (e) => {
        if (e.target.checked === true) {
            setEditUser({ ...editUser, [e.target.name]: 1 });
        } else {
            setEditUser({ ...editUser, [e.target.name]: 0 });
        }
    };

    const [apiResponseage, setApiResponseage] = useState([]);
    const [advertisementResponseage, setAdvertisementResponseage] = useState([]);
    const [ads_positionselect, setAds_positionselect] = useState([]);
    const [ads_categoryselect, setAds_categoryselect] = useState([]);
    const [user_ads_upload_type, setUser_ads_upload_type] = useState([]);
    const [selectedAges, setSelectedAges] = useState([]);


    const [optionsgender, setOptionsgender] = useState([]);
    const [selectedValuesgender, setSelectedValuesgender] = useState([]);

    const [optionsdevices, setOptionsdevices] = useState([]);
    const [selectedValuesdevices, setSelectedValuesdevices] = useState([]);


    const handleSelectChangegender = (selectedOptionsgender) => {

        setFormData({
        });
        setSelectedValuesgender(selectedOptionsgender);
    };

    const handleSelectChangedevices = (selectedOptionsdevices) => {

        setFormData({
        });
        setSelectedValuesdevices(selectedOptionsdevices);
    };


    useEffect(() => {
        // Set the initially selected ages based on advertisement response
        const selectedValues = advertisementResponseage?.map((item) => item.value);
        setSelectedAges(selectedValues);
    }, [advertisementResponseage]);

    useEffect(() => {
        const fetchDataDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement-edit/${id}`,
                    { headers: headers }
                );

                setEditUser(response?.data?.data?.Advertisements);
                setEditName(response?.data?.data?.Advertisements);
                setApiResponseage(response?.data?.data?.age);
                // setApiResponseage(response?.data?.data?.age);
                setAds_positionselect(response?.data?.data?.ads_position_play);
                setAds_categoryselect(response?.data?.data?.admin_ads_category);
                setUser_ads_upload_type(response?.data?.data?.ads_upload_type);
                setAdvertisementResponseage(response?.data?.data?.Advertisements?.age);

                // create datat 

                const datagender = response?.data?.data?.gender;
                const datadevices = response?.data?.data?.devices;

                const formattedOptionsgender = datagender?.map((item) => ({
                    value: item?.value,
                    label: item?.name,
                }));
                const formattedOptionsdevices = datadevices?.map((item) => ({
                    value: item?.value,
                    label: item?.name,
                }));

                setOptionsgender(formattedOptionsgender);
                setOptionsdevices(formattedOptionsdevices);

                // Edit data
                const datagenderedit = response?.data?.data?.Advertisements?.gender;
                const datadevicesedit = response?.data?.data?.Advertisements?.devices;

                const formattedOptionsgenderedit = datagenderedit?.map((item) => ({
                    value: item?.value,
                    label: item?.name,
                }));
                const formattedOptionsdevicesedit = datadevicesedit?.map((item) => ({
                    value: item?.value,
                    label: item?.name,
                }));

                setSelectedValuesgender(formattedOptionsgenderedit);
                setSelectedValuesdevices(formattedOptionsdevicesedit);
                // edit datat 


            } catch (error) {
                console.error(error);
            }
        };

        fetchDataDetails();

    }, []);


    const handleCheckboxChange = (value) => {
        // Toggle the selection of an age
        if (selectedAges.includes(value)) {
            setSelectedAges(selectedAges.filter((age) => age !== value));
        } else {
            setSelectedAges([...selectedAges, value]);
        }
    };


    const handleStore = async (e) => {
        e.preventDefault();

        let focusInputRef = null;

        if (!editUser?.ads_name) {
            setValidationMessagetitle("Name cannot be empty.");
            setIsValidationHiddentitle(false);
            focusInputRef = titleInputRef;
        }
        if (!editUser?.ads_upload_type) {
            setValidationMessageads_upload_type("Please select Upload Type.");
            setIsValidationHiddenads_upload_type(false);
            focusInputRef = ads_upload_typeInputRef;
        }

        if (focusInputRef) {
            focusInputRef?.current?.focus();
            return;
        }

        const selectedAgesForRequest = selectedAges?.filter(Boolean);
        const editInputValue = {
            ads_name: editUser?.ads_name,
            ads_position: editUser?.ads_position,
            ads_upload_type: editUser?.ads_upload_type,
            ads_category: editUser?.ads_category,
            ads_redirection_url: editUser?.ads_redirection_url,
            location: editUser?.location,
            ads_path: editUser?.ads_path,
            status: editUser?.status,
            age: selectedAgesForRequest,
            gender: selectedValuesgender?.map((option) => option?.value),
            devices: selectedValuesdevices?.map((option) => option?.value),
            schedule: editUser?.schedule,
        };

        setProcessing(true);
        setShowOverlay(true);

        let firstApiStatus = false;
        let secondApiStatus = false;
        let resultApi = null;
        let resultApiError = null;

        try {
            // First API call
            const response = await fetch(
                `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement-update/${id}`,
                {
                    method: "PATCH",
                    headers,
                    body: JSON.stringify(editInputValue),
                }
            );
            const responseData = await response.json();

            if (responseData?.status) {
                firstApiStatus = true;
                resultApi = responseData;

                if (advertisement_videos_upload) {
                    const formData = new FormData();
                    formData.append("advertisement_videos_upload", advertisement_videos_upload);

                    const imageResponse = await axios.patch(
                        `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement-upload/${id}`,
                        formData,
                        { headers }
                    );

                    if (imageResponse?.data?.status) {
                        secondApiStatus = true;
                    }
                } else {
                    secondApiStatus = true;
                }
            } else {
                resultApiError = responseData;
            }
        } catch (error) {
            console.error("Error during API calls:", error);
        }

        if (firstApiStatus && secondApiStatus) {
            toast.success(resultApi?.message);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else if (resultApiError) {
            toast.error(resultApiError?.message);
            setProcessing(false);
            setShowOverlay(false);
        } else {
            setProcessing(false);
            setShowOverlay(false);
        }
    };



    //#region StoreApi
    // async function handleStoressasd(e) {
    //     let focusInputRef = null;

    //     if (editUser?.ads_name === "") {
    //         setValidationMessagetitle("Name cannot be empty.");
    //         setIsValidationHiddentitle(false);
    //         focusInputRef = titleInputRef;
    //     }
    //     if (editUser?.ads_upload_type === "") {
    //         setValidationMessageads_upload_type("Please select Upload Type.");
    //         setIsValidationHiddenads_upload_type(false);
    //         focusInputRef = ads_upload_typeInputRef;
    //     }

    //     if (focusInputRef) {
    //         focusInputRef?.current?.focus();
    //         return;
    //     }

    //     e.preventDefault();
    //     const selectedAgesForRequest = selectedAges?.filter((age) => age);

    //     let arraysend = []


    //     const formData = {
    //         ads_name: editUser?.ads_name,
    //         ads_position: editUser?.ads_position,
    //         ads_upload_type: editUser?.ads_upload_type,
    //         ads_category: editUser?.ads_category,
    //         ads_redirection_url: editUser?.ads_redirection_url,
    //         location: editUser?.location,
    //         ads_path: editUser?.ads_path,
    //         age: selectedAgesForRequest, // Array [1, 2, 3]
    //         gender: selectedValuesgender?.map((option) => option?.value), // Array [1, 2, 3]
    //         devices: selectedValuesdevices?.map((option) => option?.value), // Array [1, 2, 3]
    //         schedule: editUser?.schedule,
    //     };

    //     // const formData = new URLSearchParams();

    //     // // Append simple fields
    //     // formData.append("ads_name", editUser?.ads_name);
    //     // formData.append("ads_position", editUser?.ads_position);
    //     // formData.append("ads_upload_type", editUser?.ads_upload_type);
    //     // formData.append("ads_category", editUser?.ads_category);
    //     // formData.append("ads_redirection_url", editUser?.ads_redirection_url);
    //     // formData.append("location", editUser?.location);
    //     // formData.append("ads_path", editUser?.ads_path);

    //     // // Append arrays as comma-separated strings
    //     // formData.append("age", JSON.stringify(selectedAgesForRequest));
    //     // formData.append("gender", JSON.stringify(selectedValuesgender?.map((option) => option?.value)));
    //     // formData.append("devices", JSON.stringify(selectedValuesdevices?.map((option) => option?.value)));


    //     // // Append complex objects like 'schedule'

    //     // formData.append("schedule", JSON.stringify(editUser?.schedule));
    //     // // formData.append("age", selectedAgesForRequest);
    //     // // formData.append("gender", selectedValuesgender?.map((option) => option?.value));
    //     // // formData.append("devices", selectedValuesdevices?.map((option) => option?.value));
    //     // // selectedAgesForRequest?.forEach((age) => {
    //     // //     formData.append("age", age); // Append each age as a separate key
    //     // // });
    //     // // selectedValuesgender?.forEach((option) => {
    //     // //     formData.append("gender", option?.value);
    //     // // });
    //     // // selectedValuesdevices?.forEach((option) => {
    //     // //     formData.append("devices", option?.value);
    //     // // });
    //     // // formData.append("advertisement_videos_upload", test);

    //     // if (advertisement_videos_upload) {
    //     //     // Append the selected PDF file
    //     //     formData.append("pdf_file", advertisement_videos_upload);
    //     // } else if (editUser?.EPaper) {
    //     //     // Fetch the existing PDF file and append it as a Blob
    //     //     const response = await fetch(editUser?.EPaper);
    //     //     const blob = await response?.blob();
    //     //     formData.append("pdf_file", blob, 'document.pdf'); // Specify the filename
    //     // }
    //     // const edituser = {
    //     //     ads_name: editUser?.ads_name,
    //     //     ads_slug: editUser?.ads_slug,
    //     //     ads_position: editUser?.ads_position,
    //     //     ads_upload_type: editUser?.ads_upload_type,
    //     //     ads_category: editUser?.ads_category,
    //     //     ads_redirection_url: editUser?.ads_redirection_url,
    //     //     location: editUser?.location,
    //     //     age: selectedAgesForRequest,

    //     //     languages_id: selectedValuesgender?.map((option) => option?.value),
    //     //     category_id: selectedValuesdevices?.map((option) => option?.value),

    //     //     ...formData,
    //     //     // display_for: selectedOptionslanguage?.map((option) => option?.value),
    //     // };

    //     const headers = {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + access_token,
    //         Accept: "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //     };

    //     let firstApiStatus = false;
    //     let resultapi = null;
    //     let resultapifalse = null;

    //     setProcessing(true);
    //     setShowOverlay(true);

    //     try {
    //         const response = await axios.patch(
    //             `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement-update/${id}`,
    //             formData,
    //             {
    //                 headers: {
    //                     ...headers,
    //                     'Content-Type': 'application/x-www-form-urlencoded' // Specify the content type
    //                 }
    //             }

    //         );

    //         if (response?.data?.status === true) {
    //             firstApiStatus = true;
    //             resultapi = response?.data;
    //         } else {
    //             resultapifalse = response?.data || { status: false, message: 'Label failed' };
    //         }
    //     } catch (error) {
    //         resultapifalse = { status: false, message: error.message || 'Request failed' };
    //     }

    //     if (firstApiStatus) {
    //         props?.setApiresponsealert(resultapi || { status: true, message: 'Label successful' });
    //         props?.setShowalert(true);
    //         setTimeout(() => {
    //             window.location.reload();
    //         }, 3000);
    //     } else {
    //         props?.setApiresponsealert(resultapifalse || { status: false, message: 'Label failed' });
    //         props?.setShowalert(true);
    //         setProcessing(false);
    //         setShowOverlay(false);
    //     }
    // }

    const [alluserads_upload_type, setAllUserads_upload_type] = useState("");
    function allads_upload_type() {
        switch (alluserads_upload_type) {
            case "tag_url":
                return <div>
                    <div className=" text-start">
                        <label className="m-0">
                            Ad Tag Url
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                className="rs-input form-control-lg"
                                id="ads_path"
                                name="ads_path"
                                placeholder="Enter Ads URL"
                                onChange={handleInput}
                                value={editUser?.ads_path}
                            />
                        </div>
                    </div>
                </div>;
            case "ads_video_upload":
                return <div>
                    <div className=" text-start">
                        <label className="m-0">
                            Ads Video Upload
                        </label>
                        <div className="mt-2">
                            <input
                                type="file"
                                className="form-control-lg rs-input"
                                name="video"
                                id="video"
                                accept="video/*"
                                onChange={(e) => setAdvertisement_videos_upload(e.target.files[0])}
                            />
                        </div>
                    </div>

                    <div className=" text-start mt-2">
                        <label className="m-0">
                            Ads Redirection URL
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                className="rs-input form-control-lg"
                                id="ads_redirection_url"
                                name="ads_redirection_url"
                                placeholder="Enter Ads Redirection URL"
                                onChange={handleInput}
                                value={editUser?.ads_redirection_url}
                            />
                        </div>

                    </div>

                </div>;
            default:
                return null;
        }
    }
    return (
        <div>


            <div className=" m-0 p-0">
                <section className="container-fluid">
                    <div className="col-lg-12 row py-3">
                        <div className="col-6 col-sm-6 col-lg-6">
                            <h3>Edit Advertisement</h3>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                            <div className="text-end">
                                <button onClick={handleStore} className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        {showOverlay && <Response_Processing></Response_Processing>}
                    </div>

                    <div className="row col-lg-12 ">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                            <div className="card p-3">
                                <h5>{editname?.ads_name}</h5>
                                <hr></hr>
                                <div className=" text-start">
                                    <label className="m-0">
                                        Ads Name<span className="mandatory">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            className="rs-input form-control-lg"
                                            id="ads_name"
                                            name="ads_name"
                                            placeholder="Enter Name"
                                            onChange={handleInput}
                                            value={editUser?.ads_name}
                                            ref={titleInputRef}
                                        />
                                    </div>
                                    <span className="errorred">
                                        {!isValidationHiddentitle && (
                                            <p>{validationMessagetitle}</p>
                                        )}
                                    </span>
                                </div>

                                <div className="row mt-2 text-start">
                                    <div className="col-sm-12">
                                        <label className="m-0">Ads Upload Type<span className="mandatory">*</span></label>
                                        <select
                                            onChange={handleInput}
                                            value={editUser?.ads_upload_type}
                                            name="ads_upload_type"
                                            id="ads_upload_type"
                                            className="form-select"
                                            ref={ads_upload_typeInputRef}
                                            onClick={(event) => {
                                                setAllUserads_upload_type(event.target.value);
                                            }}
                                        >
                                            <option value="">Select a User Type</option>
                                            {user_ads_upload_type?.map((item, key) => (
                                                <option key={key} value={item?.value}>
                                                    {item?.name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <div className='mt-2'>{allads_upload_type()}
                                    </div>

                                    <span className="errorred mt-2 d-block">
                                        {!isValidationHiddenads_upload_type && (
                                            <p>{validationMessageuser_ads_upload_type}</p>
                                        )}
                                    </span>
                                </div>

                                {/* <div className="text-start">
                                    <div>
                                        <div className="col-12 mt-2">
                                            <label className="m-0">Ads Age</label>
                                            <div className="mt-2">
                                                {apiResponseage?.map((age) => (
                                                    <div key={age?.value}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                value={age?.value}
                                                                checked={selectedAges.includes(age?.value)}
                                                                onChange={() => handleCheckboxChange(age?.value)}
                                                            />
                                                            {age?.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="card p-3">
                                <h5>Status Settings</h5>
                                <hr></hr>

                                <div className="col-sm-12 row mt-2">
                                    <div className="col-9 col-sm-9 col-lg-9">
                                        <p className="p2">Ads Publish</p>
                                    </div>
                                    <div className="col-3 col-sm-3 col-lg-3">
                                        <label className="switch">
                                            <input
                                                name="status"
                                                id="status"
                                                onChange={handleInputenable}
                                                className="r-input"
                                                defaultChecked={editUser?.status == 1 ? true : false}
                                                checked={editUser?.status == 1 ? true : false}
                                                type="checkbox"
                                            />
                                            <span
                                                className="slider round"
                                                name="status"
                                                id="status"
                                                onChange={handleInputenable}
                                                value={editUser?.status == 1 ? "1" : "0"}
                                            ></span>
                                        </label>
                                    </div>
                                </div>
                            </div>



                            {/* <div className="text-start mb-3">
                                <button className="btn btn-primary">
                                    Submit
                                </button>
                            </div> */}
                        </div>

                        {/* Second Part of Section  */}

                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="card p-3">
                                <h5>Category</h5>
                                <hr></hr>



                                <div className="row mt-2 text-start">
                                    <div className="col-12">
                                        <label className="m-0">Ads positon</label>

                                        <div className="mt-2">
                                            <select
                                                className="form-select"
                                                name="ads_position"
                                                id="ads_position"
                                                onChange={handleInput}
                                                value={editUser?.ads_position}
                                            >
                                                {/* {ads_positionselect?.map((item) => (
                                                    <option value={item?.value}>{item?.name}</option>
                                                ))} */}
                                                {ads_positionselect
                                                    ?.filter(item => item?.value !== 'all') // This will exclude the item with value "all"
                                                    .map((item) => (
                                                        <option key={item?.value} value={item?.value}>{item?.name}</option>
                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 text-start">
                                    <div className="col-12">
                                        <label className="m-0">Ads Category</label>

                                        <div className="mt-2">
                                            <select
                                                className="form-select"
                                                name="ads_category"
                                                id="ads_category"
                                                onChange={handleInput}
                                                value={editUser?.ads_category}
                                            >
                                                {ads_categoryselect?.map((item) => (
                                                    <option value={item?.id}>{item?.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-12  mt-2 ">
                                    <label className="m-0">Gender</label>

                                    <div className="mt-2">
                                        <Select
                                            options={optionsgender}
                                            isMulti
                                            className=""
                                            id="category"
                                            onChange={handleSelectChangegender}
                                            value={selectedValuesgender}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="col-12 mt-2">
                                    <label className="m-0">Devices</label>

                                    <div className="mt-2">
                                        <Select
                                            options={optionsdevices}
                                            isMulti
                                            className=""
                                            id="devices"
                                            onChange={handleSelectChangedevices}
                                            value={selectedValuesdevices}
                                        />
                                    </div>
                                </div> */}


                            </div>


                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default AdsUpload