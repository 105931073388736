import React, { useState, useEffect } from "react";
import { TbPlus } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import axios from "axios";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";

function AdsTimeSlot(props) {
  const [weeklyHours, setWeeklyHours] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
  };

  // Fetch existing time slots
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement/time-slot`,
          { headers: headers }
        );
        // Ensure all days are represented even if API data is missing some days
        const data = response?.data?.data || {};
        const completeData = {
          Monday: data.Monday || [],
          Tuesday: data.Tuesday || [],
          Wednesday: data.Wednesday || [],
          Thursday: data.Thursday || [],
          Friday: data.Friday || [],
          Saturday: data.Saturday || [],
          Sunday: data.Sunday || [],
        };
        setWeeklyHours(completeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Add a new time slot
  // Add a new time slot for a specific day
  const addTimeSlot = (day) => {
    const newSlot = { id: null, start_time: "", end_time: "" };
    setWeeklyHours((prev) => ({
      ...prev,
      [day]: [...prev[day], newSlot],
    }));
  };


  // Update a specific time slot
  const updateTimeSlot = (day, index, field, value) => {
    const updatedSlots = weeklyHours[day].map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    );
    setWeeklyHours((prev) => ({
      ...prev,
      [day]: updatedSlots,
    }));
  };

  // Delete a specific time slot
  const deleteTimeSlot = async (day, index) => {
    const slotToDelete = weeklyHours[day][index];

    if (slotToDelete?.id) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement/time-slot-delete/${slotToDelete?.id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        if (response?.data?.status === true) {
          setWeeklyHours((prev) => ({
            ...prev,
            [day]: prev[day].filter((_, i) => i !== index),
          }));
          props.setApiresponsealert(response?.data);
          props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          props.setApiresponsealert(response?.data);
          props.setShowalert(true);
          setProcessing(false);
          setShowOverlay(false);
        }
      } catch (error) {
        props.setApiresponsealert({ message: "An error occurred. Please try again." });
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    } else {
      setWeeklyHours((prev) => ({
        ...prev,
        [day]: prev[day].filter((_, i) => i !== index),
      }));
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const saveTimeSlots = async () => {
    const formData = new URLSearchParams();

    Object.keys(weeklyHours).forEach((day) => {
      weeklyHours[day].forEach((slot) => {
        formData.append("day", day);
        formData.append("start_time", slot?.start_time);
        formData.append("end_time", slot?.end_time);
      });
    });

    try {
      // Make the API call
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl_NODE_API}/admin/advertisement/time-slot-update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response?.data?.status === true) {
        props.setApiresponsealert(response?.data);
        props.setShowalert(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (response?.data?.status === false) {
        props.setApiresponsealert(response?.data);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    } catch (error) {
      console.error("Error:", error);
      props.setApiresponsealert({ message: "An error occurred. Please try again." });
      props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };


  return (
    <>

      <div>
        {showOverlay && (
          <div className="overlay-container">
            <span className="overlay-icon">
              {" "}
              <Response_Loading_processing></Response_Loading_processing>
              Processing ...
            </span>
          </div>
        )}
      </div>

      <section className="card p-2">
        <div className="container">
          <h4>Ads Time Slot</h4>
          <hr />
          <h5>Set your weekly hours</h5>
          {Object.keys(weeklyHours).map((day) => (
            <div key={day} className="mb-4">
              <div className="d-flex align-items-center">
                <h6>{day}</h6>
                <button className="btn btn-link" onClick={() => addTimeSlot(day)}>
                  <TbPlus />
                </button>
              </div>
              {weeklyHours[day].length === 0 ? (
                <p className="text-muted">No time slots added yet.</p>
              ) : (
                weeklyHours[day].map((slot, index) => (
                  <div className="row mb-2" key={slot.id}>
                    <div className="col-md-3">
                      <input
                        type="time"
                        className="form-control"
                        value={slot.start_time}
                        onChange={(e) =>
                          updateTimeSlot(day, index, "start_time", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="time"
                        className="form-control"
                        value={slot.end_time}
                        onChange={(e) =>
                          updateTimeSlot(day, index, "end_time", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteTimeSlot(day, index)}
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          ))}
          <button className="btn btn-primary" onClick={saveTimeSlots}>
            Save
          </button>
        </div>

      </section>
    </>
  );
}

export default AdsTimeSlot;
