import React from "react";
import { Link } from "react-router-dom";
import "./Main_labels.css";

function Main_labels() {

    return (
        <>

            <div className="iq-card">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item col-lg-6 col-md-6 col-sm-6 col-6" role="presentation">
                        <button class="nav-link active w-100" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Admin Labels</button>
                    </li>
                    <li class="nav-item col-lg-6 col-md-6 col-sm-6 col-6" role="presentation">
                        <button class="nav-link w-100" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Site Labels</button>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className=" m-0 p-0">
                            <section className="section container-fluid dashboard p-2">
                                <div className="col-lg-12 row">
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                                        <Link className="site-setting" to="/label/sidebar">
                                            <div className="sitecard p-2">
                                                <div className="row">
                                                    <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                                                        <div className="text-center fonti">
                                                            <img
                                                                src=""
                                                                className="w-100"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="card-body p-2">
                                                            <h5 className="card-title">Sidebar Management</h5>
                                                            <p className="">
                                                                Control the overall Sidebar of your Admin website
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                                        <Link className="site-setting" to="/label/videos">
                                            <div className="sitecard p-2">
                                                <div className="row">
                                                    <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                                                        <div className="text-center fonti">
                                                            <img
                                                                src=""
                                                                className="w-100"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="card-body p-2">
                                                            <h5 className="card-title">Video Management</h5>
                                                            <p className="">
                                                                Control the overall Video of your Admin website
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                                        <Link className="site-setting" to="/label/series">
                                            <div className="sitecard p-2">
                                                <div className="row">
                                                    <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                                                        <div className="text-center fonti">
                                                            <img src="" className="w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="card-body p-2">
                                                            <h5 className="card-title">Series Management</h5>
                                                            <p className="">
                                                                Control the overall Series of your Admin website
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                                        <Link className="site-setting" to="/label/live-stream">
                                            <div className="sitecard p-2">
                                                <div className="row">
                                                    <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                                                        <div className="text-center fonti">
                                                            <img src="" className="w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="card-body p-2">
                                                            <h5 className="card-title">Live Stream Management</h5>
                                                            <p className="">
                                                                Control the overall Live Stream of your Admin website
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                                        <Link className="site-setting" to="/label/audio">
                                            <div className="sitecard p-2">
                                                <div className="row">
                                                    <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                                                        <div className="text-center fonti">
                                                            <img src="" className="w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="card-body p-2">
                                                            <h5 className="card-title">Audio Management</h5>
                                                            <p className="">
                                                                Control the overall Audio of your Admin website
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            </section>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">


                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className=" m-0 p-0">
                                <section className="section container-fluid dashboard p-2">

                                    <div className="col-lg-12 row">
                                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-2">
                                            <Link className="site-setting" to="/label/details-manage">
                                                <div className="sitecard p-2">
                                                    <div className="row">
                                                        <div className=" col-3 col-sm-3 col-md-3 col-lg-3">
                                                            <div className="text-center fonti">
                                                                <img
                                                                    src=""
                                                                    className="w-100"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <div className="card-body p-2">
                                                                <h5 className="card-title">Details Management</h5>
                                                                <p className="">
                                                                    Control the overall Sidebar of your Admin website
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>

                                </section>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

        </>
    );
}

export default Main_labels;
