import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Editseason.css";
import Dropzone from "react-dropzone";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { Modal, Form, Table, Button } from 'react-bootstrap';
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";
import NavigationData from "../../../layout/Sidebar/NavigationData";

function Editseason(props) {
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const { label_management } = NavigationData();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    id: "",
    title: "",
    slug: "",
    user_id: "",
    series_id: "",
    trailer: "",
    image: "",
    access: "",
    ppv_price: "",
    order_id: "",
    ppv_interval: "",
    trailer_type: "",
    ios_product_id: "",
    uploaded_by: "",
    ppv_status: "",
    ios_ppv_price: "",
    global_ppv: "",
  });
  const [editUsername, setEditUsername] = useState({
    id: "",
    title: "",
    slug: "",
  });

  const [trailer_type, setTrailer_type] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedFile1, setSelectedFile1] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [withOutRefresh, setWithoutRefresh] = useState(false)
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");
  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [allliveuseraccessseason, setallliveuseraccessseason] = useState("");


  // const fileInputRef1 = useRef(null);

  // Handle file drop here
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  }, []);

  // Handle file selection from file input
  const handleFileChangessss = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };


  // const handleFileChange = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };



  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
  });

  const fileInputRef1 = useRef(null);
  const [selectedFile1, setSelectedFile1] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };


  const handleFileChange = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.season === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
  };


  const handleDelete = (index) => {
    setError('');
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  const [series, setSeries] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive

  const getUser = async () => {
    const reqData = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/series_season/edit/${id}`, { headers: headers });
    const resData = await reqData?.data
    var res = resData?.SeriesSeason[0];

    var Defaultppv_gobal_price = resData?.ppv_gobal_price;
    setppv_gobal_price(Defaultppv_gobal_price)
    var DefaultCurrencytest = resData?.DefaultCurrency[0];
    setAllCurrencylistfromappichoosed(DefaultCurrencytest);
    var DefaultGlobalContentPriceRates = resData?.GlobalContentPriceRates;
    setAllCurrencyglobal(DefaultGlobalContentPriceRates);
    var DefaultContentPriceRates = resData?.ContentPriceRates;
    setAllCurrencycustoms(DefaultContentPriceRates);


    const seriesData = resData?.Series?.access;
    setSeries(seriesData);

    setEditUser(res);
    setEditUsername(res);
    setLoading(false);
  };

  const create = async () => {
    const reqData = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/series_season/create`, { headers: headers });
    const resData = await reqData?.data;
    var result = resData?.user_access;
    var InappPurchase = resData?.InappPurchase;
    setUser_access(result);
    setInappPurchase(InappPurchase);
    var globelset = resData?.ppv_gobal_price;
    setInputValueAPI(globelset);
  };


  useEffect(() => {
    // Filter useraccessseason based on series value
    let options = [];
    switch (series) {
      case 'guest':
        options = user_access; // Show all options for guest
        break;
      case 'subscriber':
        options = user_access?.filter(option => ['subscriber', 'ppv'].includes(option.role || option.value));
        break;
      case 'registered':
        options = user_access?.filter(option => ['registered', 'subscriber', 'ppv'].includes(option.role || option.value));
        break;
      case 'ppv':
        options = user_access?.filter(option => ['subscriber', 'ppv'].includes(option.role || option.value));
        break;
      default:
        options = []; // No options for unknown series
    }
    setFilteredOptions(options);
  }, [series, user_access]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setValidationMessagetitleeditUser("");
    setIsValidationHiddentitleeditUser(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    setallliveuseraccessseason(value)
  };



  const [validationMessagetitleeditUser, setValidationMessagetitleeditUser] = useState("");
  const [isValidationHiddentitleeditUser, setIsValidationHiddentitleeditUser] = useState(true);

  const titleInputRefeditUser = useRef(null);

  const handleUpdate = async (e) => {

    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitleeditUser("Title cannot be empty.");
      setIsValidationHiddentitleeditUser(false);
      focusInputRef = titleInputRefeditUser;
    }

    let valid = true;

    if (compressResolutionFormat?.season === 1) {
      if (!selectedFile1 && !editUser?.image) {
        setError(prevErrors => ({ ...prevErrors, file4: 'Season title image is required.' }));
        valid = false;
      }
    }

    if (!valid) {
      return;
    }


    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();
    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      title: editUser?.title,
      slug: editUser?.slug,
      series_id: editUser?.series_id,
      trailer: editUser?.trailer,
      image: editUser?.image,
      global_ppv: checkboxChecked === true ? 1 : 0,
      ios_ppv_price: editUser?.ios_ppv_price,
      access: editUser?.access,
      order_id: editUser?.order_id,
      ppv_price: editUser?.ppv_price,
      ppv_interval: editUser?.ppv_interval,
      trailer_type: editUser?.trailer_type,
      ios_product_id: editUser?.ios_product_id,
      uploaded_by: editUser?.uploaded_by,
    };
    setShowOverlay(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/series_season/update/${id}`, editInputvalue, { headers: headers });
      const result = response.data
      if (result) {
        if (selectedFile1) {
          try {
            const formData = new FormData();
            formData.append("season_id", id);
            formData.append("image", selectedFile1);
            const imageResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Image-upload-series_season`, formData, { headers: headers });
            const result = imageResponse.data
            if (result) {
              setShowOverlay(false);
            }
            else {
              setShowOverlay(false);
              toast.error(result?.message)
            }
          }
          catch (err) {
            setShowOverlay(false);
            toast.error(result?.message)
          }
        }
        if (selectedFile) {
          try {
            const formDtrailervideo = new FormData();
            formDtrailervideo.append("season_id", id);
            formDtrailervideo.append("trailer", selectedFile);
            formDtrailervideo.append("trailer_type", trailer_type);
            // formDtrailervideo.append("trailer_filename", trailer_filename);
            const trailerResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Trailer-series_season`, formDtrailervideo, { headers: headers });
            const result = trailerResponse.data
            if (result) {
              setShowOverlay(false);
            }
            else {
              setShowOverlay(false);
              toast.error(result?.message)
            }
          }
          catch (err) {
            setShowOverlay(false);
            toast.error(result?.message)
          }
        }
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        toast.success(result?.message)
        setSelectedFile1(null)
        setSelectedFile(null)
        setWithoutRefresh(!withOutRefresh)
      }
      else {
        setShowOverlay(false);
        toast.error(result?.message)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: inputValueAPI };
      });
    } else {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: "" };
      });
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value; // setInputValue(event.target.value);
    setEditUser((prevState) => {
      return { ...prevState, [name]: value };
    });
  };



  // function alllive_streamuseraccess() {
  //   switch (allliveuseraccess) {
  //     case "guest":
  //       return <div></div>;
  //     case "subscriber":
  //       return <div></div>;
  //     case "registered":
  //       return <div></div>;
  //     case "ppv":
  //       return (
  //         <div className="row mt-3 text-start ">
  //           <div className="col-sm-12">
  //             <label className="m-0">PPV Price</label>
  //             <div className="col-lg-12 row">
  //               <div className="col-8 col-lg-8">
  //                 <p className="p1">Apply PPV Price from Global Settings? </p>
  //               </div>
  //               <div className="col-4 col-lg-4">
  //                 <label className="switch">
  //                   <input
  //                     name="ppv_price"
  //                     defaultChecked={checkboxChecked}
  //                     onChange={handleCheckboxChange}
  //                     type="checkbox"
  //                     className="rs-input"
  //                   />
  //                   <span
  //                     className="slider round"
  //                     name="ppv_price"
  //                     checked={checkboxChecked}
  //                     onChange={handleCheckboxChange}
  //                   ></span>
  //                 </label>
  //               </div>
  //             </div>

  //             <div className="panel-body">
  //               <input
  //                 type="number"
  //                 className="rs-input"
  //                 placeholder="PPV Price"
  //                 id="ppv_price"
  //                 name="ppv_price"
  //                 value={inputValue}
  //                 onChange={handleInputChange}
  //               />
  //             </div>
  //           </div>

  //           <div className="col-sm-12 mt-2">
  //             <label className="m-0"> IOS PPV Price</label>
  //             <p className="p1">Apply IOS PPV Price from Global Settings?</p>
  //             <div className="panel-body ppv_price_ios mt-2">
  //               <select
  //                 name="ios_ppv_price"
  //                 id="ios_ppv_price"
  //                 onChange={handleInput}
  //                 value={editUser?.ios_ppv_price}
  //                 className="form-select"
  //               >
  //                 {inapppurchase?.map((item, key) => (
  //                   <option value={item?.product_id}>{item?.plan_price}</option>
  //                 ))}
  //               </select>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // }

  function alllive_streamuseraccessseason() {
    switch (allliveuseraccessseason) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (


              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">PPV Price</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">Apply PPV Price from Global Settings? </p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="text"
                      className="rs-input"
                      placeholder="PPV Price"
                      name="ppv_price"
                      id="price"
                      // value={inputValue}
                      value={editUser?.ppv_price}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0"> IOS PPV Price</label>
                  <p className="p1">Apply IOS PPV Price from Global Settings?</p>
                  <div className="panel-body ppv_price_ios  mt-2">
                    <select
                      name="ios_product_id"
                      onChange={handleInput}
                      value={editUser?.ios_product_id}
                      id="ios_product_id"
                      className="form-select"
                    >
                      <option value="">Choose IOS PPV Price</option>
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.product_id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <label>PPV Interval</label>
                  <p className="p1">Please Mention How Many Episodes are Free</p>

                  <div className="panel-body ppv_price_ios  mt-2">
                    <input
                      type="text"
                      id="ppv_interval"
                      name="ppv_interval"
                      placeholder="( First Episode Free )"
                      onChange={handleInput}
                      value={editUser?.ppv_interval}
                      className="rs-input"
                    />
                  </div>
                </div>
              </div>
            ) :
              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label="Global Price"
                            name="priceOption"
                            value="1"
                            checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                            // onChange={handleChange}
                            onClick={() => handleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label="Custom Price"
                        name="priceOption"
                        value="2"
                        checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                        // onChange={handleChange}
                        onClick={() => handleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalType === '1' ? 'Set Global Price' : 'Set Custom Price'}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {step === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalType === '1' ? 'Global Price' : 'Custom Price'}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                disabled={modalType === '1'}
                              />
                            </Form.Group>
                            {modalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">Country</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">Country</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
<Button variant="primary" onClick={handleNext}>
Next
</Button>
</div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0"> IOS PPV Price</label>
                              <p className="p1">Apply IOS PPV Price from Global Settings?</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInput}
                                  value={editUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option value={item?.product_id}>{item?.plan_price}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
<Button variant="secondary" onClick={() => setStep(1)}>
Previous
</Button>
<Button variant="primary" onClick={handleSubmit}>
Submit
</Button>
</div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {step === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                            Submit
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );
      default:
        return null;
    }
  }

  useEffect(async () => {
    create();
    getUser();
  }, [id, withOutRefresh]);




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 6, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: id,
      PPVType: modalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/SeriesSeason/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 




  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Edit Season</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.series_season[13]?.title}
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">

                    <h5>{editUsername?.title} </h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">
                        {label_management?.series_season[3]?.title}<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">
                        {label_management?.series_season[3]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="title"
                          name="title"
                          className="rs-input form-control-lg"
                          value={editUser?.title}
                          onChange={handleInput}
                          placeholder="Series Title"
                          ref={titleInputRefeditUser}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddentitleeditUser && (
                          <p>{validationMessagetitleeditUser}</p>
                        )}
                      </span>
                    </div>



                    <div className="mt-2">
                      <div className="col-sm-12">
                        <label className="m-0">{label_management?.series_season[6]?.title}</label>
                        <p className="p1">
                          {label_management?.series_season[6]?.sub_title}
                        </p>

                        <select
                          onChange={handleInput}
                          name="access"
                          id="access"
                          value={editUser?.access}
                          className="form-select"
                          onClick={(event) => {
                            setallliveuseraccessseason(event.target.value);
                          }}
                        >
                          {/* <option value="">Choose User Access</option> */}
                          <option value="">Select a User Access</option>
                          {filteredOptions?.map((item) => (
                            <option key={item?.value} value={item?.role || item?.value}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>{alllive_streamuseraccessseason()}</div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>{label_management?.series_season[19]?.title}</h5>
                    <hr></hr>

                    <div className="mediavideo">
                      <label>{label_management?.series_season[5]?.title}</label>

                      {editUser?.trailer ? (
                        <div className=" py-2">


                          <div className="text-start">
                            <ReactPlayer
                              url={editUser?.trailer}
                              controls={true} // Enable player controls
                              width="100%"
                              height="200px"
                            />
                          </div>
                        </div>
                      ) : null}


                      <div className="dropzonestyle">
                        <Dropzone accept="video/*" onDrop={onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p className="p-2">
                                Drag and drop a video file here, or click to
                                select a file
                              </p>
                            </div>
                          )}
                        </Dropzone>
                        {selectedFile && (
                          <div>
                            {/* <label> Season Trailer :</label>
                                   <h3>Selected File:</h3> */}
                            <p>{selectedFile?.name}</p>
                          </div>
                        )}
                        <input
                          type="file"
                          accept="video/*"
                          style={{ display: "none" }}
                          onChange={handleFileChangessss}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.series_season[13]?.title}
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>{label_management?.series_season[20]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0 ">{label_management?.series_season[4]?.title}</label>
                    <p className="t-1">{label_management?.series_season[4]?.sub_title}</p>
                    {/* <img src={editUser?.image} width="150" height="100" /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.image}
                          alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedropseason"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopyimage">
                                <img
                                  src={URL.createObjectURL(selectedFile1)}
                                  alt="Uploaded"
                                  className="thumbnails-upload-images"
                                />
                                <button
                                  onClick={() => handleDelete(1)}
                                  type="button"
                                  className="btn-close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Editseason;
