import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { useEffect } from 'react';
const ApexAreaChart = ({ users, values, chartData }) => {
  const [data, setData] = useState({
    dateArray: [],
    adminTotal: [],
    registerTotal: [],
    subscriberTotal: []
  });

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const dateArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const formattedDay = String(day).padStart(2, '0');
      const formattedMonth = String(currentMonth + 1).padStart(2, '0');
      const dateString = `${currentYear}-${formattedMonth}-${formattedDay}`;
      dateArray.push(dateString);
    }

    setData((prev) => ({
      ...prev,
      dateArray: dateArray,
      start: values?.start,
      end: values?.end,
    }));

    const adminUsers = users.filter(user => user.role == "Admin");
    const registerUsers = users.filter(user => user.role == "Register");
    const subscriverUsers = users.filter(user => user?.role == "Subscriber");


    const adminCountByDate = dateArray.reduce((acc, date) => {
      acc[date] = 0;
      return acc;
    }, {});
    const registerCountByDate = dateArray.reduce((acc, date) => {
      acc[date] = 0;
      return acc;
    }, {});

    const subscriberCountByDate = dateArray.reduce((acc, date) => {
      acc[date] = 0;
      return acc;
    }, {});

    // for
    adminUsers.forEach(user => {
      const date = user.created_at.split('T')[0];
      if (adminCountByDate[date] !== undefined) {
        adminCountByDate[date] += 1;
      }
    });
    registerUsers.forEach(user => {
      const date = user.created_at.split('T')[0];
      if (registerCountByDate[date] !== undefined) {
        registerCountByDate[date] += 1;
      }
    });
    subscriverUsers.forEach(user => {
      const date = user.created_at.split('T')[0];
      if (subscriberCountByDate[date] !== undefined) {
        subscriberCountByDate[date] += 1;
      }
    });


    const adminCountArray = dateArray.map(date => {
      const formattedDate = date.replace(/-/g, "/");
      return [formattedDate, adminCountByDate[date]];
    });
    const registerCountArray = dateArray.map(date => {
      const formattedDate = date.replace(/-/g, "/");
      return [formattedDate, registerCountByDate[date]];
    });

    const subscriberCountArray = dateArray.map(date => {
      const formattedDate = date.replace(/-/g, "/");
      return [formattedDate, subscriberCountByDate[date]];
    });
    setData((prev) => ({
      ...prev,
      adminTotal: adminCountArray,
      registerTotal: registerCountArray,
      subscriberTotal: subscriberCountArray,
    }));

    // const adminCountArray = dateArray.map(date => adminCountByDate[date]);

  }, [users, values])

  let options = {
    series: chartData?.series,
    chart: {
      type: 'line',
      stacked: false,
      height: 350,
      // zoom: {
      //   type: 'x',
      //   enabled: true,
      //   autoScaleYaxis: true
      // },
      // toolbar: {
      //   autoSelected: 'zoom'
      // }
      toolbar: { show: false },
      zoom: {
        enabled: false,
      }
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 0,
    },
    // colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
    fill: {
      opacity: 1
      // colors: ['#000'],
      // type: 'gradient',
      // gradient: {
      //   shadeIntensity: 1,
      //   inverseColors: false,
      //   opacityFrom: 0.45,
      //   opacityTo: 0.05,
      //   stops: [20, 100, 100, 100]
      // },
    },
    yaxis: {
      title: {
        text: 'Count'
      },
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    xaxis: {
      // type: 'datetime',
      // tickAmount: 10,.
      title: {
        text: 'Date'
      },
      // min: new Date(data?.start).getTime(),
      // max: new Date(data?.end).getTime()
      categories: chartData?.categories,
      // categories: ["Jan", "Feb", "Mar"],
    },
    title: {
      text: '',
      align: 'left',
      offsetX: 14,
    },
    toolbar: { show: false },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetX: -10,
    },
  }
  return (
    <div id="chart" className='theme-bg-color rounded-3'>
      <Chart options={options} series={options.series ? options.series : []} type="area" height={350} />
    </div>
  );
};

export default ApexAreaChart;
