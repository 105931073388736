import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import ExportIcon from "../../../components/Images/icons/Export_Icon_01.png";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import "./PurchasePlan.css";

function PurchasePlan(props) {
  const [dataplan, setDataplan] = useState([]);
  const [filteredPlanData, setFilteredPlanData] = useState([]);
  const [itemToDeletePlan, setItemToDeletePlan] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalClosePlan, setModalClosePlan] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [product_id, setProduct_id] = useState("");
  const [plan_price, setPlan_price] = useState("");
  const [symbol, setSymbol] = useState("");
  const [videodataplan, setVideodataplan] = useState("");

  const product_idInputRef = useRef(null);
  const plan_priceInputRef = useRef(null);

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.CurrencySetting[0]?.symbol;
        setSymbol(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const fetchDataplan = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/index`,
        { headers: headers }
      );
      const planData = response?.data?.All_Inapp_Purchase;
      const FilterHelp = planData?.map((item) => {
        return {
          ...item,
          created_at: formatDate(item?.created_at),
        };
      });

      setDataplan(FilterHelp);
      setFilteredPlanData(FilterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchDataplan();
  }, []);

  const [validationMessageproduct_id, setValidationMessageproduct_id] =
    useState("");
  const [isValidationHiddenproduct_id, setIsValidationHiddenproduct_id] =
    useState(true);

  const [validationMessageplan_price, setValidationMessageplan_price] =
    useState("");
  const [isValidationHiddenplan_price, setIsValidationHiddenplan_price] =
    useState(true);

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredPlanData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeletePlan([...itemToDeletePlan, row.id]);
        } else {
          setItemToDeletePlan(itemToDeletePlan?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDeletePlan,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const userFormatter = (data, row) => {
    return (
      <div className="d-flex flex-column align-items-start">
        <span>{data}</span>
        <span className="dateText">{row?.created_at}</span>
      </div>
    );
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    return `${formattedDate} ${formattedTime}`;
  }

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </span>
        </span>
        <div className="editdropdown-menu">
          <Link to={`/edit-inapp-purchase/${row?.id}`}>
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2"> Edit Purchase </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeletePlan(row?.id)}
            className="commonActionPadding"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2">Delete Purchase </span>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "plan_price",
      text: "Plan Price",
      formatter: userFormatter,
      sort: true,
    },
    {
      dataField: "product_id",
      text: "Product Id",
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const exportHeadersPlan = [
    { label: "ID", key: "id" },
    { label: "PLAN PRICE", key: "plan_price" },
    { label: "PRODUCT ID", key: "product_id" },
    { label: "UPLOAD-ON", key: "created_at" },
  ];

  const conditionalGeneratePDFPlan = (get) => {
    const doc = new jsPDF();
    doc.text("PURCHASE LIST ", 10, 10);
    const columns = ["INDEX", "ID", "PLAN PRICE", "PRODUCT ID", "UPLOAD-ON"];
    const allDataPDF = dataplan?.map((item, index) => [
      index + 1,
      item?.id,
      item?.plan_price,
      item?.product_id,
      item?.created_at,
    ]);
    const filterPDF = filteredPlanData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.plan_price,
      item?.product_id,
      item?.created_at,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("PURCHASE-LIST.pdf");
  };

  const generateXLSXPLAN = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? dataplan : filteredPlanData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "PURCHASE-LIST.xlsx");
  };

  const handleFilterChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = dataplan.filter((item) =>
      ["plan_price", "product_id", "created_at"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredPlanData(filteredResults);
  };

  const handleClear = () => {
    fetchDataplan();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeletePlan(
      selectAll ? [] : filteredPlanData?.map((item) => item?.id)
    );
  };

  const closePlanModal = () => {
    setModalClosePlan(!modalClosePlan);
  };

  const openModalSingleDeletePlan = (id) => {
    setItemToDeletePlan([id]);
    setModalClosePlan(true);
  };

  const openModalMultiDeletePlan = () => {
    setModalClosePlan(true);
  };

  const handleDeletePlanData = () => {
    closePlanModal();
    planDeleteOperation();
  };

  const planDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeletePlan + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        props?.setApiresponsealert(result);
        props?.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      props?.setApiresponsealert({ status: false, message: errorMessage });
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  async function savePage() {
    let focusInputRef = null;

    if (plan_price === "") {
      setValidationMessageplan_price("Plan Price cannot be empty.");
      setIsValidationHiddenplan_price(false);
      focusInputRef = plan_priceInputRef;
    }
    if (product_id === "") {
      setValidationMessageproduct_id("Product Id cannot be empty.");
      setIsValidationHiddenproduct_id(false);
      focusInputRef = product_idInputRef;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }

    const formData = new FormData();

    formData.append("plan_price", plan_price);
    formData.append("product_id", product_id);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/inapp-purchase/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus) {
      setValidationMessageplan_price("");
      setValidationMessageproduct_id("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;

        console.log(resultError);
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenplan_price(false);
        setIsValidationHiddenproduct_id(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div
        className={`modal  packageModel ${modalClosePlan ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: modalClosePlan ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closePlanModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeletePlan?.length === 1 ? (
                <p className="fs-6">
                  {" "}
                  Are you sure you want to delete this Purchase ?
                </p>
              ) : (
                <p className="fs-6">
                  Are you sure you want to delete the selected Purchase ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closePlanModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeletePlanData}
              >
                Yes
              </button>{" "}
            </div>
          </div>
        </div>
      </div>

      <div>
        {showOverlay && <Response_Processing></Response_Processing>}
      </div>

      <div
        className="modal fade"
        id="newplan"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add In App Purchase Plan
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <label>Plan Price ( {symbol} )</label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="plan_price"
                    placeholder="Enter Plan Price"
                    className="rs-input form-control-lg"
                    onChange={(e) => setPlan_price(e.target.value)}
                    ref={plan_priceInputRef}
                  />
                </div>
                <span className="errorred">
                  {!isValidationHiddenplan_price && (
                    <p>{validationMessageplan_price}</p>
                  )}
                </span>
              </div>
            </div>
            <div className="modal-body">
              <div className="">
                <label>Product ID</label>

                <div className="mt-2">
                  <input
                    type="text"
                    name="product_id"
                    placeholder="Enter Product ID "
                    className="rs-input form-control-lg"
                    onChange={(e) => setProduct_id(e.target.value)}
                    ref={product_idInputRef}
                  />
                </div>
                <span className="errorred">
                  {!isValidationHiddenproduct_id && (
                    <p>{validationMessageproduct_id}</p>
                  )}
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary ml-3 "
                onClick={savePage}
              >
                Save Purchase
              </button>
            </div>
          </div>
        </div>
      </div>

      <section className="section container-fluid pt-3">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4>
                All Purchase Plan{" "}
                <span className="fs-6">
                  (Display all {dataplan?.length} Plan)
                </span>
              </h4>
            </div>
            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeletePlan?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeletePlan}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#newplan"
              >
                <i className="fa fa-plus-circle"></i> Add Plan
              </button>
            </div>
          </div>
          <div className="iq-card mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChange}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersPlan}
                        data={filteredPlanData}
                        className="text-dark d-block"
                        filename="PURCHASE-FILTERED-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFPlan(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXPLAN(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersPlan}
                        data={dataplan}
                        className="text-dark d-block"
                        filename="PURCHASE-ALL-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFPlan(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXPLAN(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredPlanData}
                pagination={pagination}
                className="no-border-table"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>
                        Loading...
                      </label>
                    ) : (
                      filteredPlanData?.length <= 0 && (
                        <p>No Purchase Plans Available !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="AlbumWidth"
                selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PurchasePlan;
