import React, { useState, useEffect } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, } from "react-icons/md";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import { ActionFormatterComponent, videoFormatter } from "../../../components/CommonMethod/CommonBoostrapFormatter";
import { artistDefaultURL } from "../../../components/CommonApis/CommonArtistApis";
import { adminApiHeader } from "../../../components/CommonApis/CommonApiUrl";
import AdminDeleteModal from "../../../components/CommonComponents/AdminDeleteModal";
import AdminHeader from "../../../components/CommonComponents/AdminHeader";
import { toast } from "react-toastify";
import AdminNavbar from "../../../components/CommonComponents/AdminNavbar";
import NoRecordTable from "../../../components/CommonComponents/NoRecordTable";

import NavigationData from "../../../layout/Sidebar/NavigationData";
function AllArtist() {

  const { label_management } = NavigationData();
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [withOutRefresh, setWithoutRefresh] = useState(false)

  //Bootstrap Table util start
  const columns = [
    {
      dataField: "artist_name",
      text: `${label_management?.Sidebar_admin[148]?.title}`,
      formatter: (cell, row) => videoFormatter(cell, row, artistDefaultURL?.viewTab),
      sort: true,
      headerClasses: 'video-width-table'
    },
    {
      dataField: "artist_type",
      text: `${label_management?.Sidebar_admin[148]?.title} Type`,
      sort: true,
    },

    {
      dataField: "uploaded_by",
      text: "Upload by",
      sort: true,
    },

    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: (cell, row) => <ActionFormatterComponent cell={cell} row={row} link={artistDefaultURL?.editRouteLink} action={singleDelete} />,
    },
  ];

  const options = {
    pageStartIndex: 1,
    paginationSize: 4,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: Number(filteredData?.length)
    }]
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    style: { width: '30px' },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };

  //Bootstrap Table util end

  // api start
  const fetchData = async () => {
    try {
      const response = await axios.get(`${artistDefaultURL?.listApi}`, { headers: adminApiHeader });
      const result = response?.data?.[artistDefaultURL?.fetchArrayName];
      const updatedList = result?.map((item) => {
        const UploadStatus = item?.uploaded_by === "1" ? "Admin" : item?.uploaded_by === "4" ? "Channel Partner" : item?.uploaded_by === "5" ? "Content Partner" : "";
        return { ...item, uploaded_by: UploadStatus };
      });
      setApiData(updatedList);
      setFilteredData(updatedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    setShowOverlay(true);
    try {
      const formData = new FormData();
      formData.append(`${artistDefaultURL?.reqBodyName?.deleteName}`, "[" + itemToDelete + "]");
      const response = await axios.post(`${artistDefaultURL?.MultiDeleteApi}`, formData, { headers: adminApiHeader });
      const result = response?.data;
      if (result?.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(result?.message);
        setShowOverlay(false);
        setItemToDelete([])
      }
      else {
        toast.error(result?.message);
        setShowOverlay(false);
      }
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };

  // Api links end 
  const closeModal = () => {
    setModelClose(!closeModal);
  };

  const openModal = () => {
    setModelClose(true);
  };

  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };

  const handleDeletedata = () => {
    handleConfirmDelete();
    closeModal();
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
  };

  const handleFilterChange = (data) => {
    setFilteredData(data);
  };


  useEffect(async () => {
    fetchData();
  }, [withOutRefresh]);

  return (
    <>
      {showOverlay && <Response_Processing />}
      {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={handleDeletedata} buttonText={itemToDelete?.length} />}
      <AdminNavbar heading={`All ${label_management?.Sidebar_admin[148]?.title}`} totalDataLength={apiData?.length} deleteCount={itemToDelete?.length} navigation={artistDefaultURL?.addRouteLink} deleteMethod={openModal} navText={`Add ${label_management?.Sidebar_admin[148]?.title}`} />
      <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width" >
        <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={artistDefaultURL?.exportFileName} customArtist />
        <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
      </div>
    </>
  );
}
export default AllArtist;