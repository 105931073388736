import React, { useCallback, useEffect, useState } from 'react'
import ExportIcon from "../Images/icons/Export_Icon_01.png";
import { CSVLink } from "react-csv";
import { IoClose } from 'react-icons/io5';
import { CSVColumn, CSVColumnPPV, CSVColumnSubscriber, CSVColumnUser, PDFGenerator, PDFGeneratorPPV, PDFGeneratorSubscriber, PDFGeneratorUser, XLSXGenerator, XLSXGeneratorPPV, XLSXGeneratorSubscriber, XLSXGeneratorUser, albumCSVColumn, albumPDFGenerator, albumXLSXGenerator, artistCSVColumn, artistPDFGenerator, artistXLSXGenerator, } from '../CommonMethod/CommonMethods';
import { RxReload } from 'react-icons/rx';
const AdminHeader = React.memo(({ searchMethod, reduceData, originalData, fileName, customAlbum, customArtist, customUser, reloadAction, reload, loading, customSubscriber, customppv }) => {

  const [filter, setFilter] = useState("");
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = originalData.filter((item) =>
      ["id", "name", "mobile", "email", "role", "title", "albumname", "artist_name", "artist_type", "uploaded_by", "active", "upload_on", "PaymentGateway", "subscription_id", "subscription_price", "countryname", "plans_name", "user_name", "payment_id", "payment_price"].some(
        (property) =>
          typeof item[property] === "string" && item[property].toLowerCase().includes(searchTerm)
      ) ||
      (typeof item.id === "number" && item.id.toString().includes(searchTerm))
    );
    searchMethod(filteredResults);
  };

  const handleClear = useCallback(() => {
    searchMethod(originalData);
    setFilter('');
  }, [originalData, searchMethod]);

  useEffect(() => {
    setFilter('');
  }, [loading]);
  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
      <div className="filterContainer">
        <input type='text' placeholder="Search the list " className="filter form-control theme-text-color theme-border-secondary theme-bg-color-secondary" value={filter} onChange={(event) => handleFilter(event)} />
        {filter?.length > 0 && (<button type="button" className="clearBtn" onClick={() => handleClear()}  ><IoClose className="custom-close-btn" /> </button>)}
      </div>

      <div className="btn-group">
        {reload ? (
          <button className={`bg-transparent reloadButton p-2 me-2 primary border border-2 rounded-circle theme-border-color ${loading ? "rotate-load" : ""}`} onClick={reloadAction}>
            <RxReload style={{ width: "18px", height: "15px" }} />
          </button>
        ) : ""}
        <div className="dropdown d-block " key={"2"}>
          <button className="btn btn-success dropdown-toggle d-flex hideArrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  >
            <span className="fw-bolder text-white">Export </span>
            <img src={ExportIcon} className="ms-2 exportIcon" alt="exportIcon" />
          </button>
          <ul className="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuButton1" >
            <li className="dropdown-item theme-text-color"><CSVLink headers={customAlbum ? albumCSVColumn : customArtist ? artistCSVColumn : customUser ? CSVColumnUser : customSubscriber ? CSVColumnSubscriber : customppv ? CSVColumnPPV : CSVColumn} data={reduceData} className="theme-text-color d-block" filename={`FILTERED-${fileName}.csv`} > CSV</CSVLink> </li>
            <li className="dropdown-item theme-text-color" onClick={() => { customAlbum ? albumPDFGenerator(reduceData, `FILTERED-${fileName}`) : customArtist ? artistPDFGenerator(reduceData, `FILTERED-${fileName}`) : customUser ? PDFGeneratorUser(reduceData, `FILTERED-${fileName}`) : customSubscriber ? PDFGeneratorSubscriber(reduceData, `FILTERED-${fileName}`) : customppv ? PDFGeneratorPPV(reduceData, `FILTERED-${fileName}`) : PDFGenerator(reduceData, `FILTERED-${fileName}`); }} > PDF</li>
            <li className="dropdown-item theme-text-color" onClick={() => { customAlbum ? albumXLSXGenerator(reduceData, `FILTERED-${fileName}`) : customArtist ? artistXLSXGenerator(reduceData, `FILTERED-${fileName}`) : customUser ? XLSXGeneratorUser(reduceData, `FILTERED-${fileName}`) : customSubscriber ? XLSXGeneratorSubscriber(reduceData, `FILTERED-${fileName}`) : customppv ? XLSXGeneratorPPV(reduceData, `FILTERED-${fileName}`) : XLSXGenerator(reduceData, `FILTERED-${fileName}`); }}> XLSX </li>
          </ul>
        </div >
        <div className="dropdown d-block ms-2 " key={"2"}>
          <button className="btn btn-success dropdown-toggle d-flex hideArrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  >
            <span className="fw-bolder text-white">Export all</span>
            <img src={ExportIcon} className="ms-2 exportIcon" alt="exportIcon" />
          </button>
          <ul className="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuButton1" >
            <li className="dropdown-item theme-text-color"><CSVLink headers={customAlbum ? albumCSVColumn : customArtist ? artistCSVColumn : customUser ? CSVColumnUser : customSubscriber ? CSVColumnSubscriber : customppv ? CSVColumnPPV : CSVColumn} data={originalData} className="theme-text-color d-block" filename={`${fileName}.csv`} > CSV</CSVLink> </li>
            <li className="dropdown-item theme-text-color" onClick={() => { customAlbum ? albumPDFGenerator(originalData, fileName) : customArtist ? artistPDFGenerator(originalData, fileName) : customUser ? PDFGeneratorUser(originalData, fileName) : customSubscriber ? PDFGeneratorSubscriber(originalData, fileName) : customppv ? PDFGeneratorPPV(originalData, fileName) : PDFGenerator(originalData, fileName); }}> PDF</li>
            <li className="dropdown-item theme-text-color" onClick={() => { customAlbum ? albumXLSXGenerator(originalData, fileName) : customArtist ? artistXLSXGenerator(originalData, fileName) : customUser ? XLSXGeneratorUser(originalData, fileName) : customSubscriber ? XLSXGeneratorSubscriber(originalData, fileName) : customppv ? XLSXGeneratorPPV(originalData, fileName) : XLSXGenerator(originalData, fileName); }}> XLSX </li>
          </ul>
        </div>
      </div >
    </div >
  )
})
export default AdminHeader