import React, { useState, useEffect, useRef } from "react";
import deleteitem from "../../../components/Images/deleteitem.svg";
import edititem from "../../../components/Images/edititem.svg";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";

import NavigationData from "../../../layout/Sidebar/NavigationData";

function VideoLanguage(props) {
  const { label_management } = NavigationData();
  const [datavideolanguage, setDatavideolanguage] = useState([]);
  const [countvideolanguage, setCountvideolanguage] = useState();
  const [pagevideolanguage, setPagevideolanguage] = useState(1);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [videodatavideolanguage, setVideodatavideolanguage] = useState("");
  const [loading, setLoading] = useState(true);
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPagevideolanguage, setCurrentPagevideolanguage] = useState(1);
  const itemsPerPagevideolanguage = 10;

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (index) => {
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      default:
        break;
    }
  };

  const videosdeleteOperationvideolanguage = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(`${process.env.REACT_APP_Baseurl}/admin/language/delete/` + id, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          props?.setApiresponsealert(responseData);
          props?.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData === false) {
            var resultError = responseData;
            props?.setApiresponsealert(resultError);
            props?.setShowalert(true);
          }
        }
      });
  };

  const [selectedItemsvideolanguage, setSelectedItemsvideolanguage] = useState(
    []
  );
  const [selectAllvideolanguage, setSelectAllvideolanguage] = useState(false);
  const [isConfirmationOpenvideolanguage, setIsConfirmationOpenvideolanguage] =
    useState(false);
  const [
    isSuccessMessageOpenvideolanguage,
    setIsSuccessMessageOpenvideolanguage,
  ] = useState(false);

  const handleSingleSelectvideolanguage = (itemId) => {
    setSelectedItemsvideolanguage([itemId]);
    setSelectAllvideolanguage(false);
  };

  const handleMultiSelectvideolanguage = (itemId) => {
    if (selectedItemsvideolanguage.includes(itemId)) {
      setSelectedItemsvideolanguage(
        selectedItemsvideolanguage.filter((id) => id !== itemId)
      );
    } else {
      setSelectedItemsvideolanguage([...selectedItemsvideolanguage, itemId]);
    }
  };

  const handleSelectAllvideolanguage = () => {
    setSelectAllvideolanguage(!selectAllvideolanguage);
    setSelectedItemsvideolanguage(
      selectAllvideolanguage
        ? []
        : videodatavideolanguage.map((item) => item?.id)
    );
  };

  const handleDeletevideolanguage = () => {
    setIsConfirmationOpenvideolanguage(true);
  };
  const handleCancelDeletevideolanguage = () => {
    setIsConfirmationOpenvideolanguage(false);
  };

  const handleConfirmDeletevideolanguage = () => {
    setIsConfirmationOpenvideolanguage(false);
    for (const itemId of selectedItemsvideolanguage) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/admin/language/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodatavideolanguage(
              videodatavideolanguage.filter((item) => item?.id !== itemId)
            );
            setSelectedItemsvideolanguage(
              selectedItemsvideolanguage.filter((id) => id !== itemId)
            );
            setIsSuccessMessageOpenvideolanguage(true);
            setTimeout(() => {
              setIsSuccessMessageOpenvideolanguage(false);
            }, 2000);
            var responseData = response.data;
            props?.setApiresponsealert(responseData);
            props?.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData === false) {
              var resultError = responseData;
              props?.setApiresponsealert(resultError);
              props?.setShowalert(true);
            }
          }
        });
    }
  };

  const fetchDatanextprevideolanguage = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/language/index?page=${pagevideolanguage}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.Language?.data;
        setVideodatavideolanguage(videosData);
        if (videosData.length > 0) {
          setDatavideolanguage(videosData);
          setLoading(false);
        } else {
          setDatavideolanguage([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchDatavideolanguage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/language/index`,
        { headers: headers }
      );
      setCountvideolanguage(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchdefaultimagevideolanguage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Vertical-Default-Image`,
        { headers: headers }
      );
      setDefaultimage(response?.data?.Vertical_Default_Image);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchdefaultimageurlvideolanguage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Default-image-url`,
        { headers: headers }
      );
      setDefaultimageurl(response?.data?.Default_Image_Url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const indexOfLastItemvideolanguage =
    currentPagevideolanguage * itemsPerPagevideolanguage;
  const indexOfFirstItemvideolanguage =
    indexOfLastItemvideolanguage - itemsPerPagevideolanguage;
  const currentItemsvideolanguage = datavideolanguage?.slice(
    indexOfFirstItemvideolanguage,
    indexOfLastItemvideolanguage
  );

  const handlePreClickvideolanguage = () => {
    if (pagevideolanguage > 1) {
      setPagevideolanguage(pagevideolanguage - 1);
    }
  };

  const handleNxtClickvideolanguage = () => {
    setPagevideolanguage(pagevideolanguage + 1);
  };

  const [itemToDeletevideolanguage, setItemToDeletevideolanguage] =
    useState(null);
  const [successMessagevideolanguage, setSuccessMessagevideolanguage] =
    useState("");

  const openModalvideolanguage = (id) => {
    setItemToDeletevideolanguage(id);
    setSuccessMessagevideolanguage("");
  };

  const closeModalvideolanguage = () => {
    setItemToDeletevideolanguage(null);
  };
  const handleDeletedata = () => {
    closeModalvideolanguage();
    if (itemToDeletevideolanguage) {
      videosdeleteOperationvideolanguage(itemToDeletevideolanguage);
    }
  };

  useEffect(async () => {
    fetchDatavideolanguage();
    fetchdefaultimagevideolanguage();
    fetchdefaultimageurlvideolanguage();
    fetchDatanextprevideolanguage();
  }, [pagevideolanguage]);
  const [editUser, setEditUser] = useState({
    name: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagename("");
    setIsValidationHiddenname(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagename, setValidationMessagename] = useState("");
  const [isValidationHiddenname, setIsValidationHiddenname] = useState(true);

  const nameInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function savelanguages() {
    let focusInputRef = null;

    if (editUser?.name === "") {
      setValidationMessagename("Name cannot be empty.");
      setIsValidationHiddenname(false);
      focusInputRef = nameInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    const formData = new FormData();

    formData.append("name", editUser?.name);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/language/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status === true) {
        firstApiStatus = true;

        resultapi = response.data;
        var Language_id = response.data.Language_id;

        const imageFormData = new FormData();
        imageFormData.append("language_id", Language_id);

        if (selectedFile1) {
          imageFormData.append("Language_image", selectedFile1);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/language/image-update`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse.data.status === true) {
          secondApiStatus = true;
        }
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagename("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenname(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  return (
    <>
      <div
        className={`modal ${itemToDeletevideolanguage !== null ? "show" : ""}`}
        tabIndex="-1"
        style={{
          display: itemToDeletevideolanguage !== null ? "block" : "none",
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModalvideolanguage}
              ></button>
            </div>
            <div className="modal-body">
              {successMessagevideolanguage ? (
                <div className="alert alert-success" role="alert">
                  {successMessagevideolanguage}
                </div>
              ) : (
                <p>Are you sure you want to delete this {label_management?.Sidebar_admin[149]?.title}?</p>
              )}
            </div>
            <div className="modal-footer">
              {successMessagevideolanguage ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeModalvideolanguage}
                >
                  Close
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModalvideolanguage}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeletedata}
                  >
                    Yes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delete_confirm">
          <div className="modal-content text-center">
            <div className="modal-body">
              {isConfirmationOpenvideolanguage && (
                <div>
                  <h5 className="modal-title p-3" id="exampleModalLabel">
                    <p className="fs-6">
                      Are you sure you want to delete the selected items?
                    </p>
                  </h5>
                  <div className="col-lg-12 row p-4">
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          handleConfirmDeletevideolanguage();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelDeletevideolanguage}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {isSuccessMessageOpenvideolanguage && (
                <div className="success-message">
                  <div className="p-5">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <p className="fs-6 fw-bold">
                    Selected items have been successfully deleted.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" m-0 p-0">
        <div className="container-fluid">
          <section className="section all-video-stream ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>
                  {`All ${label_management?.Sidebar_admin[149]?.title}`}
                  <span className="fs-6 ms-2">
                    {`Display all ${countvideolanguage} ${label_management?.Sidebar_admin[149]?.title}`}
                  </span>
                </h3>
              </div>
              <div className="col-3 col-sm-3 col-lg-3 text-end">
                {selectedItemsvideolanguage?.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleDeletevideolanguage}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Delete Selected
                  </button>
                )}
              </div>

              <div
                className="modal fade"
                id="addlanguage"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add {label_management?.Sidebar_admin[149]?.title}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div>
                        <label>
                          Name<span className="mandatory">*</span>
                        </label>

                        <div className="mt-2">
                          <input
                            type="text"
                            name="name"
                            placeholder=""
                            className="rs-input form-control-lg"
                            onChange={handleInput}
                            value={editUser?.name}
                            ref={nameInputRef}
                          />
                        </div>

                        <span className="errorred">
                          {!isValidationHiddenname && (
                            <p>{validationMessagename}</p>
                          )}
                        </span>
                      </div>
                      <div className="mt-3">
                        <label>Thumbnail</label>
                        <p>
                          <small>
                            Select The {label_management?.Sidebar_admin[149]?.title} Image ( 1:1 Ratio Or 1024 X
                            1024px )
                          </small>
                        </p>

                        <div className=" text-start mt-2">
                          {/* <label className="m-0">Image</label> */}

                          <div className="mt-2">
                            <div className="col-lg-12 row">
                              <div className=" col-sm-6 col-lg-6">
                                <div
                                  className="imagedrop"
                                  onClick={() => fileInputRef1.current.click()}
                                >
                                  <input
                                    type="file"
                                    ref={fileInputRef1}
                                    onChange={(event) =>
                                      handleFileChange(
                                        event,
                                        fileInputRef1,
                                        setSelectedFile1
                                      )
                                    }
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  <label>
                                    <CameraRetroIcon />
                                  </label>
                                </div>
                              </div>
                              <div className=" col-sm-6 col-lg-6">
                                <div>
                                  {selectedFile1 && (
                                    <div className="imagedropcopy text-end">
                                      <div className="col-lg-12 row p-0 m-0">
                                        <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                          <img
                                            src={URL.createObjectURL(
                                              selectedFile1
                                            )}
                                            alt="Uploaded"
                                            className="thumbnails-upload-images"
                                          />
                                        </div>
                                        <div
                                          className="col-sm-2 col-lg-2 p-0 m-0"
                                          style={{ alignSelf: "center" }}
                                        >
                                          <button
                                            onClick={() => handleDelete(1)}
                                            type="button"
                                            className="btn-close"
                                            // data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className=" ">
                          <label>Image:</label>
                          <input
                            type="file"
                            multiple="true"
                            className="rs-input form-control-lg"
                            name="image"
                            id="image"
                            onChange={(e) =>
                              setLanguageimage(e.target.files[0])
                            }
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-primary ml-3 "
                        onClick={savelanguages}
                      >
                        Save {label_management?.Sidebar_admin[149]?.title}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-3 col-sm-3 col-lg-3 text-end">
                <Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#addlanguage"
                  >
                    Add {label_management?.Sidebar_admin[149]?.title}
                  </button>
                </Link>
              </div>
            </div>

            <div>
              {showOverlay && (
                <div className="overlay-container">
                  <span className="overlay-icon">
                    {" "}
                    <Response_Loading_processing></Response_Loading_processing>
                    Processing ...
                  </span>
                </div>
              )}
            </div>

            <div className="col-lg-12 ">
              <div className="card">
                {loading ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllvideolanguage}
                                onChange={handleSelectAllvideolanguage}
                              />
                            </label>
                          </th>
                          <th>{label_management?.Sidebar_admin[149]?.title}</th>
                          {/* <th>UPLOADED BY</th> */}
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>
                        {" "}
                        <Response_Loading_processing></Response_Loading_processing>{" "}
                        Loading...
                      </label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickvideolanguage}
                          disabled={pagevideolanguage <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagevideolanguage}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickvideolanguage}
                          disabled={
                            datavideolanguage.length < itemsPerPagevideolanguage
                          }
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : videodatavideolanguage.length > 0 ? (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllvideolanguage}
                                onChange={handleSelectAllvideolanguage}
                              />
                            </label>
                          </th>
                          {/* <th>S.NO</th> */}
                          <th>{label_management?.Sidebar_admin[149]?.title}</th>
                          {/* <th>UPLOADED BY</th> */}
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      {currentItemsvideolanguage?.map((item, key) => (
                        <tbody>
                          <tr key={item?.id}>
                            <td key={item?.id}>
                              <input
                                type="checkbox"
                                checked={selectedItemsvideolanguage.includes(
                                  item?.id
                                )}
                                onChange={() =>
                                  handleMultiSelectvideolanguage(item?.id)
                                }
                              />
                            </td>
                            {/* <td className="table-content" key={item?.id}>
                      {key + 1}
                    </td> */}

                            <td>
                              <div className="d-flex text-start align-items-center ">
                                {loading ? (
                                  <img
                                    src={item?.image_url}
                                    className="w-100"
                                    height="100"
                                  />
                                ) : (
                                  <img
                                    src={item?.image_url}
                                    alt="Image"
                                    width="50"
                                  />
                                )}

                                <div>
                                  <div className="ms-2">
                                    <div>{item?.name}</div>
                                    <div className="d-flex ">
                                      <div className="">
                                        <span>{item?.upload_on}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            {/* <td className="">
                              {item?.uploaded_by === "1"
                                ? "Admin"
                                : item?.uploaded_by === "2"
                                ? "Content Partner"
                                : item?.uploaded_by === "3"
                                ? "Channel Partner"
                                : ""}
                            </td> */}

                            <td>
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <i
                                      className="fa fa-ellipsis-v"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                                <div className="editdropdown-menu">
                                  <Link
                                    to={
                                      "/admin-editlanguages/" + item?.id
                                    }
                                    className="edit"
                                  >
                                    <span>
                                      <img
                                        src={edititem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">
                                        Edit {label_management?.Sidebar_admin[149]?.title}
                                      </span>
                                    </span>
                                  </Link>
                                  <Link to="" className="delete">
                                    <span
                                      onClick={() =>
                                        openModalvideolanguage(item?.id)
                                      }
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                      <span className="ms-2">
                                        Delete {label_management?.Sidebar_admin[149]?.title}
                                      </span>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickvideolanguage}
                          disabled={pagevideolanguage <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagevideolanguage}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickvideolanguage}
                          disabled={
                            datavideolanguage.length < itemsPerPagevideolanguage
                          }
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 text-start iq-card-body">
                    <Table>
                      <thead className="p-1">
                        <tr className="title">
                          <th>
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAllvideolanguage}
                                onChange={handleSelectAllvideolanguage}
                              />
                            </label>
                          </th>
                          <th>{label_management?.Sidebar_admin[149]?.title}</th>
                          {/* <th>UPLOADED BY</th> */}
                          <th>ACTION</th>
                        </tr>
                      </thead>
                    </Table>

                    <div className="text-center">
                      <label>No {label_management?.Sidebar_admin[149]?.title} Available.</label>
                    </div>

                    <div className="row align-items-center text-center d-flex">
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={handlePreClickvideolanguage}
                          disabled={pagevideolanguage <= 1}
                        >
                          {"<< Previous"}
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <p className="">{pagevideolanguage}</p>
                      </div>
                      <div className="col-lg-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleNxtClickvideolanguage}
                          disabled={
                            datavideolanguage.length < itemsPerPagevideolanguage
                          }
                        >
                          {"Next >>"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <div className=" m-0 p-0">
        <div className="">
          <section className="section  container-fluid">
            <div className="iq-card mt-4">
              <div className="row mt-5 ">
                <div className="col-md-6">
                  <h4>
                    <i className="entypo-archive"></i> Languages
                  </h4>
                  <small>Organize the Languages below:</small>
                </div>

                <div className="col-md-6" align="right">
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#addlanguage"
                  >
                    <i className="fa fa-plus-circle"></i> Add New
                  </button>
                </div>

                <div
                  className="modal fade"
                  id="addlanguage"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          New Language
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div>
                          <label>Name: </label>

                          <input
                            type="text"
                            name="name"
                            placeholder="Language Name"
                            className="form-control rtmp_urls"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="mt-3">
                          <label>Thumbnail</label>
                          <p>
                            Select The Language Image ( 1:1 Ratio Or 1024 X
                            1024px ) :
                          </p>

                          <div className=" ">
                            <label>Image:</label>
                            <input
                              type="file"
                              multiple="true"
                              className="form-control"
                              name="image"
                              id="image"
                              onChange={(e) =>
                                setLanguageimage(e.target.files[0])
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary ml-3 "
                          onClick={handleSubmit}
                        >
                          Save Changes{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table
                className="table table-bordered iq-card text-center"
                id="categorytbl"
              >
                <tr className="table-header r1">
                  <th>
                    <label>S.No</label>
                  </th>
                  <th>
                    <label>Thumbnail</label>
                  </th>
                  <th>
                    <label>Name</label>
                  </th>
                  <th>
                    <label>Action</label>
                  </th>
                </tr>

                {language?.map((item, key) => (
                  <tr>
                    <td className="table-content" key={item?.id}>
                      {key + 1}
                    </td>

                    <td className="table-content">
                      <img
                        src={item?.image_url}
                        width={150}
                        height={150}
                        className="img-fluid img-thumbnail img-manage"
                        alt="image"
                      />
                    </td>
                    <td className="table-content">
                      <p>{item?.name}</p>
                    </td>
                    <td className="table-content">
                      <Link to={"/admin-editlanguages/" + item?.id}>
                        <i
                          className="fa fa-pencil-square"
                          aria-hidden="true"
                        ></i>
                      </Link>
                      <Link onClick={() => deleteOperation(item?.id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </table>

              <div className="row text-center d-flex">
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={handlePreClick}
                  >
                    {"<< Previous"}
                  </button>
                </div>
                <div className="col-lg-4">
                  <p className="">{page}</p>
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleNxtClick}
                  >
                    {"Next >>"}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> */}
    </>
  );
}
export default VideoLanguage;
