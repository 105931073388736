import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function Videochannelpartner(props) {
  const [page, setPage] = useState(1);
  const [channel, setChannel] = useState([]);
  const [page1, setPage1] = useState(1);

  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/content_partner/videos-pending`,
          {
            headers: headers,
          }
        );
        var result1 = response1?.data?.pending_videos?.data;

        const response2 = await axios.get(
          `${process.env.REACT_APP_Baseurl}/admin/channel_partner/videos-pending`,
          {
            headers: headers,
          }
        );
        var result2 = response2?.data?.pending_videos?.data;
        setChannel(result2);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const rejectedchannel = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/reject-video/` +
        id,
        { headers: headers }
      )
      .then((response) => {

        if (response?.data?.status === true) {
          // Success
          const resultapi = response.data;
          props?.setApiresponsealert(resultapi);
          props?.setShowalert(true);
          window.location.reload();
        } else if (response?.data?.status === false) {
          // API returned a false status
          const resultapifalse = response.data;
          props?.setApiresponsealert(resultapifalse);
          props?.setShowalert(true);
        }
      });
  };


  const approvedchannel = async (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/channel_partner/approve-video/${id}`,
        { headers }
      );

      if (response?.data?.status === true) {
        // Success
        const resultapi = response.data;
        props?.setApiresponsealert(resultapi);
        props?.setShowalert(true);
        window.location.reload();

      } else if (response?.data?.status === false) {
        const resultapifalse = response.data;
        props?.setApiresponsealert(resultapifalse);
        props?.setShowalert(true);
      }
    } catch (error) {
      console.error("Approval failed", error);
    }
  };

  function handlePreClickchannel() {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl
        }/admin/channel_partner/videos-pending?page=${page - 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log(res.data.videos.data);
        // setPrevious1(res?.data?.pending_videos?.data);
        setChannel(res?.data?.pending_videos?.data);
        setPage1(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNxtClickchannel() {
    // console.log('next');

    axios
      .get(
        `${process.env.REACT_APP_Baseurl
        }/admin/channel_partner/videos-pending?page=${page + 1}`,
        { headers: headers }
      )
      .then((res) => {
        // console.log('next', res.data.videos.data);
        // setNext1(res?.data?.pending_videos?.data);
        setChannel(res?.data?.pending_videos?.data);
        setPage1(page + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid">
            <div className="pt-3">
              <div className="row">
                <div className="col-sm-12">
                  <div className="py-3">
                    <h4>Channel Uploaded Videos</h4>
                  </div>

                  <div className="iq-card">
                    <div className="iq-card-header justify-content-between">
                      <div className="row ">
                        <div className="" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            {" "}
                            <div className="iq-card-body p-0">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>TITLES</th>
                                    <th>VIDEO UPLOADED BY</th>
                                    <th>VIDEO TYPE</th>
                                    <th>UPLOADED DATE</th>
                                    <th>VIDEO DURATION</th>
                                    <th>ACTION</th>
                                  </tr>
                                </thead>
                                {channel?.map((item, key) => (
                                  <tbody>
                                    <tr>
                                      <td>{item?.title}</td>
                                      <td>
                                        {item?.uploaded_by === "1"
                                          ? "Admin"
                                          : item?.uploaded_by === "4"
                                            ? "Channel Partner"
                                            : item?.uploaded_by === "5"
                                              ? "Content Partner"
                                              : ""}
                                      </td>
                                      <td>{item?.type}</td>
                                      <td>{item?.created_at}</td>
                                      <td>{item?.duration}</td>
                                      <td colspan="2">
                                        <div className="flex align-items-center list-user-action fs-5">
                                          <Link
                                            className="iq-bg-warning"
                                            to=""
                                            onClick={() =>
                                              approvedchannel(item?.id)
                                            }
                                          >
                                            <i className="fa fa-check-circle"></i>
                                          </Link>
                                          <Link
                                            className="iq-bg-success ms-2"
                                            to=""
                                            onClick={() =>
                                              rejectedchannel(item?.id)
                                            }
                                          >
                                            <i className="fa fa-close"></i>
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </Table>

                              <div className="row align-items-center text-center d-flex">
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={handlePreClickchannel}
                                  >
                                    {"<< Previous"}
                                  </button>
                                </div>
                                <div className="col-lg-4">
                                  <p className="">{page1}</p>
                                </div>
                                <div className="col-lg-4">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleNxtClickchannel}
                                  >
                                    {"Next >>"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Videochannelpartner;
