import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import './Main_labels.css'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Response_Loading_processing from "../../../../../src/layout/Others/Response_Loading_processing";
import backarrow from "../../../../components/Images/backarrow.svg";
import { FaSearch } from "react-icons/fa";
import './Labels.css'

function Sidebar_label(props) {

    const [getVideolists, setVideolists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // New state for search term

    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    useEffect(() => {
        const fetchDatavideo = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl}/admin/Label-Management/list-depends-source`,
                    { headers: headers }
                );
                setVideolists(response?.data?.label_management?.Sidebar_admin);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchDatavideo();
    }, []);

    async function handleUpdatevideo(updatedItem) {
        const formData = new FormData();

        formData.append("default_name", updatedItem?.default_name);
        formData.append("title", updatedItem?.title);
        formData.append("sub_title", updatedItem?.sub_title);
        formData.append("source", "Sidebar_admin");

        let firstApiStatus = false;
        let resultapi = null;
        setProcessing(true);
        setShowOverlay(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/admin/Label-Management/update/${updatedItem?.id}`,
                formData,
                { headers: headers }
            );

            if (response.data.status === true) {
                firstApiStatus = true;

                resultapi = response.data;

                // Update the local state with the updated data
                const updatedList = [...getVideolists];
                const itemIndex = updatedList.findIndex((item) => item.id === updatedItem.id);

                if (itemIndex !== -1) {
                    updatedList[itemIndex] = { ...updatedList[itemIndex], ...updatedItem };
                    setVideolists(updatedList);
                }
            }
        } catch (error) { }

        if (firstApiStatus) {
            props.setApiresponsealert(resultapi);
            props.setShowalert(true);
        } else {
            if (resultapi && resultapi.status === false) {
                var resultError = resultapi;
                props.setApiresponsealert(resultError);
                props.setShowalert(false);
                setProcessing(false);
                setShowOverlay(false);
            }
        }
    }

    // Filter the video lists based on searchTerm
    const filteredVideoLists = getVideolists?.filter((item) =>
        item?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    return (
        <>
            <Link to="/labels" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
            </Link>

            <section className="section Label_settings container-fluid pt-3">
                <div className="row">
                    <div className="col-sm-12">

                        <div className="iq-card-body table-responsive iq-card">
                            <div className="col-lg-12 row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <h4>Sidebar Lists</h4></div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-end">
                                    {/* Search Input */}
                                    <div className="row mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by Text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    <div className="searchPageIconFaSearchtranssearchPageIconFaSearchtrans">
                                        <FaSearch
                                            className="searchPageIconFaSearchtranssearchPageIconFaSearchtrans p-1 fs-5"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <section className="section container-fluid">
                                <div id="content-page" className="content-page">
                                    <div className="mt-3">
                                        <div className="bootstrapTable ">
                                            <div className="col-12 p-0">
                                                <div className="row m-0 p-0">
                                                    <div className="col-lg-6 col-sm-6 col-6 data">
                                                        {" "}
                                                        <div>Text</div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 data text-center">
                                                        {" "}
                                                        <div>Actions</div>
                                                    </div>
                                                </div>



                                                <DragDropContext>
                                                    <Droppable droppableId="menu-items">
                                                        {(provided) => (
                                                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                                                {loading ? (
                                                                    <div className="noRecord">
                                                                        {" "}
                                                                        <Response_Loading_processing></Response_Loading_processing>
                                                                        Loading...
                                                                    </div>
                                                                ) : filteredVideoLists?.length <= 0 ? (  // Use filtered list for rendering
                                                                    <div className="noRecord">
                                                                        {" "}
                                                                        <p>No Sidebar Available !!! </p>
                                                                    </div>
                                                                ) : (
                                                                    filteredVideoLists?.map((item, index) => (  // Use filtered list for rendering
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id.toString()}
                                                                            index={index}
                                                                        >
                                                                            {(provided) => (
                                                                                <li
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className={`bg-white orderborder ${item?.order
                                                                                        } index ${index + 1} w-100`}
                                                                                >
                                                                                    <div className="row m-0 p-0 align-items-center">

                                                                                        <div className="col-lg-6 col-sm-6 col-6 data_th">
                                                                                            <div className="">
                                                                                                <div className="">
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="rs-input form-control-lg"
                                                                                                        value={item?.title}
                                                                                                        onChange={(e) => {
                                                                                                            const newName = e.target.value;
                                                                                                            const updatedList = [...getVideolists];
                                                                                                            updatedList[index].title = newName;
                                                                                                            setVideolists(updatedList);
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-sm-6 col-6 data_th text-center">
                                                                                            <div className="justify-content-between align-items-center">
                                                                                                <button className="btn btn-primary" onClick={() => handleUpdatevideo(item)}>Update</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))
                                                                )}
                                                                {provided.placeholder}
                                                            </ul>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Sidebar_label;
