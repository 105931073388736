import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";

    function ChannelPartnerSignupMenu(props) {
        
        const [editSignupMenu, seteditChannelPartnerSignupMenu] = useState({
            username: '',email: '',mobile: '',avatar: '',best_content: '',password: '',password_confirm: '',
        });

        const access_token = localStorage.getItem('access_token');

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        };

        useEffect(async ()=> {
            const getChannelSignupMenu = async () => {
                const reqData = await fetch(
                    `${process.env.REACT_APP_Baseurl}/admin/channel-partner-signup_menu`,
                    { headers: headers }
                );
                const resData = await reqData?.json();
                var res = resData?.signup_menu;
                seteditChannelPartnerSignupMenu(res);
            };
            getChannelSignupMenu();
        },[])

        const handleInput = (e) =>{
            if(e.target.checked === true){
                seteditChannelPartnerSignupMenu({ ...editSignupMenu, [e.target.name]: 1 });
            }else{
                seteditChannelPartnerSignupMenu({ ...editSignupMenu, [e.target.name]: 0 });
            }
        }; 

        const [processing, setProcessing] = useState(false);
        const [showOverlay, setShowOverlay] = useState(false);
        
        const handleUpdate = async (e) =>{
            e.preventDefault();

            const editInputvalue = {
                user_id:editSignupMenu?.user_id,username:editSignupMenu?.username,email:editSignupMenu?.email,
                mobile:editSignupMenu?.mobile,avatar:editSignupMenu?.avatar,best_content:editSignupMenu?.best_content,
                password:editSignupMenu?.password,password_confirm:editSignupMenu?.password_confirm,
            };

            setProcessing(true);
            setShowOverlay(true);

            let responseUpdate = await fetch(
                `${process.env.REACT_APP_Baseurl}/admin/channel-partner-signup-menu-store`,
                {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(editInputvalue),
                  }
            );

            let resjson = await responseUpdate.json();

            if(resjson?.status === true){
                var resultapi = resjson;
                props?.setApiresponsealert(resultapi);
                props?.setShowalert(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }else if (resjson?.status === false) {
                var resultError = resjson;
                props?.setApiresponsealert(resultError);
                props?.setShowalert(true);
                setProcessing(false);
                setShowOverlay(false);
            }
        };
          

        return (
            <>
                <div className=" m-0 p-0">
                    <div className="">
                        <section className="section container-fluid pt-3">
                            <div className="col-lg-12 row py-3">
                                <div className="col-6 col-sm-6 col-lg-6">
                                    <h3>Channel Partner SignupMenu</h3>
                                </div>
                                <div className="col-6 col-sm-6 col-lg-6 text-end">
                                    <button onClick={handleUpdate} className="btn btn-primary">
                                        Submit
                                    </button>
                                </div>    
                            </div>

                            <div className="iq-card">
                                <div>
                                    {showOverlay && (<Response_Processing></Response_Processing>)}
                                </div>

                                <div className="col-lg-12">
                                    <div className="">
                                        <Table>
                                            <thead>
                                                <tr className="title">
                                                    <th>S.No</th>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>User Name</td>
                                                    <td>
                                                        <div className="mt-1">
                                                            <label className="switch">
                                                                <input name="username" onChange={handleInput} 
                                                                    defaultChecked={ editSignupMenu?.username === 1 ? true : false }
                                                                    checked={ editSignupMenu?.username ===1 ? true : false }
                                                                    type="checkbox"
                                                                />
                                                                <span className="slider round" name="username" value={editSignupMenu?.username === 1 ? "1" : "0"}></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Email</td>
                                                    <td>
                                                        <div className="mt-1">
                                                            <label className="switch">
                                                                <input name="email" onChange={handleInput} 
                                                                    defaultChecked={ editSignupMenu?.email === 1 ? true : false }
                                                                    checked={ editSignupMenu?.email ===1 ? true : false }
                                                                    type="checkbox"
                                                                />
                                                                <span className="slider round" name="email" value={editSignupMenu?.email === 1 ? "1" : "0"}></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Mobile Number</td>
                                                    <td>
                                                        <div className="mt-1">
                                                            <label className="switch">
                                                                <input name="mobile" onChange={handleInput} 
                                                                    defaultChecked={ editSignupMenu?.mobile === 1 ? true : false }
                                                                    checked={ editSignupMenu?.mobile ===1 ? true : false }
                                                                    type="checkbox"
                                                                />
                                                                <span className="slider round" name="mobile" value={editSignupMenu?.mobile === 1 ? "1" : "0"}></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Avatar</td>
                                                    <td>
                                                        <div className="mt-1">
                                                            <label className="switch">
                                                                <input name="avatar" onChange={handleInput} 
                                                                    defaultChecked={ editSignupMenu?.avatar === 1 ? true : false }
                                                                    checked={ editSignupMenu?.avatar ===1 ? true : false }
                                                                    type="checkbox"
                                                                />
                                                                <span className="slider round" name="avatar" value={editSignupMenu?.avatar === 1 ? "1" : "0"}></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Best Content</td>
                                                    <td>
                                                        <div className="mt-1">
                                                            <label className="switch">
                                                                <input name="best_content" onChange={handleInput} 
                                                                    defaultChecked={ editSignupMenu?.best_content === 1 ? true : false }
                                                                    checked={ editSignupMenu?.best_content ===1 ? true : false }
                                                                    type="checkbox"
                                                                />
                                                                <span className="slider round" name="best_content" value={editSignupMenu?.best_content === 1 ? "1" : "0"}></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Password</td>
                                                    <td>
                                                        <div className="mt-1">
                                                            <label className="switch">
                                                                <input name="password" onChange={handleInput} 
                                                                    defaultChecked={ editSignupMenu?.password === 1 ? true : false }
                                                                    checked={ editSignupMenu?.password ===1 ? true : false }
                                                                    type="checkbox"
                                                                />
                                                                <span className="slider round" name="password" value={editSignupMenu?.password === 1 ? "1" : "0"}></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Password Confirm</td>
                                                    <td>
                                                        <div className="mt-1">
                                                            <label className="switch">
                                                                <input name="password_confirm" onChange={handleInput} 
                                                                    defaultChecked={ editSignupMenu?.password_confirm === 1 ? true : false }
                                                                    checked={ editSignupMenu?.password_confirm ===1 ? true : false }
                                                                    type="checkbox"
                                                                />
                                                                <span className="slider round" name="password_confirm" value={editSignupMenu?.password_confirm === 1 ? "1" : "0"}></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        );

    }   

export default ChannelPartnerSignupMenu;
