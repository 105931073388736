import React, { useState } from 'react';
import { IconCopy } from '../../Assets/MyIcons';
import MessageLabel from './MessageLabel';

const AdminCustomURLCopy = ({ link, fieldName, className, labelClassName }) => {
    const [isCopied, setIsCopied] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            })
            .catch((error) => {
                console.error('Failed to copy text: ', error);
            });
    };

    return (
        <div>
            <MessageLabel text={fieldName} className={labelClassName} />
            <div class="position-relative ">
                {isCopied && <span className='position-absolute copy-reply theme-button-text-color button-bg-color px-2 py-1 rounded-2'>{'Copied!'}</span>}
                <input type="text" className={`custom-input w-100 custom-placeholder  theme-text-color  mb-3 ${className}`} value={link} readOnly />
                <button onClick={copyToClipboard} className=' border-0 copy-board-container bg-transparent'>
                    <IconCopy styled={{ width: "20px", height: "20px", className: "" }} />
                </button>
            </div>
        </div>
    );
};

export default AdminCustomURLCopy;
