import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { Modal, Form, Table } from 'react-bootstrap';
import { toast } from "react-toastify"
import Button from "@mui/material/Button";
import { TagsInput } from "react-tag-input-component";
import "./Addnewseries.css";
import { useNavigate } from "react-router-dom";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";
import NavigationData from "../../../layout/Sidebar/NavigationData";


function Addnewseries(props) {
  const { label_management } = NavigationData();
  const [type, setUrl_type] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [featured, setFeatured] = useState("");
  const [datanewppvpprice, setDatanewppvpprice] = useState();
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [embed_code, setEmbed_code] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [trailer, setTrailer] = useState("");
  const [banner, setBanner] = useState("");
  const [deleted_at, setDeleted_at] = useState("");
  const [series_trailer, setSeries_trailer] = useState("");
  const [season_trailer, setSeason_trailer] = useState("");
  const [status, setStatus] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [url, setUrl] = useState("");

  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");

  const [Thumbnail, setThumbnail] = useState("");
  const [Player_thumbnail, setPlayer_thumbnail] = useState("");
  const [TV_Thumbnail, setTV_Thumbnail] = useState("");
  const [series_id, setSeries_id] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");

  // const [publish_type, setPublish_type] = useState("");
  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const contentFieldChanaged = (data) => {
    setDetails(data);
  };


  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [userAccessesValue, setUserAccessesValue] = useState([]);
  const handleAccessChange = (e) => {
    setUserAccessesValue(e);
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
  };

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    duration: "",
    year: "",
    ppv_price: "",
    source_type: "",
    PPVType: "",
    global_ppv: "",
    ios_ppv_price: "",
  });

  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  const [checkboxCheckedseason, setcheckboxCheckedseason] = useState(false);
  const [inputValueseason, setInputValueseason] = useState("");
  const [inputValueAPIseason, setInputValueAPIseason] = useState("");

  const handleCheckboxChangeseason = () => {
    setcheckboxCheckedseason(!checkboxCheckedseason);
    if (!checkboxCheckedseason) {
      setInputValueseason(inputValueAPIseason);
    } else {
      setInputValueseason("");
    }
  };

  const handleInputChangeseason = (event) => {
    setInputValueseason(event.target.value);
  };

  const handleTagsChange = (newTags) => {
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) === index);
    setSearchTags(cleanedTags);
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue === "publish_later") {
      setPublishTime("");
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
  };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };


  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    const formattedInput = inputValue.replace(/\D/g, "");

    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    setActive({ [e.target.name]: e.target.value });

    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    // setActive({ ...active, [e.target.name]: e.target.value });
    setSeason_trailer({ ...season_trailer, [e.target.name]: e.target.value });
    setSeries_trailer({ ...series_trailer, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setTrailer({ ...trailer, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 1 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setTrailer({ ...trailer, [e.target.name]: 1 });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setSeason_trailer({ ...season_trailer, [e.target.name]: 0 });
      setSeries_trailer({ ...series_trailer, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setTrailer({ ...trailer, [e.target.name]: 0 });
    }
  };

  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };

  const handleFileChange = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.series === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };

  const handleDelete = (index) => {

    setError('');
    switch (index) {
      case 1:
        setSelectedFile1(null);
        if (fileInputRef1.current) {
          fileInputRef1.current.value = null;
        }
        break;
      case 2:
        setSelectedFile2(null);
        if (fileInputRef2.current) {
          fileInputRef2.current.value = null;
        }
        break;
      case 3:
        setSelectedFile3(null);
        if (fileInputRef3.current) {
          fileInputRef3.current.value = null;
        }
        break;
      default:
        break;
    }
  };


  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive


  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  // const [audio_albums, setAudio_albums] = useState([]);
  const [selectedOptionsaudio_albums, setSelectedOptionsaudio_albums] =
    useState([]);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/series/create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setLanguage(response?.data?.languages);
      setArtists(response?.data?.artists);
      setUser_access(response?.data?.user_access);
      setInappPurchase(response?.data?.InappPurchase);
      // setLivestream_source(response?.data?.Livestream_source);
      setCategory(response?.data?.series_categories);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPIseason(globelset);



      var Defaultppv_gobal_price = response?.data?.ppv_gobal_price;
      setppv_gobal_price(Defaultppv_gobal_price)
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(async () => {
    fetchOptions();
  }, []);

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangeaudio_albums = (selectedValues) => {
    setSelectedOptionsaudio_albums(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);
    setSelectedOptionscategory(selectedValues);
  };

  const handleAccess = (data) => {
    setStoreliveuseraccess(data)
    setValidationMessageAccess("");
    setIsValidationHiddenAccess(true);
  }

  // Multiselect

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);
  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const accessInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function series() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (selectedOptionscategory?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    // if (selectedOptionslanguage?.length === 0) {
    //   setValidationMessagelanguage("Please select at least one Language.");
    //   setIsValidationHiddenlanguage(false);
    //   focusInputRef = languageInputRef;
    // }
    if (!storeliveuseraccess) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }

    let valid = true;

    if (compressResolutionFormat?.series === 1) {
      if (!selectedFile1) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile2) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile3) {
        setError(prevErrors => ({ ...prevErrors, file3: 'Video TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }
    }

    if (!valid) {
      return;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputvalueseason_trailer = season_trailer?.season_trailer;
    var dataseason = JSON.stringify(editInputvalueseason_trailer);
    var season_trailerdata = dataseason;

    const editInputvalueviews = views?.views;
    var dataviews = JSON.stringify(editInputvalueviews);
    var viewsdata = dataviews;

    const editInputvalueseries_trailer = series_trailer?.series_trailer;
    var dataseries = JSON.stringify(editInputvalueseries_trailer);
    var series_trailerdata = dataseries;

    // const editInputvalueppvstatus = ppvstatus.ppvstatus;
    // var data = JSON.stringify(editInputvalueppvstatus);
    // var ppvstatusdata = data;

    const formData = new FormData();

    const formDatatest = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

    // if (!formDatatest || formDatatest?.convertedPrices.length === 0) {
    // if (!formDatatest || formDatatest?.convertedPrices.length) {
    //   return;
    // }

    formData.append("title", editUser?.title);
    formData.append("genre_id", 1);
    formData.append("mp4_url", mp4_url);
    formData.append("slug", autoFillValuetitle);
    formData.append("type", type);
    formData.append("access", storeliveuseraccess);
    formData.append("details", details);
    formData.append("description", description);

    const aactivedata2 = Number.isNaN(parseInt(aactivedata, 10)) ? 0 : parseInt(aactivedata, 10);
    formData.append("active", aactivedata2 ? parseInt(aactivedata2, 10) : 0);

    const enableppv_status = Number.isNaN(parseInt(inputValueseason, 10)) ? 0 : parseInt(inputValueseason, 10);
    formData.append("ppv_status", enableppv_status);
    formData.append("global_ppv", modalTypemodalType);

    // formData.append("ppv_status", ppv_status ? parseInt(ppv_status, 10) : 0);
    formData.append("status", status ? parseInt(status, 10) : 0);
    formData.append("draft", 1);
    formData.append("featured", featureddata ? parseInt(featureddata, 10) : 0);
    formData.append("duration", editUser?.duration);
    formData.append("views", viewsdata ? parseInt(viewsdata, 10) : 0);
    formData.append("rating", rating ? parseInt(rating, 10) : 0);
    formData.append("search_tag", searchTags);
    // formData.append("ppv_price", enableppv_status);

    if (global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0) {
      formData.append("ppv_price", inputValueseason);
    } else {
      formData.append("ppv_price", datanewppvpprice?.price);
    }

    formData.append("ios_ppv_price", editUser?.ios_ppv_price);
    formData.append("global_ppv", checkboxCheckedseason === true ? 1 : 0 || editUser?.global_ppv);

    formData.append(
      "artists",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option?.value))
    );
    formData.append(
      "genre_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formData.append("embed_code", embed_code);
    formData.append("mp4_url", mp4_url);
    formData.append("webm_url", webm_url);
    formData.append("ogg_url", ogg_url);
    formData.append("year", editUser?.year);
    formData.append("trailer", trailer);
    formData.append("banner", bannerdata ? parseInt(bannerdata, 10) : 0);
    formData.append("series_trailer", series_trailerdata ? parseInt(series_trailerdata, 10) : 0);
    formData.append("season_trailer", season_trailerdata ? parseInt(season_trailerdata, 10) : 0);
    formData.append("uploaded_by", uploaded_by);
    formData.append("url", url);
    formData.append("deleted_at", deleted_at);
    formData.append("Thumbnail", Thumbnail);
    formData.append("Player_thumbnail", Player_thumbnail);
    formData.append("TV_Thumbnail", TV_Thumbnail);

    formData.append("ads_position", selectedAdsPosition?.value);
    formData.append("live_ads", secondSelectOptions?.value);

    formData.append("source_type", 3);
    // formData.append("source_id", series_id);
    formData.append("PPVType", modalTypemodalType);
    formData.append("PriceType", formDatatest?.priceType || '');
    formData.append("IOSPrice", editUser?.ios_ppv_price || '');
    formData.append("ActualPrice", formDatatest?.price || '');

    // Serialize convertedPriceRates into a JSON string
    // Format and serialize convertedPriceRates
    if (formDatatest?.convertedPrices && formDatatest?.convertedPrices.length > 0) {
      formDatatest?.convertedPrices?.forEach((item, index) => {
        // Append each property with its corresponding value
        formData.append(`convertedPriceRates[${index}][Code]`, item?.Code || '');
        formData.append(`convertedPriceRates[${index}][Country]`, item?.country_name || item?.Country || '');
        formData.append(`convertedPriceRates[${index}][RateDifference]`, item?.difference || item?.RateDifference || '');
        formData.append(`convertedPriceRates[${index}][ConvertedPriceRate]`, item?.editPrice || item?.convertedPrice || '');
        formData.append(`convertedPriceRates[${index}][ChangedPrice]`, item?.editPrice || item?.convertedPrice || '');
        formData.append(`convertedPriceRates[${index}][Symbol]`, item?.Symbol || item?.symbol);
      });
    } else {
      formData.append('convertedPriceRates', JSON.stringify([]));
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/series/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;
        var series_id = response?.data?.Series_id;
        setSeries_id(series_id);
        const imageFormData = new FormData();
        imageFormData.append("series_id", series_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("tv_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("series_id", series_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/series/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        navigate(`/edit-series/${series_id}`);
      }, 3000);
    } else {
      if (resultapi === false) {
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  // async function series() {
  //   if (editUser?.title === "") {
  //     setValidationMessagetitle("Title cannot be empty.");
  //     setIsValidationHiddentitle(false);
  //     return;
  //   }

  //   if (autoFillValuetitle === "") {
  //     setValidationMessageslug("slug cannot be empty.");
  //     setIsValidationHiddenslug(false);
  //     return;
  //   }

  //   if (selectedOptionscategory.length === 0) {
  //     setValidationMessagecategory("Please select at least one Category.");
  //     setIsValidationHiddencategory(false);
  //     return;
  //   }

  //   if (selectedOptionslanguage.length === 0) {
  //     setValidationMessagelanguage("Please select at least one Language.");
  //     setIsValidationHiddenlanguage(false);
  //     return;
  //   }

  //   const editInputfeatured = featured.featured;
  //   var datafeatured = JSON.stringify(editInputfeatured);
  //   var featureddata = datafeatured;

  //   const editInputbanner = banner.banner;
  //   var databanner = JSON.stringify(editInputbanner);
  //   var bannerdata = databanner;

  //   const editInputvalue = active.active;
  //   var data = JSON.stringify(editInputvalue);
  //   var aactivedata = data;

  //   const editInputvalueseason_trailer = season_trailer.season_trailer;
  //   var data = JSON.stringify(editInputvalueseason_trailer);
  //   var season_trailerdata = data;

  //   const editInputvalueviews = views.views;
  //   var data = JSON.stringify(editInputvalueviews);
  //   var viewsdata = data;

  //   const editInputvalueseries_trailer = series_trailer.series_trailer;
  //   var data = JSON.stringify(editInputvalueseries_trailer);
  //   var series_trailerdata = data;

  //   // const editInputvalueppvstatus = ppvstatus.ppvstatus;
  //   // var data = JSON.stringify(editInputvalueppvstatus);
  //   // var ppvstatusdata = data;

  //   const formData = new FormData();

  //   formData.append("title", editUser?.title);
  //   formData.append("genre_id", genre_id);
  //   formData.append("mp4_url", mp4_url);
  //   formData.append("slug", autoFillValuetitle);
  //   formData.append("type", type);
  //   formData.append("access", access);
  //   formData.append("details", details);
  //   formData.append("description", description);
  //   formData.append("active", aactivedata);
  //   formData.append("ppv_status", ppv_status);
  //   formData.append("status", status);
  //   formData.append("featured", featureddata);
  //   formData.append("duration", duration);
  //   formData.append("views", viewsdata);
  //   formData.append("rating", rating);
  //   formData.append("search_tag", search_tags);
  //   formData.append("ppv_price", inputValue);
  //   formData.append("ios_ppv_price", ios_ppv_price?.value);

  //   formData.append(
  //     "artists",
  //     JSON.stringify(selectedOptionsartists?.map((option) => option.value))
  //   );
  //   formData.append(
  //     "block_country",
  //     JSON.stringify(selectedOptions?.map((option) => option.value))
  //   );
  //   formData.append(
  //     "category",
  //     JSON.stringify(selectedOptionscategory?.map((option) => option.value))
  //   );
  //   formData.append(
  //     "languages_id",
  //     JSON.stringify(selectedOptionslanguage?.map((option) => option.value))
  //   );

  //   formData.append("embed_code", embed_code);
  //   formData.append("mp4_url", mp4_url);
  //   formData.append("webm_url", webm_url);
  //   formData.append("ogg_url", ogg_url);
  //   // formData.append("language", language);
  //   formData.append("year", year);
  //   formData.append("trailer", trailer);
  //   formData.append("banner", bannerdata);
  //   formData.append("series_trailer", series_trailerdata);
  //   formData.append("season_trailer", season_trailerdata);
  //   formData.append("uploaded_by", uploaded_by);
  //   formData.append("url", url);
  //   formData.append("deleted_at", deleted_at);
  //   formData.append("Thumbnail", Thumbnail);
  //   formData.append("Player_thumbnail", Player_thumbnail);
  //   formData.append("TV_Thumbnail", TV_Thumbnail);

  //   formData.append("ads_position", selectedAdsPosition?.value);
  //   formData.append("live_ads", secondSelectOptions?.value);

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + access_token,
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   await axios
  //     .post(`${process.env.REACT_APP_Baseurl}/admin/series/store`, formData, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       // console.log("api", response);
  //       if (response.data.status === true) {
  //         var result = response.data;
  //         // console.log("result", result);
  //         // navigate('/series-list')
  //         // alert("Added successfully  !");

  //         var resultapi = response.data;
  //         var series_id = response.data.Series_id;

  //         setSeries_id(series_id);

  //         const formData = new FormData();
  //         formData.append("series_id", series_id);
  //         if (selectedFile1) {
  //           formData.append("image", selectedFile1);
  //         }
  //         if (selectedFile2) {
  //           formData.append("player_image", selectedFile2);
  //         }
  //         if (selectedFile3) {
  //           formData.append("TV_image", selectedFile3);
  //         }

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/Image-upload-series`,
  //             formData,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             console.log("File(s) uploaded successfully:", response.data);
  //             // Perform any actions with the response data as needed.
  //             //       // navigate('/all-artist')
  //             // navigate("/all-artist");
  //             // alert("Added successfully  !");
  //           })
  //           .catch((error) => {
  //             console.error("Error uploading file(s):", error);
  //             // Handle any errors that occurred during the upload process.
  //           });

  //         const formDataseo = new FormData();
  //         formDataseo.append("series_id", series_id);
  //         formDataseo.append("website_page_title", websitetitle);
  //         formDataseo.append("website_URL", websiteurl);
  //         formDataseo.append("Meta_description", metadescription);

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_Baseurl}/admin/series/SEO`,
  //             formDataseo,
  //             { headers: headers }
  //           )
  //           .then((response) => {
  //             console.log("File(s) uploaded successfully:", response.data);
  //           });

  //         setValidationMessagetitle("");
  //         setValidationMessageslug("");
  //         setValidationMessagecategory("");
  //         setValidationMessagelanguage("");

  //         props.setApiresponsealert(resultapi);
  //         props.setShowalert(true);
  //         setTimeout(() => {
  //           // window.location.reload();
  //           navigate(`/edit-series/${series_id}`);
  //         }, 1000);

  //         // window.location.reload()
  //         // navigate(`/edit-series/${series_id}`);
  //         // alert("Added successfully  !");
  //       } else {
  //         if (response.data.status === false) {
  //           // setMessage("Some error Occured");
  //           // console.log("Error");
  //           // alert("Enter Correct Details");
  //           var resultError = response.data;
  //           props.setApiresponsealert(resultError);
  //           props.setShowalert(true);

  //           setIsValidationHiddentitle(false);
  //           setValidationMessageslug(false);
  //           setIsValidationHiddencategory(false);
  //           setIsValidationHiddenlanguage(false);
  //         }
  //       }
  //     });
  // }

  // const [access, access] = useState('');

  const headers = {
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/series/create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();
  }, []);

  useEffect(async () => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/admin/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");
          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  // const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
  //   useState("");

  // function proceednextpublic() {
  //   switch (enable_restreampublic) {
  //     case "publish_now":
  //       return <div></div>;
  //     case "publish_later":
  //       return (
  //         <div className="mb-3">
  //           <label className="m-0">Publish Time</label>
  //           <input
  //             type="datetime-local"
  //             className="rs-input mt-2  form-control-lg"
  //             id="publish_time"
  //             name="publish_time"
  //             onChange={(e) => setPublish_time(e.target.value)}
  //           />
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // }






  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModalshowModal, setShowModalshowModal] = useState(false);
  const [modalTypemodalType, setModalTypemodalType] = useState('');
  const [stepstep, setStepstep] = useState(1);
  const [formDataglobalpriceformDataglobalprice, setFormDataglobalpriceformDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustompriceformDatacustomprice, setFormDatacustompriceformDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChangehandleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalTypemodalType(e.target.value);
    setShowModalshowModal(true);
    setStepstep(1);
  };

  const handleClosehandleClose = () => {
    setShowModalshowModal(false);
    setFormDataglobalpriceformDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustompriceformDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleClosehandleClosehandleClosehandleClose = () => {
    setShowModalshowModal(false);
  };

  const handleNexthandleNext = async () => {
    if (stepstep === 1) {
      const formData = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalTypemodalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustompriceformDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalTypemodalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustompriceformDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalTypemodalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalpriceformDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStepstep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmithandleSubmit = async (series_id) => {
    const formData = modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice : formDatacustompriceformDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 3, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: series_id,
      PPVType: modalTypemodalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Episode/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClosehandleClose();
      setStepstep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditablehandleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.convertedPrices : formDatacustompriceformDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalTypemodalType === '1') {
      setFormDataglobalpriceformDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustompriceformDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChangehandleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustompriceformDatacustomprice.price,
            difference: ((formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustompriceformDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustompriceformDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustompriceformDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustompriceformDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustompriceformDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };


  const handlePriceChangehandlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalpriceformDataglobalprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    } else if (formType === 'custom') {
      setFormDatacustompriceformDatacustomprice(prevState => {
        const updatedState = { ...prevState, [name]: value };
        setDatanewppvpprice(updatedState)
        return updatedState;
      });
    }
  };

  // {ppv price with global and customs} 
  // {ppv price with global and customs} 



  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (
              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">{label_management?.series[42]?.title}</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">{label_management?.series[42]?.sub_title}</p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxCheckedseason}
                          onChange={handleCheckboxChangeseason}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxCheckedseason}
                          onChange={handleCheckboxChangeseason}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      name="ppv_price"
                      id="price"
                      value={inputValueseason}
                      onChange={handleInputChangeseason}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0">{label_management?.series[39]?.title}</label>
                  <p className="p1">{label_management?.series[39]?.sub_title}</p>
                  <div className="panel-body ppv_price_ios  mt-2">
                    <select
                      // onChange={(e) => setIos_ppv_price(e.target.value)}
                      onChange={handleInput}
                      value={editUser?.ios_ppv_price}
                      className="form-select"
                      id="ios_ppv_price"
                      name="ios_ppv_price"
                    >
                      <option value="">Choose a PPV Price</option>
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ) :
              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label={label_management?.series[34]?.title}
                            name="priceOption"
                            value="1"
                            checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                            // onChange={handleChange}
                            onClick={() => handleChangehandleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label={label_management?.series[41]?.title}
                        name="priceOption"
                        value="2"
                        checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                        // onChange={handleChange}
                        onClick={() => handleChangehandleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModalshowModal} onHide={handleClosehandleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalTypemodalType === '1' ? `${label_management?.series[36]?.title}` : `${label_management?.series[40]?.title}`}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {stepstep === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalTypemodalType === '1' ? `${label_management?.series[37]?.title}` : `${label_management?.series[41]?.title}`}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.price : formDatacustompriceformDatacustomprice.price}
                                onChange={(e) => handlePriceChangehandlePriceChange(e, modalTypemodalType === '1' ? 'global' : 'custom')}
                                disabled={modalTypemodalType === '1'}
                              />
                            </Form.Group>
                            {modalTypemodalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series[38]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustompriceformDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChangehandleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalTypemodalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.series[38]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustompriceformDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChangehandleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
                              <Button variant="primary" onClick={handleNexthandleNext}>
Next
</Button>
</div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0">{label_management?.series[39]?.title}</label>
                              <p className="p1">{label_management?.series[39]?.sub_title}</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInput}
                                  value={editUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option value={item?.product_id}>{item?.plan_price}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalTypemodalType === '1' ? formDataglobalpriceformDataglobalprice.convertedPrices : formDatacustompriceformDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditablehandleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalTypemodalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
<Button variant="secondary" onClick={() => setStepstep(1)}>
Previous
</Button>
<Button variant="primary" onClick={handleSubmithandleSubmit}>
Submit
</Button>
</div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {stepstep === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNexthandleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStepstep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleClosehandleClosehandleClosehandleClose} className='ms-2'>
                            Processed
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>{label_management?.series[0]?.title}</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={series} className="btn btn-primary">
                    {label_management?.series[33]?.title}
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{label_management?.series[2]?.title}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      {label_management?.series[3]?.title}<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      {label_management?.series[3]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        value={editUser?.title}
                        onBlur={handleBlurtitle}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.series[4]?.title}</label>
                    <p className="t-1">{label_management?.series[4]?.sub_title}</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">

                    <label className="m-0">{label_management?.series[5]?.title}</label>
                    <p className="t-1">{label_management?.series[5]?.sub_title}</p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">

                      <label className="m-0">{label_management?.series[6]?.title}</label>
                      <p className="t-1">{label_management?.series[6]?.sub_title}</p>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={details}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">

                      <label className="m-0">{label_management?.series[7]?.title}</label>
                      <p className="t-1">{label_management?.series[7]?.sub_title}</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          maxLength="8"
                          className="rs-input form-control-lg"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                        // placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.series[8]?.title}</label>
                      <p className="t-1">{label_management?.series[8]?.sub_title}</p>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={editUser?.year || ''}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>{label_management?.series[9]?.title}</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{label_management?.series[10]?.title}</label>
                      <p className="t-1">{label_management?.series[10]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className=" mt-2 text-start">
                      <label className="m-0">Age Restrict :</label>
                      <p className="t-1">Select A Series Age Below:</p>
                      <div className="mt-2">
                        <select
                          className="rs-input form-control-lg "
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={(e) => setAge_restrict(e.target.value)}
                        >
                          {age_restrictdata.map((item) => (
                            <option selected value={item.id}>
                              {item.slug}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.series[11]?.title}</label>
                      <p className="t-1">{label_management?.series[11]?.sub_title}</p>

                      <div className="mt-2">
                        <Select
                          options={artists?.map((option) => ({
                            value: option?.id,
                            label: option?.artist_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsartists}
                          onChange={handleSelectChangeartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">

                      <label className="m-0">{label_management?.series[12]?.title}<span className="mandatory">*</span></label>
                      <p className="t-1">{label_management?.series[12]?.sub_title}</p>

                      <div className="mt-2">
                        <Select
                          options={category?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionscategory}
                          onChange={handleSelectChangecategory}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">

                      <label className="m-0">{label_management?.series[13]?.title}</label>
                      <p className="t-1">{label_management?.series[13]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={language?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionslanguage}
                          onChange={handleSelectChangelanguage}
                        // ref={languageInputRef}
                        />
                      </div>
                      {/* <span className="errorred">
                        {!isValidationHiddenlanguage && (
                          <p>{validationMessagelanguage}</p>
                        )}
                      </span> */}
                    </div>

                    <div className=" text-start mt-2">

                      <label className="m-0">{label_management?.series[14]?.title}</label>
                      <p className="t-1">{label_management?.series[14]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={block_country?.map((option) => ({
                            value: option?.id,
                            label: option?.name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptions}
                          onChange={handleSelectChange}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">

                      <label className="m-0">{label_management?.series[15]?.title}</label>
                      <p className="t-1">{label_management?.series[15]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className=""
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.series[16]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.series[17]?.title}</label>
                    <p className="t-1">{label_management?.series[17]?.sub_title}</p>
                    <div className="mt-2">
                      <TagsInput
                        value={searchTags}
                        className="rs-input"
                        onChange={handleTagsChange}
                        name="search_tags"
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={series} className="btn btn-primary">
                    {label_management?.series[33]?.title}
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="card p-3">
                  <h5>{label_management?.series[18]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series[19]?.title}
                      {compressResolutionFormat?.series === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series[19]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1,
                                  'file1'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(1)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series[20]?.title}
                      {compressResolutionFormat?.series === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series[20]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2,
                                  'file2'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(2)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.series[21]?.title}
                      {compressResolutionFormat?.series === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.series[21]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3,
                                  'file3'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() => handleDelete(3)}
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file3 && <div className="errorred mt-1">{error?.file3}</div>}
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  {/* <div className="card p-3">
                    <h5>Visibility</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="mb-2">Publish Type</label>
                      <div>
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          value="publish_now"
                          checked={publishType === "publish_now"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_now" className="mt-2 ms-1">
                          Publish Now
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="publish_later"
                          name="publish_type"
                          value="publish_later"
                          checked={publishType === "publish_later"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_later" className="mt-2 ms-1">
                          Publish Later
                        </label>
                        {publishType === "publish_later" && (
                          <div className="mb-3">
                            <label className="mt-2">Publish Time</label>
                            <input
                              type="datetime-local"
                              className=" mt-2"
                              id="publish_time"
                              name="publish_time"
                              value={publishTime}
                              onChange={handlePublishTimeChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="card p-3">
                    <h5>{label_management?.series[22]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0 mandatorySimple">{label_management?.series[23]?.title}</label>
                      <p className="p1">{label_management?.series[23]?.sub_title}</p>
                      <select
                        onChange={(e) => handleAccess(e.target.value)}
                        className="form-select"
                        id="access"
                        name="access"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                        ref={accessInputRef}
                      >
                        <option value="">Select User Access</option>
                        {/* <option value={item.role}>{item.name}</option> */}
                        {user_access?.map((item, key) => (
                          <option value={item?.role || item?.value}>{item?.name}</option>
                        ))}
                      </select>
                      <span className="errorred mt-2 d-block">
                        {!isValidationHiddenAccess && (
                          <p>{validationMessageAccess}</p>
                        )}
                      </span>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.series[24]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[25]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            onChange={handleInput}
                            className="rs-input"
                            defaultChecked={
                              featured?.featured === 1 ? true : false
                            }
                            checked={featured?.featured === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            onChange={handleInput}
                            value={featured?.featured === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[26]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            onChange={handleInput}
                            defaultChecked={active?.active === 1 ? true : false}
                            checked={active?.active === 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            onChange={handleInput}
                            value={active?.active === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[27]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={banner?.banner === 1 ? true : false}
                            checked={banner?.banner === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            onChange={handleInput}
                            value={banner?.banner === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.series[28]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={
                              series_trailer?.series_trailer === 1 ? true : false
                            }
                            checked={
                              series_trailer?.series_trailer === 1 ? true : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            value={
                              series_trailer?.series_trailer === 1 ? "1" : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">
                        Season 1 :
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            className="r-input"
                            defaultChecked={series_trailer.series_trailer == 1 ? true : false}
                            checked={series_trailer.series_trailer == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="series_trailer"
                            id="series_trailer"
                            onChange={handleInput}
                            value={series_trailer.series_trailer == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.series[29]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0">{label_management?.series[30]?.title}</label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg"
                          onChange={(e) => setWebsitetitle(e.target.value)}
                        // placeholder="websitetitle"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">{label_management?.series[31]?.title}</label>

                      <div className=" mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg"
                          onChange={(e) => setWebsiteurl(e.target.value)}
                        // placeholder="websitetitle"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2">
                      <label className="m-0">{label_management?.series[32]?.title}</label>

                      <div className=" mt-2">
                        <textarea
                          type="text"
                          className="rs-input form-control-lg"
                          onChange={(e) => setMetadescription(e.target.value)}
                        // placeholder="metadescription"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  {/* <div className="card p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* <section className="section container-fluid pt-3">
            <div className="iq-card p-2 mt-3">
              <h4 className="p-2">
                <i className="entypo-plus"></i> Add New Series
              </h4>
              <hr />
              <div className="p-3">
                <div className="row mt-1">
                  <div className="col-sm-6 mb-3">
                    <label className="m-0">Title</label>
                    <p className="p1">
                      Add the series title in the textbox below.
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="Series Title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-6" data-collapsed="0">
                    <label className="m-0">Slug</label>
                    <div className="panel-body">
                      <p className="p1">Add a URL Slug</p>
                      <input
                        type="text"
                        className="form-control"
                        name="slug"
                        id="slug"
                        placeholder="Series Slug"
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the series image ( 9:16 Ratio or 1080X1920px ):
                        </p>
                        <input
                          type="file"
                          multiple={true}
                          className="form-control image series_image"
                          name="image"
                          id="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series Player Image </label>
                        </div>
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the Player image ( 16:9 Ratio or 1280X720px ):
                        </p>
                        <input
                          type="file"
                          multiple={true}
                          className="form-control"
                          name="player_image"
                          id="player_image"
                          onChange={(e) => setPlayer_image(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-md-6">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Series TV Image Cover</label>
                        </div>
                      </div>

                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Select the TV series image ( 16:9 Ratio or 1920 X 1080
                          px ):
                        </p>
                        <input
                          type="file"
                          multiple={true}
                          className="form-control image"
                          name="tv_image"
                          id="tv_image"
                          onChange={(e) => setTv_image(e.target.files[0])}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-primary mt-3" data-collapsed="0">
                  {" "}
                  <div className="panel-heading">
                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">
                            Series Details, Links, and Info
                          </label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <textarea
                          className="form-control"
                          name="details"
                          id="summary-ckeditor"
                          onChange={(e) => setDetails(e.target.value)}
                        ></textarea>

                        <JoditEditor
                          value={details}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>

                    <div
                      className="panel panel-primary mt-3"
                      data-collapsed="0"
                    >
                      {" "}
                      <div className="panel-heading">
                        <div className="panel-title font-weight-bold">
                          <label className="m-0">Short Description</label>
                        </div>{" "}
                      </div>
                      <div className="panel-body col-sm-12 p-0">
                        <p className="p1">
                          Add a short description of the series below:
                        </p>
                        <textarea
                          className="form-control"
                          name="description"
                          id="description"
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Cast and Crew</label>{" "}
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Add artists for the series below:
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Category</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Category Below:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Ratings</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body p-0 ">
                            <p className="p1">IMDb Ratings 10 out of 10</p>
                            <select
                              className="form-control"
                              name="rating"
                              id="rating"
                              onChange={(e) => setRating(e.target.value)}
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Language</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Select a Series Language Below:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="col-sm-6">
                        <div className="panel panel-primary" data-collapsed="0">
                          {" "}
                          <div className="panel-heading">
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Series Year</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body  p-0">
                            <p className="p1">Series Created Year</p>
                            <input
                              className="form-control"
                              name="year"
                              id="year"
                              onChange={(e) => setYear(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6" data-collapsed="0">
                        <div className="panel-heading">
                          <div className="panel-title font-weight-bold">
                            <label className="m-0">Search Tags</label>
                          </div>

                          <div className="">
                            <input
                              className="form-control "
                              type="search"
                              placeholder="search_tags"
                              aria-label="Search"
                              onChange={(e) => setSearch_tag(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row text-start mt-4">
                      <div className="col-sm-6">
                        <label className="m-0">Choose Ads Position</label>

                        <div className="">
                          <Select
                            options={adsPositions}
                            onChange={(e) => handleAdsPositionChange(e)}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <label className="m-0">Choose Advertisement</label>

                        <div className="">
                          <Select
                            options={secondSelectOptions}
                            onChange={(e) => setSecondSelectOptions(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-sm-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              {" "}
                              <label className="m-0">Duration</label>
                            </div>{" "}
                          </div>
                          <div className="panel-body">
                            <p className="p1">
                              Enter the duration in the (HH: MM : SS) format{" "}
                            </p>
                            <input
                              className="form-control"
                              name="duration"
                              id="duration"
                              onChange={(e) => setDuration(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label className="m-0">User Access</label>
                        <p className="p1">
                          Who Is Allowed To View This Series ?
                        </p>
                        <Select
                          options={userAccesses}
                          onChange={(e) => handleAccessChange(e)}
                          className="abcd"
                        />
                      </div>

                      {userAccessesValue?.value === "ppv" ? (
                        <>
                          <div className="row mt-3 text-start ">
                            <div className="col-sm-6">
                              <label className="m-0">PPV Price</label>
                              <div className="col-lg-12 row">
                                <div className="col-6 col-lg-6">
                                  <p className="p1">
                                    Apply PPV Price from Global Settings?{" "}
                                  </p>
                                </div>
                                <div className="col-6 col-lg-6">
                                  <label className="switch">
                                    <input
                                      name="ppv_status"
                                      defaultChecked={checkboxCheckedseason}
                                      onChange={handleCheckboxChangeseason}
                                      type="checkbox"
                                    />
                                    <span
                                      className="slider round"
                                      name="ppv_status"
                                      checked={checkboxCheckedseason}
                                      onChange={handleCheckboxChangeseason}
                                    ></span>
                                  </label>
                                </div>
                              </div>

                              <div className="panel-body">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="PPV Price"
                                  name="ppv_price"
                                  id="price"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0"> IOS PPV Price</label>
                              <p className="p1">
                                Apply IOS PPV Price from Global Settings?
                              </p>
                              <div className="panel-body ppv_price_ios">
                                <Select
                                  name="ios_ppv_price"
                                  className="form-control"
                                  options={ios_ppv_price}
                                  onChange={(e) =>
                                    setIos_ppv_price(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      <div className="col-sm-6 mt-4">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <label className="m-0 p2">
                            Apply Global PPV Price:
                          </label>

                          <label className="switch">
                            <input
                              name="ppv_status"
                              onChange={handleInput}
                              defaultChecked={
                                ppv_status?.ppv_status === 1 ? true : false
                              }
                              checked={
                                ppv_status?.ppv_status === 1 ? true : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              name="ppv_status"
                              onChange={handleInput}
                              value={ppv_status?.ppv_status === 1 ? "1" : "0"}
                            ></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6 mt-4">
                        <div className="panel panel-primary" data-collapsed="0">
                          <div className="panel-heading">
                            {" "}
                            <div className="panel-title font-weight-bold">
                              <label className="m-0">Status Settings</label>
                            </div>
                          </div>
                          <div className="panel-body mt-3">
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="featured">
                                Is this series Featured:
                              </label>

                              <label className="switch">
                                <input
                                  name="featured"
                                  onChange={handleInput}
                                  defaultChecked={
                                    featured?.featured === 1 ? true : false
                                  }
                                  checked={
                                    featured?.featured === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="featured"
                                  onChange={handleInput}
                                  value={featured?.featured === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="banner" name="banner">
                                Banner :
                              </label>

                              <label className="switch">
                                <input
                                  name="banner"
                                  onChange={handleInput}
                                  defaultChecked={
                                    banner?.banner === 1 ? true : false
                                  }
                                  checked={banner?.banner === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="banner"
                                  onChange={handleInput}
                                  value={banner?.banner === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline">
                              <label className="p2" for="active">
                                Is this series Active:
                              </label>

                              <label className="switch">
                                <input
                                  name="active"
                                  onChange={handleInput}
                                  defaultChecked={
                                    active?.active === 1 ? true : false
                                  }
                                  checked={active?.active === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="active"
                                  onChange={handleInput}
                                  value={active?.active === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>

                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="trailer">
                                  Season Trailer:
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    trailer?.trailer === 1 ? true : false
                                  }
                                  checked={
                                    trailer?.trailer === 1 ? true : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="trailer"
                                  onChange={handleInput}
                                  value={trailer?.trailer === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div>
                                <label className="p2" for="active">
                                  Series Trailer:
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="series_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    series_trailer?.series_trailer === 1
                                      ? true
                                      : false
                                  }
                                  checked={
                                    series_trailer?.series_trailer === 1
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="series_trailer"
                                  onChange={handleInput}
                                  value={
                                    series_trailer?.series_trailer === 1
                                      ? "1"
                                      : "0"
                                  }
                                ></span>
                              </label>
                            </div>
                            <div className="d-flex justify-content-between align-items-baseline mr-2">
                              <div className="">
                                <label className="p2" for="active">
                                  Season 1 :
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="season_trailer"
                                  onChange={handleInput}
                                  defaultChecked={
                                    season_trailer?.season_trailer === 1
                                      ? true
                                      : false
                                  }
                                  checked={
                                    season_trailer?.season_trailer === 1
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="season_trailer"
                                  onChange={handleInput}
                                  value={
                                    season_trailer?.season_trailer === 1
                                      ? "1"
                                      : "0"
                                  }
                                ></span>
                              </label>
                            </div>

                            <div className="d-flex justify-content-between align-items-baseline mr-2 views">
                              <div className="">
                                <label className="p2" for="active">
                                  Views :
                                </label>
                              </div>

                              <label className="switch">
                                <input
                                  name="views"
                                  onChange={handleInput}
                                  defaultChecked={
                                    views?.views === 1 ? true : false
                                  }
                                  checked={views?.views === 1 ? true : false}
                                  type="checkbox"
                                />
                                <span
                                  className="slider round"
                                  name="views"
                                  onChange={handleInput}
                                  value={views?.views === 1 ? "1" : "0"}
                                ></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12 text-end">
                      <input
                        type="submit"
                        value="Upload"
                        onClick={series}
                        className="btn btn-primary "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
}

export default Addnewseries;
