import React, { useState } from "react";
import { FaPlus, FaTrashAlt, FaThLarge, FaBars } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; // Import Drag and Drop
import axios from "axios"; // Assuming you use axios for API requests
import "./Tvpayouts.css"; // Assuming CSS is imported
import { Link } from "react-router-dom";

function Tvpayouts() {
    const [viewMode, setViewMode] = useState("list"); // State for view mode (list or grid)
    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const [sortBy, setSortBy] = useState("latest"); // State for sorting
    const [payouts, setPayouts] = useState([
        {
            id: 341,
            title: "Sintel",
            slug: "sintel",
            type: "m3u8_url",
            active: 1,
            status: 1,
            Thumbnail: "https://api.flicknexs.com/public/uploads/images/video-image-1728382515.jpg",
            upload_on: "October 8th 2024",
        },
        {
            id: 340,
            title: "Sample.mp4",
            slug: null,
            type: "mp4_video_upload",
            active: null,
            status: null,
            Thumbnail: "https://api.flicknexs.com/public/uploads/images/default_video_image1715941002.webp",
            upload_on: "October 3rd 2024",
        },
    ]);

    // Sorting and filtering logic
    const filteredPayouts = payouts
        .filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            if (sortBy === "latest") {
                return new Date(b.upload_on) - new Date(a.upload_on);
            } else if (sortBy === "atoz") {
                return a.title.localeCompare(b.title);
            }
            return 0;
        });

    // Handle drag end for reordering
    const handleDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) return; // Do nothing if dropped outside the list

        const updatedPayouts = Array.from(filteredPayouts);
        const [movedItem] = updatedPayouts.splice(source.index, 1);
        updatedPayouts.splice(destination.index, 0, movedItem);

        setPayouts(updatedPayouts); // Update state with new order

        // Send reordered IDs to API
        const reorderedIds = updatedPayouts.map((item) => item.id);
        sendReorderedPayoutsToApi(reorderedIds);
    };

    const sendReorderedPayoutsToApi = (reorderedIds) => {
        // Example API call
        axios.post("https://api.yourdomain.com/reorder-payouts", {
            payoutIds: reorderedIds,
        })
            .then((response) => {
                console.log("Reorder successful:", response.data);
            })
            .catch((error) => {
                console.error("Error reordering payouts:", error);
            });
    };

    const handleDelete = (id) => {
        setPayouts(payouts.filter((payout) => payout.id !== id));
    };

    const handleViewToggle = (mode) => {
        setViewMode(mode);
    };

    return (
        <div className="m-0 p-0">
            <section className="container-fluid iq-card">
                <div className="row py-3">
                    <div className="col-lg-6 col-sm-6">
                        <h3>TV Payouts</h3>
                    </div>
                    <div className="col-lg-6 col-sm-6 text-end">
                        <button className="btn btn-primary">
                            <FaPlus /> Create TV Payouts
                        </button>
                    </div>
                </div>

                {/* Search and sort controls */}
                <div className="row py-3">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-end">
                        <select
                            className="form-select"
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                        >
                            <option value="latest">Sort by Latest</option>
                            <option value="atoz">Sort A to Z</option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 ">
                        <input
                            type="text"
                            placeholder="Search by ..."
                            style={{ height: "50px" }}
                            className="rs-input form-control-lg"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 text-end">
                        <button
                            className="btn btn-outline-secondary mx-2"
                            onClick={() => handleViewToggle("grid")}
                        >
                            <FaThLarge />
                        </button>
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => handleViewToggle("list")}
                        >
                            <FaBars />
                        </button>
                    </div>
                </div>

                {/* DragDropContext for list/grid view */}
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="payouts">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {filteredPayouts.length === 0 ? (
                                    <div className="text-center">
                                        <h5 className="mt-2">There are no TV Payouts available!</h5>
                                    </div>
                                ) : viewMode === "list" ? (
                                    // List view mode without flex
                                    <div className="list-view-container">
                                        {filteredPayouts.map((item, index) => (
                                            <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                                {(provided) => (
                                                    <Link to={`/edit/tv-payouts/${item?.id}`}>
                                                        <div
                                                            className="list-item row align-items-center mb-3"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{ width: "100%" }} // Full-width for list view
                                                        >
                                                            <div className="col-1">
                                                                <img
                                                                    src={item.Thumbnail}
                                                                    alt={item.title}
                                                                    className="thumbnail w-100"
                                                                />
                                                            </div>
                                                            <div className="col-5">
                                                                <h5 className="mb-1">{item.title}</h5>
                                                                <p className="text-muted">
                                                                    <i className="fas fa-video"></i> No Destination Selected
                                                                </p>
                                                            </div>
                                                            <div className="col-6 text-end">
                                                                <button
                                                                    className="btn btn-outline-danger"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleDelete(item.id);
                                                                    }}
                                                                >
                                                                    <FaTrashAlt />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                ) : (
                                    // Grid view mode with d-flex
                                    <div className="d-flex flex-wrap justify-content-start">
                                        {filteredPayouts.map((item, index) => (
                                            <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                                {(provided) => (
                                                    <Link to={`/edit/tv-payouts/${item?.id}`}>
                                                        <div
                                                            className="mb-3 p-2"
                                                            style={{ width: "300px" }}  // Adjust the width as needed
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="card">
                                                                <img
                                                                    src={item.Thumbnail}
                                                                    className="card-img-top w-100"
                                                                    style={{ height: "200px" }}  // Adjust height as needed
                                                                    alt={item.title}
                                                                />
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{item.title}</h5>
                                                                    <p className="card-text">Uploaded on: {item.upload_on}</p>
                                                                    <button className="btn btn-primary">
                                                                        View Details
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </section>
        </div>
    );
}

export default Tvpayouts;
