import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";

function Labelsstorelabels(props) {
    const [home_genre, setHome_genre] = useState("");
    const [in_home, setIn_home] = useState("");
    const [in_menu, setIn_menu] = useState("");
    const [parent_id, setParent_id] = useState("");
    const [banner, setBanner] = useState("");
    const [data, setData] = useState([]);

    const [editUser, setEditUser] = useState({
        title: "",
        default_name: "",
        sub_title: "",
        source: "",
        display_for: "",
    });

    const access_token = localStorage.getItem("access_token");

    const handleInput = (e) => {
        const { name, value } = e.target;
        setEditUser((prevEditUser) => ({
            ...prevEditUser,
            [name]: value,
        }));

        setEditUser({ ...editUser, title: e.target.value });
        setEditUser({ ...editUser, [e.target.name]: e.target.value });
    };



    // store 


    const [language, setLanguage] = useState([]);
    const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

    const handleSelectChangelanguage = (selectedValues) => {
        setSelectedOptionslanguage(selectedValues);
    };

    const [formData, setFormData] = useState({
    });

    //update

    useEffect(() => {
        const fetchDataDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl}/admin/Label-Management/create`,
                    { headers: headers }
                );
                setLanguage(response?.data?.MenuLabel);

            } catch (error) {
                console.error(error);
            }
        };

        fetchDataDetails();
    }, []);


    const [processing, setProcessing] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    async function AddManagevideocategories() {
        const edituser = {
            default_name: editUser?.default_name,
            title: editUser?.title,
            sub_title: editUser?.sub_title,
            source: editUser?.source,
            display_for: selectedOptionslanguage?.map((option) => option?.value),
            ...formData,
        };

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
        };

        let firstApiStatus = false;
        let resultapi = null;
        let resultapifalse = null;

        setProcessing(true);
        setShowOverlay(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/admin/Label-Management/store`,
                edituser,
                { headers: headers }
            );

            if (response?.data?.status === true) {
                firstApiStatus = true;
                resultapi = response?.data;
            } else {
                resultapifalse = response?.data || { status: false, message: 'Label failed' };
            }
        } catch (error) {
            resultapifalse = { status: false, message: error.message || 'Request failed' };
        }

        if (firstApiStatus) {
            props?.setApiresponsealert(resultapi || { status: true, message: 'Label successful' });
            props?.setShowalert(true);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            props?.setApiresponsealert(resultapifalse || { status: false, message: 'Label failed' });
            props?.setShowalert(true);
            setProcessing(false);
            setShowOverlay(false);
        }
    }

    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    useEffect(async () => {
        await axios
            .get(`${process.env.REACT_APP_Baseurl}/admin/videos-category/create`, {
                headers: headers,
            })
            .then((response) => {
                var result = response?.data?.Video_Category;
                setData(result);
            })
            .catch((error) => console.log(error));
    }, []);

    return (
        <>
            <div className=" m-0 p-0">
                <section className="container-fluid">
                    <div className="col-lg-12 row py-3">
                        <div className="col-6 col-sm-6 col-lg-6">
                            <h3>Label</h3>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                            <div className="text-end">
                                <button
                                    onClick={AddManagevideocategories}
                                    className="btn btn-primary"
                                >
                                    Store
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        {showOverlay && <Response_Processing></Response_Processing>}
                    </div>

                    <div className="row col-lg-12 ">
                        <div className=" ">
                            <div className="card p-3">
                                <h5>Label store</h5>
                                <hr></hr>
                                <div className=" text-start">
                                    <label className="m-0">
                                        Default Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            className="rs-input form-control-lg"
                                            id="default_name"
                                            name="default_name"
                                            placeholder="Enter Default Name"
                                            onChange={handleInput}
                                            value={editUser?.default_name}
                                        />
                                    </div>
                                </div>
                                <div className=" text-start">
                                    <label className="mt-2">
                                        Title
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            className="rs-input form-control-lg"
                                            id="title"
                                            name="title"
                                            placeholder="Enter Default Name"
                                            onChange={handleInput}
                                            value={editUser?.title}
                                        />
                                    </div>
                                </div>
                                <div className=" text-start">
                                    <label className="mt-2">
                                        Sub Title
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            className="rs-input form-control-lg"
                                            id="sub_title"
                                            name="sub_title"
                                            placeholder="Enter Default Name"
                                            onChange={handleInput}
                                            value={editUser?.sub_title}
                                        />
                                    </div>
                                </div>
                                <div className=" text-start">
                                    <label className="mt-2">
                                        Sub Title
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            className="rs-input form-control-lg"
                                            id="source"
                                            name="source"
                                            placeholder="Enter Default Name"
                                            onChange={handleInput}
                                            value={editUser?.source}
                                        />
                                    </div>
                                </div>
                                <div className=" text-start">
                                    <label className="mt-2">
                                        Dislay for
                                    </label>
                                    <div className="mt-2">
                                        <Select
                                            options={language?.map((option) => ({
                                                value: option?.id,
                                                label: option?.name,
                                            }))}
                                            className=""
                                            id="language"
                                            isMulti
                                            value={selectedOptionslanguage}
                                            onChange={handleSelectChangelanguage}
                                        />
                                    </div>
                                </div>



                            </div>



                            <div className="text-start mb-3">
                                <button
                                    onClick={AddManagevideocategories}
                                    className="btn btn-primary"
                                >
                                    Store
                                </button>
                            </div>
                        </div>

                        {/* Second Part of Section  */}


                    </div>
                </section>
            </div>
        </>
    );
}

export default Labelsstorelabels;
