import React from 'react'
import { MdControlPoint, MdDelete } from 'react-icons/md'
import { Link } from 'react-router-dom'
import MessageBox from './MessageBox';

const AdminNavbar = ({ heading, totalDataLength, deleteCount, deleteMethod, navigation, navText, addIconHide }) => {
    return (
        <div className="d-flex align-items-center flex-wrap pb-3 ">
            <div className="col-md-6 col-12 ">
                <h4 className="admin-title mb-0 theme-text-color text-md-start"> <MessageBox text={heading} classname="theme-text-color" />
                    {totalDataLength && <span className="admin-sub-title theme-text-color ms-2 ">( {totalDataLength} <MessageBox text='results found' classname="theme-text-color" />   )</span>}</h4>
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center justify-content-end mt-3 mt-sm-0" >
                {deleteCount > 0 && (
                    <button className="bg-primary p-2 rounded-2 d-inline-flex align-items-center " onClick={() => deleteMethod()}>
                        <MdDelete className="exportIcon me-1" fill="#fff" />
                        <MessageBox text='Delete' classname='admin-button-text ' />
                    </button>
                )}
                {navigation && <Link to={`${navigation}`} className="admin-title ms-3 rounded-2 d-inline-flex align-items-center bg-primary text-decoration-hover  py-2 px-3">{!addIconHide && <MdControlPoint className="me-2 exportIcon text-white" style={{ width: "22px", height: "22px" }} />} <MessageBox classname="admin-button-text text-white" text={navText} /> </Link>}
            </div>
        </div>
    )
}

export default AdminNavbar