
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import '../Main_labels.css'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Response_Loading_processing from "../../../../../../src/layout/Others/Response_Loading_processing";
import backarrow from "../../../../../components/Images/backarrow.svg";
import { FaSearch } from "react-icons/fa";
import '../Labels.css'

function Detailsmanage_labels(props) {

    const [getDetailslists, setDetailslists] = useState([]);
    const [searchTermlistDetails, setSearchTermlistDetails] = useState(""); // New state for search term
    const [getDetailsslider, setDetailsslider] = useState([]);
    const [searchTermsliderDetails, setsearchTermsliderDetails] = useState("");
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    useEffect(() => {
        const fetchDataDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_Baseurl}/admin/Label-Management/list-depends-source`,
                    { headers: headers }
                );
                setDetailslists(response?.data?.label_management?.F_details);
                setDetailsslider(response?.data?.label_management?.F_Slider);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchDataDetails();
    }, []);

    async function handleUpdateDetails(updatedItem) {
        const formData = new FormData();

        formData.append("default_name", updatedItem?.default_name);
        formData.append("title", updatedItem?.title);
        formData.append("sub_title", updatedItem?.sub_title);
        formData.append("source", "F_Details");

        let firstApiStatus = false;
        let resultapi = null;
        setProcessing(true);
        setShowOverlay(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/admin/Label-Management/update/${updatedItem?.id}`,
                formData,
                { headers: headers }
            );

            if (response.data.status === true) {
                firstApiStatus = true;
                resultapi = response.data;
                // Update the local state with the updated data
                const updatedList = [...getDetailslists];
                const itemIndex = updatedList.findIndex((item) => item.id === updatedItem.id);

                if (itemIndex !== -1) {
                    updatedList[itemIndex] = { ...updatedList[itemIndex], ...updatedItem };
                    setDetailslists(updatedList);
                }
            }
        } catch (error) { }

        if (firstApiStatus) {
            props.setApiresponsealert(resultapi);
            props.setShowalert(true);
        } else {
            if (resultapi && resultapi.status === false) {
                var resultError = resultapi;
                props.setApiresponsealert(resultError);
                props.setShowalert(false);
                setProcessing(false);
                setShowOverlay(false);
            }
        }
    }


    async function handleUpdateDetailsslider(updatedItemslider) {
        const formData = new FormData();

        formData.append("default_name", updatedItemslider?.default_name);
        formData.append("title", updatedItemslider?.title);
        formData.append("sub_title", updatedItemslider?.sub_title);
        formData.append("source", "F_Slider");

        let firstApiStatus = false;
        let resultapi = null;
        setProcessing(true);
        setShowOverlay(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/admin/Label-Management/update/${updatedItemslider?.id}`,
                formData,
                { headers: headers }
            );

            if (response.data.status === true) {
                firstApiStatus = true;

                resultapi = response.data;

                // Update the local state with the updated data
                const updatedListslider = [...getDetailsslider];
                const itemIndex = updatedListslider.findIndex((item) => item.id === updatedItemslider.id);

                if (itemIndex !== -1) {
                    updatedListslider[itemIndex] = { ...updatedListslider[itemIndex], ...updatedItemslider };
                    setDetailsslider(updatedListslider);
                }
            }
        } catch (error) { }

        if (firstApiStatus) {
            props.setApiresponsealert(resultapi);
            props.setShowalert(true);
        } else {
            if (resultapi && resultapi.status === false) {
                var resultError = resultapi;
                props.setApiresponsealert(resultError);
                props.setShowalert(false);
                setProcessing(false);
                setShowOverlay(false);
            }
        }
    }

    const filteredDetailsLists = getDetailslists?.filter((item) =>
        item?.title?.toLowerCase().includes(searchTermlistDetails?.toLowerCase())
    );

    const filteredDetailsListsslider = getDetailsslider?.filter((item) =>
        item?.title?.toLowerCase().includes(searchTermsliderDetails?.toLowerCase())
    );



    return (
        <>

            <Link to="/labels" className="backarrow">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
            </Link>

            <section className="section Label_settings container-fluid pt-3">
                <div className="row">
                    <div className="col-sm-12">

                        <div className="iq-card-body table-responsive iq-card">
                            <div className="table-view">
                                <ul
                                    className="nav nav-pills justify-content-center row"
                                    id="pills-tab "
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item col-lg-6 col-12 col-sm-6 col-md-6 "
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link active fs-6 fw-bold px-4 w-100  mx-auto "
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-next"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                        >
                                            Details Lists
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item col-lg-6 col-12 col-sm-6 col-md-6"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link fs-6 fw-bold px-4 w-100  mx-auto"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-next2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                        >
                                            Home Slider Lists
                                        </button>
                                    </li>

                                </ul>
                                <div className="tab-content p-0" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-next"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                    >

                                        <div className="col-lg-12 row mt-3">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-end">
                                                {/* Search Input */}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by Text"
                                                    value={searchTermlistDetails}
                                                    onChange={(e) => setSearchTermlistDetails(e.target.value)}
                                                />
                                                <div className="searchPageIconFasearch">
                                                    <FaSearch
                                                        className="searchPageIconFasearch p-1 fs-5"
                                                        value={searchTermlistDetails}
                                                        onChange={(e) => setSearchTermlistDetails(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <section className="section container-fluid">
                                            <div id="content-page" className="content-page">
                                                <div className="mt-3">
                                                    <div className="bootstrapTable ">
                                                        <div className="col-12 p-0">
                                                            <div className="row m-0 p-0">
                                                                <div className="col-lg-8 col-sm-8 col-8 data">
                                                                    {" "}
                                                                    <div>Text</div>
                                                                </div>
                                                                {/* <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Help Text</div>
                                                                </div> */}
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Actions</div>
                                                                </div>
                                                            </div>
                                                            <DragDropContext >
                                                                <Droppable droppableId="menu-items">
                                                                    {(provided) => (
                                                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                                                            {loading ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <Response_Loading_processing></Response_Loading_processing>
                                                                                    Loading...
                                                                                </div>
                                                                            ) : filteredDetailsLists?.length <= 0 ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <p>No Details Lists Available !!! </p>
                                                                                </div>
                                                                            ) : (
                                                                                filteredDetailsLists?.map((item, index) => (
                                                                                    <Draggable
                                                                                    >
                                                                                        {(provided) => (
                                                                                            <li
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                className={`bg-white orderborder ${item?.order
                                                                                                    } index ${index + 1}w-100`}
                                                                                            >
                                                                                                <div className="row m-0 p-0 align-items-center">
                                                                                                    <div className="col-lg-8 col-sm-8 col-8 data_th">
                                                                                                        <div
                                                                                                        // className="d-flex justify-content-between align-items-center  w-100"
                                                                                                        >
                                                                                                            <div
                                                                                                            // className="rightVideoTitle"
                                                                                                            >
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg w-100"
                                                                                                                    value={item?.title}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newName = e.target.value;
                                                                                                                        const updatedList = [...getDetailslists];
                                                                                                                        updatedList[index].title = newName;
                                                                                                                        setDetailslists(updatedList);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {/* <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="rightVideoTitle">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg"
                                                                                                                    value={item?.sub_title}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newSlug = e.target.value;
                                                                                                                        const updatedList = [...getDetailslists];
                                                                                                                        updatedList[index].sub_title = newSlug;
                                                                                                                        setDetailslists(updatedList);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">

                                                                                                            <div className="rightVideoTitle">
                                                                                                                <button className="btn btn-primary" onClick={() => handleUpdateDetails(item)}>Update</button>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))
                                                                            )}
                                                                            {provided.placeholder}
                                                                        </ul>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div
                                        className="tab-pane fade"
                                        id="pills-next2"
                                        role="tabpanel"
                                        aria-labelledby="pills-profile-tab"
                                    >
                                        <div className="col-lg-12 row mt-3">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 text-end">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by Text"
                                                    value={searchTermsliderDetails}
                                                    onChange={(e) => setsearchTermsliderDetails(e.target.value)}
                                                />
                                                <div className="searchPageIconFasearch">
                                                    <FaSearch
                                                        className="searchPageIconFasearch p-1 fs-5"
                                                        value={searchTermsliderDetails}
                                                        onChange={(e) => setsearchTermsliderDetails(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <section className="section container-fluid">
                                            <div id="content-page" className="content-page">
                                                <div className="mt-3">
                                                    <div className="bootstrapTable ">
                                                        <div className="col-12 p-0">
                                                            <div className="row m-0 p-0">
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Text</div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Help Text</div>
                                                                </div>
                                                                <div className="col-lg-4 col-sm-4 col-4 data">
                                                                    {" "}
                                                                    <div>Actions</div>
                                                                </div>
                                                            </div>
                                                            <DragDropContext >
                                                                <Droppable droppableId="menu-items">
                                                                    {(provided) => (
                                                                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                                                                            {loading ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <Response_Loading_processing></Response_Loading_processing>
                                                                                    Loading...
                                                                                </div>
                                                                            ) : filteredDetailsListsslider?.length <= 0 ? (
                                                                                <div className="noRecord">
                                                                                    {" "}
                                                                                    <p>No Details slider Lists Available !!! </p>
                                                                                </div>
                                                                            ) : (
                                                                                filteredDetailsListsslider?.map((item, index) => (
                                                                                    <Draggable
                                                                                    >
                                                                                        {(provided) => (
                                                                                            <li
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                className={`bg-white orderborder ${item?.order
                                                                                                    } index ${index + 1}w-100`}
                                                                                            >
                                                                                                <div className="row m-0 p-0 align-items-center">

                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="rightVideoTitle">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg"
                                                                                                                    value={item?.title}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newName = e.target.value;
                                                                                                                        const updatedListslider = [...getDetailsslider];
                                                                                                                        updatedListslider[index].title = newName;
                                                                                                                        setDetailsslider(updatedListslider);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                                            <div className="rightVideoTitle">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="rs-input form-control-lg"
                                                                                                                    value={item?.sub_title}
                                                                                                                    onChange={(e) => {
                                                                                                                        const newSlug = e.target.value;
                                                                                                                        const updatedListslider = [...getDetailsslider];
                                                                                                                        updatedListslider[index].sub_title = newSlug;
                                                                                                                        setDetailsslider(updatedListslider);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-4 col-sm-4 col-4 data_th">
                                                                                                        <div className="d-flex justify-content-between align-items-center">

                                                                                                            <div className="rightVideoTitle">
                                                                                                                <button className="btn btn-primary" onClick={() => handleUpdateDetailsslider(item)}>Update</button>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))
                                                                            )}
                                                                            {provided.placeholder}
                                                                        </ul>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Detailsmanage_labels;




