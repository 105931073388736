import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Form, Button, Table } from 'react-bootstrap';
import axios from "axios";
import Response_Processing from "../../../../../src/layout/Others/Response_Processing";
import { IoArrowBack } from "react-icons/io5";
import { toast } from "react-toastify";
import usePpvGlobalImageConfig from "../../../../components/CommonApis/ppv_global_change";

function Ppv_Setting(props) {
  const access_token = localStorage.getItem("access_token");
  const [setting, setSetting] = useState({
    // Advertisement_Setting
    ads_on_videos: "",
    cpc_advertiser: "",
    featured_pre_ad: "",
    featured_mid_ad: "",
    featured_post_ad: "",
    cpc_admin: "",
    cpv_advertiser: "",
    cpv_admin: "",
    default_ads_url: "",
    ads_play_unlimited_period: "",
    ads_on_livestream: "",
    ads_on_audios: "",
    ads_on_episodes: "",
    // Advertisement_Setting
    //coupon code
    new_subscriber_coupon: "",
    discount_percentage: "",
    coupon_code: "",
    //coupon code
    //email setting
    google_oauth_key: "",
    coupon_status: "",
    google_tracking_id: "",
    system_email: "",
    system_phone_number: "",
    system_address: "",
    //email setting
    //login setting
    notification_icon: "",
    notification_key: "",
    login_text: "",
    login_content: "",
    signature: "",
    //login setting
    //geo fencing
    id: "",
    geofencing: "",
    geofencing_status: "",
    //geo fencing
    // payout setting
    payout_method: "",
    //payout setting
    //ppv setting
    ppv_status: "",
    enable_ppv_button: "",
    ppv_price: "",
    ppv_hours: "",
    ios_product_id: "",
    ios_plan_price: "",
    expiry_time_started: "",
    expiry_day_notstarted: "",
    expiry_hours_notstarted: "",
    expiry_min_notstarted: "",
    PPV_Individual_Contents: "",
    //ppv setting
    // capcha setting
    captcha_site_key: "",
    captcha_secret_key: "",
    captcha: "",
    //capcha setting
    //registration settings
    free_registration: "",
    activation_email: "",
    premium_upgrade: "",
    access_free: "",
    enable_landing_page: "",
    //registration settings
    //search settings
    search_title_status: "",
    search_category_status: "",
    search_tags_status: "",
    search_description_status: "",
    search_details_status: "",
    //search settings
    //series settings
    series_season: "",
    series_networks_status: "",
    //series settings
    //site settings
    website_name: "",
    website_description: "",
    logo_height: "",
    logo_width: "",
    login_content: "",
    favicon: "",
    logo: "",
    //site settings
    //social network settings
    facebook_page_id: "",
    google_page_id: "",
    twitter_page_id: "",
    instagram_page_id: "",
    linkedin_page_id: "",
    whatsapp_page_id: "",
    skype_page_id: "",
    youtube_page_id: "",
    email_page_id: "",
    tiktok_page_id: "",
    //social network settings
    //video settings
    videos_per_page: "",
    posts_per_page: "",
    show_description: "",
    show_Links_and_details: "",
    show_genre: "",
    show_languages: "",
    show_subtitle: "",
    show_artist: "",
    show_recommended_videos: "",
    show_views: "",
    videos_expiry_status: "",
    epg_status: "",
    //video settings
    earn_amount: "",
    video_clip_enable: "",
    enable_ppv_rent: "",
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(async () => {
    fetchSetting()
  }, []);
  const fetchSetting = async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/setting/index`, { headers: headers })
      .then((response) => {
        var setting = response?.data?.settings[0];
        setSetting(setting);
      })
      .catch((error) => console.log(error));
  }
  const handleInput = (e) => {
    const { name, value } = e.target;
    setSetting((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageppv_hours("");
    setIsValidationHiddenppv_hours(true);
    setValidationMessageppv_price("");
    setIsValidationHiddenppv_price(true);
    setSetting({ ...setting, [e.target.name]: e.target.value });
  };


  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setSetting({ ...setting, [e.target.name]: 1 });
    } else {
      setSetting({ ...setting, [e.target.name]: 0 });
    }
  };

  const [validationMessageppv_hours, setValidationMessageppv_hours] =
    useState("");
  const [isValidationHiddenppv_hours, setIsValidationHiddenppv_hours] =
    useState(true);

  const [validationMessageppv_price, setValidationMessageppv_price] =
    useState("");
  const [isValidationHiddenppv_price, setIsValidationHiddenppv_price] =
    useState(true);

  const ppv_hoursInputRef = useRef(null);
  const ppv_priceInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (setting?.ppv_hours === "") {
      setValidationMessageppv_hours("PPV Hour cannot be empty.");
      setIsValidationHiddenppv_hours(false);
      focusInputRef = ppv_hoursInputRef;
    } if (setting?.ppv_price === "") {
      setValidationMessageppv_price("PPV Price cannot be empty.");
      setIsValidationHiddenppv_price(false);
      focusInputRef = ppv_priceInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const enable_ppv_renttest = Number.isNaN(parseInt(setting?.enable_ppv_rent, 10)) ? 0 : parseInt(setting?.enable_ppv_rent, 10);

    // advertisement setting
    const ads_on_videos = Number.isNaN(parseInt(setting?.ads_on_videos, 10)) ? 0 : parseInt(setting?.ads_on_videos, 10);
    const ads_play_unlimited_period = Number.isNaN(parseInt(setting?.ads_play_unlimited_period, 10)) ? 0 : parseInt(setting?.ads_play_unlimited_period, 10);
    const ads_on_livestream = Number.isNaN(parseInt(setting?.ads_on_livestream, 10)) ? 0 : parseInt(setting?.ads_on_livestream, 10);
    const ads_on_audios = Number.isNaN(parseInt(setting?.ads_on_audios, 10)) ? 0 : parseInt(setting?.ads_on_audios, 10);
    const ads_on_episodes = Number.isNaN(parseInt(setting?.ads_on_episodes, 10)) ? 0 : parseInt(setting?.ads_on_episodes, 10);

    //coupon setting      
    const coupon_code = Number.isNaN(parseInt(setting?.coupon_code, 10)) ? 0 : parseInt(setting?.coupon_code, 10);

    //payout settings   
    const payout_method = Number.isNaN(parseInt(setting?.payout_method, 10)) ? 0 : parseInt(setting?.payout_method, 10);

    // ppv_status
    const setppv_status = Number.isNaN(parseInt(setting?.ppv_status, 10)) ? 0 : parseInt(setting?.ppv_status, 10);
    const setenable_ppv_button = Number.isNaN(parseInt(setting?.enable_ppv_button, 10)) ? 0 : parseInt(setting?.enable_ppv_button, 10);
    const setPPV_Individual_Contents = Number.isNaN(parseInt(setting?.PPV_Individual_Contents, 10)) ? 0 : parseInt(setting?.PPV_Individual_Contents, 10);

    //registration settings
    const free_registration = Number.isNaN(parseInt(setting?.free_registration, 10)) ? 0 : parseInt(setting?.free_registration, 10);

    //search settings
    const search_title_status = Number.isNaN(parseInt(setting?.search_title_status, 10)) ? 0 : parseInt(setting?.search_title_status, 10);
    const search_category_status = Number.isNaN(parseInt(setting?.search_category_status, 10)) ? 0 : parseInt(setting?.search_category_status, 10);
    const search_tags_status = Number.isNaN(parseInt(setting?.search_tags_status, 10)) ? 0 : parseInt(setting?.search_tags_status, 10);
    const search_description_status = Number.isNaN(parseInt(setting?.search_description_status, 10)) ? 0 : parseInt(setting?.search_description_status, 10);
    const search_details_status = Number.isNaN(parseInt(setting?.search_details_status, 10)) ? 0 : parseInt(setting?.search_details_status, 10);

    //serires settings
    const series_networks_status = Number.isNaN(parseInt(setting?.series_networks_status, 10)) ? 0 : parseInt(setting?.series_networks_status, 10);

    // video settings 
    const videos_expiry_status = Number.isNaN(parseInt(setting?.videos_expiry_status, 10)) ? 0 : parseInt(setting?.videos_expiry_status, 10);
    const videos_per_page = Number.isNaN(parseInt(setting?.videos_per_page, 10)) ? 0 : parseInt(setting?.videos_per_page, 10);
    const epg_status = Number.isNaN(parseInt(setting?.epg_status, 10)) ? 0 : parseInt(setting?.epg_status, 10);
    const show_genre = Number.isNaN(parseInt(setting?.show_genre, 10)) ? 0 : parseInt(setting?.show_genre, 10);
    const show_languages = Number.isNaN(parseInt(setting?.show_languages, 10)) ? 0 : parseInt(setting?.show_languages, 10);
    const show_subtitle = Number.isNaN(parseInt(setting?.show_subtitle, 10)) ? 0 : parseInt(setting?.show_subtitle, 10);
    const show_artist = Number.isNaN(parseInt(setting?.show_artist, 10)) ? 0 : parseInt(setting?.show_artist, 10);
    const show_recommended_videos = Number.isNaN(parseInt(setting?.show_recommended_videos, 10)) ? 0 : parseInt(setting?.show_recommended_videos, 10);
    const show_views = Number.isNaN(parseInt(setting?.show_views, 10)) ? 0 : parseInt(setting?.show_views, 10);
    const show_Links_and_details = Number.isNaN(parseInt(setting?.show_Links_and_details, 10)) ? 0 : parseInt(setting?.show_Links_and_details, 10);
    const show_description = Number.isNaN(parseInt(setting?.show_description, 10)) ? 0 : parseInt(setting?.show_description, 10);

    const editInputvalue = {
      // advertisement setting
      ads_on_videos: ads_on_videos,
      default_ads_url: setting?.default_ads_url,
      featured_pre_ad: setting?.featured_pre_ad,
      featured_mid_ad: setting?.featured_mid_ad,
      featured_post_ad: setting?.featured_post_ad,
      cpv_admin: setting?.cpv_admin,
      cpv_advertiser: setting?.cpv_advertiser,
      cpc_admin: setting?.cpc_admin,
      cpc_advertiser: setting?.cpc_advertiser,
      ads_play_unlimited_period: ads_play_unlimited_period,
      ads_on_livestream: ads_on_livestream,
      ads_on_audios: ads_on_audios,
      ads_on_episodes: ads_on_episodes,

      //coupon setting      
      discount_percentage: setting?.discount_percentage,
      coupon_code: coupon_code,
      new_subscriber_coupon: setting?.new_subscriber_coupon,

      //email settings
      coupon_status: setting?.coupon_status,
      google_tracking_id: setting?.google_tracking_id,
      google_oauth_key: setting?.google_oauth_key,
      system_email: setting?.system_email,
      system_phone_number: setting?.system_phone_number,

      //login settings      
      login_text: setting?.login_text,
      signature: setting?.signature,
      login_content: setting?.login_content,
      notification_key: setting?.notification_key,

      //payout setting
      payout_method: payout_method,

      //ppv settings
      ppv_status: setppv_status,
      enable_ppv_button: setenable_ppv_button,
      PPV_Individual_Contents: setPPV_Individual_Contents,
      ppv_price: setting?.ppv_price,
      ppv_hours: setting?.ppv_hours,
      ios_plan_price: setting?.ios_plan_price,
      ios_product_id: setting?.ios_product_id,
      expiry_time_started: setting?.expiry_time_started,
      expiry_day_notstarted: setting?.expiry_day_notstarted,
      expiry_hours_notstarted: setting?.expiry_hours_notstarted,
      expiry_min_notstarted: setting?.expiry_min_notstarted,

      //registration settings
      enable_landing_page: setting?.enable_landing_page,
      free_registration: free_registration,
      activation_email: setting?.activation_email,
      premium_upgrade: setting?.premium_upgrade,
      access_free: setting?.access_free,

      //search settings
      search_title_status: search_title_status,
      search_category_status: search_category_status,
      search_tags_status: search_tags_status,
      search_description_status: search_description_status,
      search_details_status: search_details_status,

      //serires settings
      series_season: setting?.series_season,
      series_networks_status: series_networks_status,

      //site settings
      website_name: setting?.website_name,
      website_description: setting?.website_description,
      logo: setting?.logo,
      logo_height: setting?.logo_height,
      logo_width: setting?.logo_width,
      favicon: setting?.favicon,

      //social network settings
      facebook_page_id: setting?.facebook_page_id,
      google_page_id: setting?.google_page_id,
      twitter_page_id: setting?.twitter_page_id,
      instagram_page_id: setting?.instagram_page_id,
      linkedin_page_id: setting?.linkedin_page_id,
      whatsapp_page_id: setting?.whatsapp_page_id,
      skype_page_id: setting?.skype_page_id,
      youtube_page_id: setting?.youtube_page_id,
      tiktok_page_id: setting?.tiktok_page_id,
      email_page_id: setting?.email_page_id,

      //default_time_zone
      default_time_zone: setting?.default_time_zone,

      // video settings 
      videos_per_page: videos_per_page,
      posts_per_page: setting?.posts_per_page,
      show_description: show_description,
      show_Links_and_details: show_Links_and_details,
      show_genre: show_genre,
      show_languages: show_languages,
      show_subtitle: show_subtitle,
      show_artist: show_artist,
      show_recommended_videos: show_recommended_videos,
      show_views: show_views,
      videos_expiry_status: videos_expiry_status,
      epg_status: epg_status,

      enable_ppv_rent: enable_ppv_renttest,

      earn_amount: setting?.earn_amount ? parseInt(setting?.earn_amount, 10) : 0,
      video_clip_enable: setting?.video_clip_enable ? parseInt(setting?.video_clip_enable, 10) : 0,

    };


    let firstApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/setting/store`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res?.json();

      if (resjson?.status === true) {
        firstApiStatus = true;

        resultapi = resjson;

        // secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus) {
      setValidationMessageppv_hours("");
      setValidationMessageppv_price("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status === false) {
        var resultError = resjson;
        props?.setApiresponsealert(resultError);
        props?.setShowalert(true);
        setIsValidationHiddenppv_hours(false);
        setIsValidationHiddenppv_price(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };


  // {global ppv price }
  // {global ppv price }

  const [allCurrencylistfromappi, setAllCurrencylistfromappi] = useState([]);

  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);

  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);

  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();

  const [editUser, setEditUser] = useState({
    ios_ppv_price: "",
    ppv_gobal_price: "",
    global_ppv: null,
    ppv_price: "",
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/Settings/GlobalPrice/index`,
        { headers: headers }
      );
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.GlobalPrice;
      setAllCurrencycustoms(DefaultContentPriceRates);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/currency_setting/index`,
        { headers: headers }
      );
      const data = response?.data?.allCurrency;
      setAllCurrencylistfromappi(data);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  useEffect(async () => {
    fetchData();
  }, []);




  const { globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive

  // {ppv price with global and customs} 
  // {ppv price with global and customs} 


  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: editUser?.ppv_gobal_price,
    convertedPrices: [],
    symbol: ""
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: setting?.ppv_price,
    convertedPrices: [],
    symbol: ""
  });

  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: setting?.ppv_price,
      convertedPrices: []
    });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== setting?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            return {
              Code: item?.code,
              country_name: matchingCountry?.country || item?.country,
              symbol: matchingCountry?.symbol || item?.symbol || '', // Ensure fallback is not null
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === setting?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 7, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: 7,
      PPVType: 1,
      PriceType: formData?.priceType,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.symbol || ""
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Settings/GlobalPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
        setTimeout(() => {
          // window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response?.data?.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          return {
            Code: item?.code,
            Country: matchingCountry?.country || item?.country,
            symbol: matchingCountry?.symbol || item?.symbol || '', // Ensure fallback is not null
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 


  return (
    <>
      <section className="container-fluid">
        <Link to="/settings" className="backarrow d-inline-flex align-items-center  p-2 my-2 ">
          <IoArrowBack style={{ width: "20px", height: "20px" }} />
          <span className="ms-2 ">Back</span>
        </Link>
        <div className="row m-0 py-3 ">
          <div className="col-12 col-sm-6 "><h3>PPV Settings</h3></div>
          <div className="col-12 col-sm-6 d-flex justify-content-end "><button onClick={handleUpdate} className="btn btn-primary ">Save PPV</button></div>
        </div>
        {showOverlay && <Response_Processing></Response_Processing>}
        <div className="row col-lg-12 ">
          <div className=" col-sm-6 col-md-6 col-lg-6 ">
            <div className="card p-3">
              <h5>Create Site</h5>
              <hr></hr>

              <>
                {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (

                  <div className="mt-2 text-start">
                    <label className="m-0">
                      PPV Global Price<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="ppv_price"
                        name="ppv_price"
                        value={setting?.ppv_price}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={ppv_priceInputRef}
                      />
                    </div>

                    <span className="errorred">
                      {!isValidationHiddenppv_price && (
                        <p>{validationMessageppv_price}</p>
                      )}
                    </span>

                  </div>

                ) :
                  <div className=" text-start">
                    <div className='modalofglobalppv'>
                      <Form>
                        {/* <>
                      {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                        <Form.Check
                          type="radio"
                          label="Global Price"
                          name="priceOption"
                          value="1"
                          checked={setting?.global_ppv === "1" || setting?.global_ppv === 1}
                          // onChange={handleChange}
                          onClick={() => handleChange({ target: { value: '1' } })}
                        />
                      ) : null}
                    </> */}
                        <Form.Check
                          type="radio"
                          label="Global Price"
                          name="priceOption"
                          value="2"
                          checked={setting?.global_ppv === null || (setting?.global_ppv !== "1" && setting?.global_ppv !== 1)}
                          // onChange={handleChange}
                          onClick={() => handleChange({ target: { value: '2' } })}
                        />
                      </Form>
                      <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                        keyboard={false}>
                        <Modal.Header closeButton>
                          <Modal.Title>{modalType === '1' ? 'Set Global Price' : 'Set Global Price'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body-custom">
                          {step === 1 ? (
                            <Form>
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{modalType === '1' ? 'Global Price' : 'Global Price'}</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="price"
                                  value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                  onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                  disabled={modalType === '1'}
                                />
                              </Form.Group>
                              {modalType === '2' && (
                                <Form.Group>
                                  <Form.Label
                                    className="mandatorySimple">Country</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="priceType"
                                    value={formDatacustomprice.priceType}
                                    onChange={handleCustomPriceTypeChange}
                                    disabled
                                  >
                                    <option value="">Select Countries</option>
                                    {allCurrencylistfromappi?.map(item => (
                                      <option key={item?.code} value={item?.code}>{item?.country}</option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              )}
                              {modalType === '1' && (
                                <Form.Group>
                                  <Form.Label
                                    className="mandatorySimple">Country</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="priceType"
                                    value={formDatacustomprice.priceType}
                                    onChange={handleCustomPriceTypeChange}
                                    disabled
                                  >
                                    <option value="">Select Countries</option>
                                    {allCurrencylistfromappi?.map(item => (
                                      <option key={item?.code} value={item?.code}>{item?.country}</option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                              )}
                              {/* <div className='text-end mt-2'>
                            <Button variant="primary" onClick={handleNext}>
                              Next
                            </Button>
                          </div> */}
                            </Form>
                          ) : (
                            <>
                              <div className="table-responsive">
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Country</th>
                                      <th>Current Price</th>
                                      <th>Converted Price</th>
                                      <th>Difference</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                      <tr key={index}>
                                        <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                        <td>{item?.symbol} {item?.price || item?.ActualPrice}</td>
                                        <td>
                                          <Form.Control
                                            type="number"
                                            value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                            onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                            disabled={modalType === '1'}
                                          />
                                        </td>
                                        <td>{item?.difference || item?.RateDifference}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                              {/* <div className='text-end mt-2'>
                            <Button variant="secondary" onClick={() => setStep(1)}>
                              Previous
                            </Button>
                            <Button variant="primary" onClick={handleSubmit}>
                              Submit
                            </Button>
                          </div> */}
                            </>
                          )}
                        </Modal.Body>

                        <hr></hr>
                        {step === 1 ? (

                          <div className='text-end p-2'>
                            <Button variant="primary" onClick={handleNext}>
                              Next
                            </Button>
                          </div>
                        ) : (
                          <div className='text-end p-2'>
                            <Button variant="secondary" onClick={() => setStep(1)}>
                              Previous
                            </Button>
                            <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                              Submit
                            </Button>
                          </div>
                        )}
                      </Modal>
                    </div>




                    {/* <label className="m-0">
                      PPV Global Price<span className="mandatory">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        type="number"
                        // id="ppv_price"
                        name="ppv_price"
                        value={setting?.ppv_price}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                        placeholder=""
                        ref={ppv_priceInputRef}
                      />
                    </div> */}
                    <span className="errorred">
                      {!isValidationHiddenppv_price && (
                        <p>{validationMessageppv_price}</p>
                      )}
                    </span>
                  </div>

                }
              </>


              <div className="mt-2 text-start">
                <label className="m-0">
                  Pay Per view Hours<span className="mandatory">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    // id="ppv_hours"
                    name="ppv_hours"
                    className="rs-input form-control-lg"
                    // id="ppv_hours"
                    onChange={handleInput}
                    value={setting?.ppv_hours}
                    ref={ppv_hoursInputRef}
                  />
                </div>
                <span className="errorred">
                  {!isValidationHiddenppv_hours && (
                    <p>{validationMessageppv_hours}</p>
                  )}
                </span>
              </div>

              <div className="mt-2 text-start">
                <label className="m-0">IOS PRODUCT ID</label>

                <div className="mt-2">
                  <input
                    type="number"
                    // id="ppv_price"
                    name="ios_product_id"
                    value={setting?.ios_product_id}
                    className="rs-input form-control-lg"
                    onChange={handleInput}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="mt-2 text-start">
                <label className="m-0">IOS Global Price</label>

                <div className="mt-2">
                  <input
                    type="number"
                    // id="ppv_price"
                    name="ios_plan_price"
                    value={setting?.ios_plan_price}
                    className="rs-input form-control-lg"
                    onChange={handleInput}
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div className="text-start mb-3">
              <button onClick={handleUpdate} className="btn btn-primary">
                Save PPV
              </button>
            </div>
          </div>

          {/* Second Part of Section  */}

          <div className=" col-sm-6 col-md-6 col-lg-6">
            <div className="card p-3">
              <h5>Time Settings</h5>
              <hr></hr>

              {/* <div className=" text-start">
                <label className="m-0">Set Expiry time <small>( If Live Video Started )</small></label>
                <div className="mt-2">
                  <input
                    type="number"
                    // id="ppv_hours"
                    name="expiry_time_started"
                    className="rs-input form-control-lg"
                    onChange={handleInput}
                    value={setting?.expiry_time_started}
                  />
                </div>
              </div> */}

              <div className="mt-2 text-start">
                <label className="m-0">Set Expiry time 
                   {/* <small>( If Live Video Not Started ) </small> */}
                   </label>

                <div className="mt-2">
                  <div className="row">
                    <div className="panel-body col-md-4">
                      <div className="form-group">
                        <p className=""> Days </p>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        >
                          <select
                            // id="expiry_day_notstarted"
                            className="form-select"
                            name="expiry_day_notstarted"
                            onChange={handleInput}
                            value={setting?.expiry_day_notstarted}
                          >
                            {/* <option value="0">0</option> */}
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="panel-body col-md-4">
                      <div className="form-group">
                        <p className=""> Hours </p>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        >
                          <select
                            // id="expiry_hours_notstarted"
                            className="form-select"
                            name="expiry_hours_notstarted"
                            onChange={handleInput}
                            value={setting?.expiry_hours_notstarted}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="panel-body col-md-4">
                      <div className="form-group">
                        <p className=""> Minutes </p>
                        <div
                          className="make-switch"
                          data-on="success"
                          data-off="warning"
                        >
                          <select
                            // id="expiry_min_notstarted"
                            className="form-select"
                            name="expiry_min_notstarted"
                            onChange={handleInput}
                            value={setting?.expiry_min_notstarted}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                            <option value="39">39</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="45">45</option>
                            <option value="46">46</option>
                            <option value="47">47</option>
                            <option value="48">48</option>
                            <option value="49">49</option>
                            <option value="50">50</option>
                            <option value="51">21</option>
                            <option value="52">52</option>
                            <option value="53">53</option>
                            <option value="54">54</option>
                            <option value="55">55</option>
                            <option value="56">56</option>
                            <option value="57">57</option>
                            <option value="58">58</option>
                            <option value="59">59</option>
                            <option value="60">60</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-3">
              <h5>Status Settings</h5>
              <hr></hr>
              <div className="row">
                <div className="col-9 col-sm-9 col-lg-9">
                  <p className="p2">Enable Pay per View Status</p>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 text-end">
                  <label className="switch">
                    <input
                      name="ppv_status"
                      id="ppv_status"
                      onChange={handleInputenable}
                      className="rs-input"
                      defaultChecked={
                        setting?.ppv_status == 1 ? true : false
                      }
                      checked={setting?.ppv_status == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_status"
                      id="ppv_status"
                      onChange={handleInputenable}
                      value={setting?.ppv_status === 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-9 col-sm-9 col-lg-9">
                  <p className="p2">Frontend Enable Pay per View Button</p>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 text-end">
                  <label className="switch">
                    <input
                      name="enable_ppv_button"
                      id="enable_ppv_button"
                      onChange={handleInputenable}
                      className="rs-input"
                      defaultChecked={
                        setting?.enable_ppv_button == 1 ? true : false
                      }
                      checked={setting?.enable_ppv_button == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="enable_ppv_button"
                      id="enable_ppv_button"
                      onChange={handleInputenable}
                      value={setting?.enable_ppv_button === 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-9 col-sm-9 col-lg-9">
                  <p className="p2">Enable Rent as PPV ( Free for Subscriber) </p>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 text-end">
                  <label className="switch">
                    <input
                      name="enable_ppv_rent"
                      id="enable_ppv_rent"
                      onChange={handleInputenable}
                      className="rs-input"
                      defaultChecked={
                        setting?.enable_ppv_rent === 1 ? true : false
                      }
                      checked={setting?.enable_ppv_rent === 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="enable_ppv_rent"
                      id="enable_ppv_rent"
                      onChange={handleInputenable}
                      value={setting?.enable_ppv_rent === 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-9 col-sm-9 col-lg-9">
                  <p className="p2">Pay Per View for Individual Contents ( Customs for Videos) </p>
                </div>
                <div className="col-3 col-sm-3 col-lg-3 text-end">
                  <label className="switch">
                    <input
                      name="PPV_Individual_Contents"
                      id="PPV_Individual_Contents"
                      onChange={handleInputenable}
                      className="rs-input"
                      defaultChecked={
                        setting?.PPV_Individual_Contents === 1 || setting?.PPV_Individual_Contents === "1" ? true : false
                      }
                      checked={setting?.PPV_Individual_Contents === 1 || setting?.PPV_Individual_Contents === "1" ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="PPV_Individual_Contents"
                      id="PPV_Individual_Contents"
                      onChange={handleInputenable}
                      value={setting?.PPV_Individual_Contents === 1 || setting?.PPV_Individual_Contents === "1" ? "1" : "0"}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ppv_Setting;
